const state = {
    VehiclesRS:{},
    Vehicles:[],
    VehicleInfoDetail:{
        pickUpLocation:"",
        dropOffLocation:"",
        VehicleRateFeeAndTax:[],
        vehicleExtras:[],
        insurance:[]
    },
}

export default state;

