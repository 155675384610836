<template>

    <Layout>
        <!-- <PageHeader :title="title" :items="items" /> -->
        <!-- Search #TODO-->
        <div>
			<span class="">
				<img src="@/assets/images/logo-raileurope.png" alt height="50" />
			</span>
		</div>
		<div class="mt-3 mb-3">
			<b-carousel
			id="carousel-1"
			:interval="2000"
			controls
			indicators
			img-width="2000"
			img-height="240"
			>
				<!-- Text slides with image -->
				<a @click="toDetails(banner.id)"
				v-for="banner, index in bannerList" :key="index"
				>
				<b-carousel-slide
					:caption="banner.caption"
					:text="banner.text"
					:img-src="urlPrefix+banner.banner.url"
				>
				</b-carousel-slide>
				</a>
			</b-carousel>
		</div>
        <div
		v-permission="{permission: 'agencyportal.rail', effect: 'hidden'}"
		>
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Train Tickets" active>
                        <KeepAlive>
                            <TicketSearchVue></TicketSearchVue>
                        </KeepAlive>
                        
                    </b-tab>
                    <b-tab title="Rail Passes">
                        <KeepAlive>
                            <PassSearchVue></PassSearchVue>
                        </KeepAlive>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
        <!-- Promotions #TODO -->
        <div class="mt-1">
            <rail-promotions></rail-promotions>
        </div>
        <!-- Connection Status #TODO -->
        <div style="margin-top: 10px;">
            <rail-connection-status></rail-connection-status>
        </div>
    </Layout>
</template>

<script>
    //import { Carousel3d, Slide } from 'vue-carousel-3d'
    import Layout from "../layouts/main";
    // import PageHeader from "@/components/page-header";

    //# TODO
    // import RailSearch from './RailSearch.vue'
    import TicketSearchVue from "../../components/rail/TicketSearch.vue";
    import PassSearchVue from "../../components/rail/PassSearch.vue";
    import RailPromotions from './RailPromotions.vue'
    import RailConnectionStatus from './RailConnectionStatus.vue'
    import RailAPI from '../../api/RailAPI';

    export default {
        name: "RailHome",
        components:{
            //Carousel3d,
            //Slide,
            Layout,
            // PageHeader,
            TicketSearchVue,
            PassSearchVue,
            'rail-promotions': RailPromotions,
            'rail-connection-status': RailConnectionStatus
        },
        data(){
            return{
                // 跨域资源，需要正确的代理头才可以正常显示
                urlPrefix: process.env.VUE_APP_BFF_API+'/v1/cms/images',
                bannerList: []
                
            }
        },
        methods:{
            loadBannerList(){
                RailAPI.getBannerList().then(res => {
                    let resList = res.data
                    this.bannerList = resList
					console.log(resList)
                }).catch(error => {
                    console.log(error)
                })
            },
			toDetails(id) {
				this.$router.push({
					name: 'promotion',
					query: {
						id: id,
						type: 'banner'
					}
				})
			},
        },  
        computed:{
            
        },
        created(){
            this.loadBannerList()
        },
        mounted() {
            
        },

    }
</script>

<style scoped>
    .max-width{
        width: 80%;
        margin-left: 10%;
    }
</style>