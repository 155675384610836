/**
 *  根据mutationTypes中常量定义的方法名来构造指定命名的函数，函数实现state中数据的状态转变
 * */
import * as types from "./mutationsTypes"
const mutations = {
    [types.UPDATE_VEHICLES](state, payload){
        console.log(payload)
        state.VehiclesRS = payload.VehiclesSearchRS;
        state.Vehicles = [];
        if(payload.VehiclesSearchRS.vehAvailRSCore){
            for(let i=0;i<payload.VehiclesSearchRS.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvails.length;i++){
                let item = payload.VehiclesSearchRS.vehAvailRSCore.vehVendorAvails[0].vehAvails.vehAvails[i];
                let carInfo = item.vehAvailCore;
                let currencyCode = carInfo.totalCharges[0].currencyCode;
                let currencyTag = ""
                if (currencyCode=="USD"){
                    currencyTag = "$";
                }else if (currencyCode=="HKD"){
                    currencyTag = "HK$";
                }else {
                    currencyTag = "HK$";
                }
                let VehicleInfo ={
                    model: carInfo.vehicle.vehMakeModel.model,
                    pictureURL: carInfo.vehicle.pictureURL,
                    name: carInfo.vehicle.vehMakeModel.name,
                    make: carInfo.vehicle.vehMakeModel.make,
                    oilConsume: carInfo.vehicle.oilConsume,
                    passengerQuantity: carInfo.vehicle.passengerQuantity,
                    vehClassName: carInfo.vehicle.vehClass.vehClassName,
                    transmission: carInfo.vehicle.transmissionType,
                    baggageQuantity: carInfo.vehicle.baggageQuantity,
                    doorCount: carInfo.vehicle.vehType.doorCount,
                    currencyTag: currencyTag,
                    rateTotalAmount:carInfo.totalCharges[0].rateTotalAmount,
                    rateCode:carInfo.rentalRates[0].rateQualifier.rateQualifier,
                    vehClassCode:carInfo.vehicle.vehClass.vehClassCode,
                    airConditionInd:carInfo.vehicle.airConditionInd
                }
                state.Vehicles.push(VehicleInfo)
            }
        }
    },

    [types.UPDATE_VEHICLERATE](state, payload){
        console.log(payload);
        let item =  payload.VehicleRateRS.vehAvailRSCore
        console.log(item)
        state.VehicleInfoDetail.pickUpLocation = item.vehRentalCore.pickUpLocations[0].locationName
        state.VehicleInfoDetail.dropOffLocation = item.vehRentalCore.returnLocations[0].locationName
        state.VehicleInfoDetail.VehicleRateFeeAndTax = item.vehVendorAvails[0].vehAvails.vehAvails[0].vehAvailCore.fees
        state.VehicleInfoDetail.vehicleExtras = item.vehVendorAvails[0].vehAvails.vehAvails[0].vehAvailCore.pricedEquips
        state.VehicleInfoDetail.insurance = item.vehVendorAvails[0].vehAvails.vehAvails[0].vehAvailInfo.pricedCoverages
    }

}


export default mutations;