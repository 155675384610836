<script>
import { mapActions } from 'vuex';
import RailAPI from '../../api/RailAPI';
export default {
    data () {
        return {
            // 跨域资源，需要正确的代理头才可以正常显示
            urlPrefix: process.env.VUE_APP_BFF_API+'/v1/cms/images',
            promotionList: []
        }
    },
    created(){
        this.loadPromotionList()
    },
    methods: {
        ...mapActions('rail', [
            'selectPromotion'
        ]),
        loadPromotionList(){
            RailAPI.getPromotionList().then(res => {
                let resList = res.data
                this.promotionList = resList
            }).catch(error => {
                console.log(error)
            })
        },
        toDetails(id) {
            this.$router.push({
                name: 'promotion',
                query: {
                    id: id
                }
            })
            // let route = this.$router.resolve({
            //     name: 'promotion',
            //     query: {
            //         id: id
            //     }
            // })
            // window.open(route.href)
        },
    } 
}

</script>

<template>
    <div>
    <b-card bg-variant="dark"
    v-if="promotionList.length > 0"
    >
        <b-card-title class="text-white">Promotions</b-card-title>
        <b-card-group deck>

            <b-card v-for="promotion, index in promotionList" :key="`promotion-${index}`"
            :title="promotion.title"
            :img-src="urlPrefix+promotion.photo.formats.small.url"
            img-alt="Image"
            img-top
            >
                <!-- <b-card-img :src="urlPrefix+promotion.photo[0].formats.small.url" alt="Image" top></b-card-img> -->
                <!-- <b-card-title>{{promotion.title}}</b-card-title> -->
                <!-- <b-card-sub-title class="mb-2">Card Sub Title</b-card-sub-title> -->
                <b-card-text>
                    <p>{{promotion.abstract}}<b-badge class="ml-2" href="#" variant="light" @click="toDetails(index+1)"><small class="text-primary">More +</small></b-badge></p>
                    <!-- <p>{{promotion.abstract}}<b-badge class="ml-2" href="#" variant="light" ><router-link :to="'/rail/promotion?id='+(index+1)" target="_blank"><small class="text-primary">More +</small></router-link></b-badge></p> -->
                    <!-- <p>{{promotion.abstract}}<b-badge class="ml-2" variant="light" ><router-link :to="{name: 'promotion', query: {id: index+1}}" target="_blank"><small class="text-primary">More +</small></router-link></b-badge></p> -->
                </b-card-text>
            </b-card>
        </b-card-group>
        
    </b-card>
    <!-- skeleton -->
    <b-card 
    bg-variant="dark"
    v-else
    >
        <b-card-title class="text-white">Promotions</b-card-title>
        <b-card-group deck>
            <b-card no-body img-top>
                <b-skeleton-img card-img="top" aspect="3:1"></b-skeleton-img>
                <b-card-body>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card-body>
            </b-card>
            <b-card no-body img-top>
                <b-skeleton-img card-img="top" aspect="3:1"></b-skeleton-img>
                <b-card-body>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card-body>
            </b-card>
            <b-card no-body img-top>
                <b-skeleton-img card-img="top" aspect="3:1"></b-skeleton-img>
                <b-card-body>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card-body>
            </b-card>
            <b-card no-body img-top>
                <b-skeleton-img card-img="top" aspect="3:1"></b-skeleton-img>
                <b-card-body>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card-body>
            </b-card>
            <b-card no-body img-top>
                <b-skeleton-img card-img="top" aspect="3:1"></b-skeleton-img>
                <b-card-body>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card-body>
            </b-card>
        </b-card-group>
    </b-card>
</div>
</template>



<style scoped>
.promotion-card:hover {
    border-color: #179a73;
}
</style>