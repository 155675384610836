<script>
import Layout from "../layouts/main.vue";

import PageHeader from "../../components/page-header.vue";
import TrainInfoVue from "./TrainInfo.vue";
import TravelerFormVue from "./TravelerForm.vue";
import TravelerInfoVue from "./TravelerInfo.vue";
import PriceBreakdownVue from "./PriceBreakdown.vue";

import { mapActions, mapGetters } from "vuex";
import RailUtils from "../../utils/RailUtils";
import RailAPI from "../../api/RailAPI";

export default {
  name: "TrainTicketDetailsVue",
  components: {
    Layout,
    PageHeader,
    TrainInfoVue,
    TravelerFormVue,
    TravelerInfoVue,
    PriceBreakdownVue
  },
  data() {
    return {
      title: "Ticket Details",
      items: [
        {
          text: "Rail"
        },
        {
          text: "Ticket Details",
          active: true
        }
      ],
      isOverLayShow: false,
      alertSettings: {
        dismissCountDown: 0,
        countDownSecs: 4,
        title: "",
        alertMsg: "",
        alertVariant: "warning"
      },
      componentName: "TravelerFormVue",
      travelerQuantity: {},
      journeyDetails: {},
      travelerFormValues: [],
      travelersForm: [],
      leadTraveler: {},
      correlationID: "",
      travelers: [],
      p2pOptions: [],
      confirmBookingRQ: "",
      isInitiateStep: true,
      isQuoteStep: false,
      isPreBooked: false,
      isConfirmed: false,
      bookedPrice: {
        currencyCode: "",
        totalAmount: 0,
        sellingCurrencyCode: "",
        sellingTotalAmount: 0
      },
      passengerTypeInfo: null,
      isIssueTicketConfirmModalVisible: false,
      isIssueTicketAgreed: false,
    };
  },
  computed: {
    ...mapGetters("rail", [
      "selectedOutboundTicket",
      "selectedInboundTicket",
      "isRoundTrip",
      "searchInputValues",
      "bookInitiateRS",
      "bookTicketRS"
    ]),
    ...mapGetters("account", ["accountInfo", "loginStatusRS", "logedIn"])
  },
  watch: {
    bookInitiateRS() {
      //initiate
      this.isOverLayShow = false;
      if (this.bookInitiateRS.success) {
        this.isInitiateStep = false;
        this.isQuoteStep = true;
        this.correlationID = this.bookInitiateRS.correlationID;
        this.bookedPrice = RailUtils.getInitiateBookedPrice(
          this.bookInitiateRS.railReservations[0]
        );
        this.passengerTypeInfo = RailUtils.getPassengerTypeInfo(
          this.bookInitiateRS.railReservations[0].itinerary.vendorMessages
        );
        this.travelersForm = RailUtils.getTravelersFormArray(
          this.bookInitiateRS.railReservations[0]
        );
        this.p2pOptions = RailUtils.getP2POptions(
          this.bookInitiateRS.railReservations[0]
        );
      } else {
        this.alertSettings.title = this.bookInitiateRS.errorTitle;
        this.alertSettings.alertMsg = this.bookInitiateRS.errorMsg;
        this.alertSettings.alertVariant = "danger";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;
      }
    },
    bookTicketRS() {
      //quote
      this.isOverLayShow = false;
      if (this.bookTicketRS.success) {
        this.isQuoteStep = false;
        this.isPreBooked = true;
        // this.bookedPrice = RailUtils.getBookedPrice(this.bookTicketRS.railReservations[0])
        this.confirmBookingRQ = RailUtils.getConfirmBookingRQ(
          this.bookTicketRS
        );
        // this.componentName = "TravelerInfoVue"
        this.alertSettings.title = "Successfully!";
        this.alertSettings.alertMsg =
          "Booking was created, please check out to issue tickets.";
        this.alertSettings.alertVariant = "success";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;

        // this.componentName = "TravelerInfoVue"
      } else {
        this.alertSettings.title = this.bookTicketRS.errorTitle;
        this.alertSettings.alertMsg = this.bookTicketRS.errorMsg;
        this.alertSettings.alertVariant = "danger";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;
      }
    }
  },
  created() {
    let selectedOutboundTicketSession = sessionStorage.getItem(
      "selectedOutboundTicket"
    )
      ? JSON.parse(sessionStorage.getItem("selectedOutboundTicket"))
      : [];
    let selectedInboundTicketSession = sessionStorage.getItem(
      "selectedInboundTicket"
    )
      ? JSON.parse(sessionStorage.getItem("selectedInboundTicket"))
      : [];
    let isRoundTripSession = JSON.parse(sessionStorage.getItem("isRoundTrip"));

    //更新 vuex
    this.updateTiketDetails({
      selectedOutboundTicket: selectedOutboundTicketSession,
      selectedInboundTicket: selectedInboundTicketSession,
      isRoundTrip: isRoundTripSession
    });
  },
  methods: {
    ...mapActions("rail", ["bookTicket", "bookInitiate", "updateTiketDetails"]),
    countDownChanged(dismissCountDown) {
      this.alertSettings.dismissCountDown = dismissCountDown;
    },
    toOpenIssueTicketConfirmModal() {
      this.isIssueTicketConfirmModalVisible = true;
    },
    hideIssueTicketModal() {
      this.isIssueTicketConfirmModalVisible = false;
      this.isIssueTicketAgreed = false;
    },
    checkOut: function() {
      //hide modal
      this.isIssueTicketConfirmModalVisible = false;
      this.isIssueTicketAgreed = false;

      if (this.confirmBookingRQ != "") {
        this.isOverLayShow = true;
        RailAPI.confirmBooking(this.confirmBookingRQ)
          .then(res => {
            this.isOverLayShow = false;
            let confirmbookingRS = res.data;
            if (
              confirmbookingRS.confirmations != undefined &&
              confirmbookingRS.confirmations.length > 0
            ) {
              this.isPreBooked = false;
              this.isConfirmed = true;
              this.alertSettings.title = "Successfully!";
              this.alertSettings.alertMsg =
                "Booking was confirmed. Tickets were issued.";
              this.alertSettings.alertVariant = "success";
              this.alertSettings.dismissCountDown =
                this.alertSettings.countDownSecs + 10;
            } else if (
              !confirmbookingRS.success &&
              confirmbookingRS.errors &&
              confirmbookingRS.errors.errors
            ) {
              let error = confirmbookingRS.errors.errors[0];
              let stringValue = error.stringValue;
              this.alertSettings.title = "Sorry!";
              this.alertSettings.alertMsg = stringValue;
              this.alertSettings.alertVariant = "danger";
              this.alertSettings.dismissCountDown =
                this.alertSettings.countDownSecs + 10;
            } else {
              this.alertSettings.title = "Sorry!";
              this.alertSettings.alertMsg = "Supplier response errors.";
              this.alertSettings.alertVariant = "danger";
              this.alertSettings.dismissCountDown =
                this.alertSettings.countDownSecs + 10;
            }
          })
          .catch(error => {
            let title = "Sorry!";
            let errorMsg = "";
            if (error.response) {
              try {
                errorMsg = error.response.data.errors.errors[0].stringValue;
              } catch {
                console.info(error.response);
                errorMsg = "Some reponse errors";
              }
            } else if (error.request) {
              console.info(error.request);
              errorMsg = "Some request errors";
            } else {
              console.info(error);
              errorMsg = "Some data errors";
            }
            this.isOverLayShow = false;

            this.alertSettings.title = title;
            this.alertSettings.alertMsg = errorMsg;
            this.alertSettings.alertVariant = "danger";
            this.alertSettings.dismissCountDown =
              this.alertSettings.countDownSecs + 10;
          });
      } else {
        this.alertSettings.title = "Sorry!";
        this.alertSettings.alertMsg = "Supplier request error.";
        this.alertSettings.alertVariant = "danger";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;
      }
    },
    backTo: function() {},
    toRailHome() {
      this.$router.push({
        name: "rail-home"
      });
    },
    toBookingList() {
      this.$router.push({
        name: "bookingRail"
      });
    },
    toPreBook: function(travelerForm) {
      //从TravelerFormVue子组件传递的traveler信信息
      this.travelerFormValues = travelerForm;
      //输入的乘客信息
      this.travelers = RailUtils.getTicketTravelersInfo(travelerForm);
      let islogedIn = this.logedIn;
      let accountInfoFromSession = JSON.parse(
        sessionStorage.getItem("accountInfo")
      );
      let username = accountInfoFromSession.id;
      if (islogedIn) {
        let bookRQ = RailUtils.getBookRQ({
          correlationID: this.correlationID,
          travelerFormValues: travelerForm,
          username: username
        });
        this.isOverLayShow = true;
        this.bookTicket(bookRQ);
      } else {
        this.alertSettings.title = "Sorry!";
        this.alertSettings.alertMsg = "Please log in.";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;
      }
    },
    toInitiate() {
      let islogedIn = this.logedIn;
      let accountInfoFromSession = JSON.parse(
        sessionStorage.getItem("accountInfo")
      );
      let username = accountInfoFromSession.id;
      if (islogedIn) {
        let rphs = [];
        rphs.push(this.selectedOutboundTicket.rph);
        if (this.isRoundTrip) {
          rphs.push(this.selectedInboundTicket.rph);
        }
        let bookRQ = RailUtils.getBookInitiateRQ({
          rphs: rphs,
          username: username
        });
        this.isOverLayShow = true;
        this.bookInitiate(bookRQ);
      } else {
        this.alertSettings.title = "Sorry!";
        this.alertSettings.alertMsg = "Please log in.";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <b-row>
      <!-- train info & traveler info -->
      <b-col>
        <b-overlay :show="isOverLayShow" rounded="sm">
          <!-- Departure train info -->

          <TrainInfoVue
            v-if="isRoundTrip"
            :selected-outbound-ticket="selectedOutboundTicket"
            :selected-inbound-ticket="selectedInboundTicket"
            :passengerTypeInfo="passengerTypeInfo"
          />
          <TrainInfoVue
            v-else
            :selected-outbound-ticket="selectedOutboundTicket"
            :passengerTypeInfo="passengerTypeInfo"
          />

          <div class="p-2 d-flex justify-content-end" v-if="isInitiateStep">
            <b-button variant="success" @click="toInitiate"
              >Continue to Passengers</b-button
            >
          </div>

          <div class="p-2 bg-white" v-if="isQuoteStep && p2pOptions.length > 0">
            <h6>Ouigo Options</h6>
            <div
              class="p-2"
              v-for="(option, opIdx) in p2pOptions"
              :key="`p2p-option-${opIdx}`"
            >
              <div class="p-2 border bg-light">
                {{ option.label }}
              </div>
              <div class="mt-2">
                <p>
                  <b>{{ option.title }}</b>
                </p>
                <p v-html="option.description"></p>
              </div>
              <div class="mt-2 border-top" v-if="option.summary !== undefined">
                <span class="text-success">{{ option.summary }}</span>
              </div>
              <div class="d-flex" v-if="option.label == 'Included luggage'">
                <div class="p-3">
                  <img src="../../assets/images/handbag.svg" />
                </div>
                <div class="p-3">
                  <img src="../../assets/images/cabin-luggage.svg" />
                </div>
              </div>
            </div>
          </div>

          <TravelerFormVue
            v-if="isQuoteStep"
            :travelers="travelersForm"
            :travel-date-time="selectedOutboundTicket.departureDateTime"
            @confirm="toPreBook"
          ></TravelerFormVue>
          <!-------------------个人信息--------------------------->
          <TravelerInfoVue
            v-if="isPreBooked"
            :travelers="travelers"
            @checkOut="toOpenIssueTicketConfirmModal"
            :is-button-hidden="isConfirmed"
          ></TravelerInfoVue>

          <div class="p-2 d-flex justify-content-end" v-if="isConfirmed">
            <!-- <b-button variant="success" @click="toRailHome">Find more Tickets</b-button> -->
            <b-button variant="success" @click="toBookingList"
              >Booking List</b-button
            >
          </div>
        </b-overlay>
      </b-col>

      <!-- Price Summary -->
      <b-col cols="4">
        <b-card no-body>
          <b-card-header
            header-class="font-weight-bold bg-soft-primary"
            header="Price Summary"
          />
          <div v-if="!isInitiateStep">
            <div class="p-4">
              Total after commission &nbsp;&nbsp;
              <span class="text-danger"
                ><b
                  >{{ bookedPrice.sellingCurrencyCode }} &nbsp;{{
                    bookedPrice.sellingTotalAmount
                  }}</b
                ></span
              >
              <div
                v-for="(taxItem, taxIndex) in bookedPrice.taxes"
                :key="`tax-${taxIndex}`"
              >
                <small
                  >{{ taxItem.name }}
                  <span class="text-danger">{{
                    taxItem.sellingCurrencyCode + " " + taxItem.sellingAmount
                  }}</span></small
                >
              </div>
            </div>
          </div>
          <div v-if="isInitiateStep">
            <div class="p-2">
              <PriceBreakdownVue
                :currency-code="selectedOutboundTicket.currencyCode"
                :total-amount="selectedOutboundTicket.totalAmount"
                :selling-currency-code="
                  selectedOutboundTicket.sellingCurrencyCode
                "
                :selling-total-amount="
                  selectedOutboundTicket.sellingTotalAmount
                "
                :conditions="selectedOutboundTicket.conditions"
                :price-details="selectedOutboundTicket.priceBreakdown"
              />
            </div>
            <div class="p-2 border-top">
              <a v-b-modal.ticket-details-out-conditions-modal class="text-info"
                >Terms and Conditions</a
              >
              <b-modal
                id="ticket-details-out-conditions-modal"
                centered
                scrollable
                hide-footer
                title="Ticket Conditions"
              >
                <div
                  class="my-2"
                  v-for="(pbdItem,
                  indx) in selectedOutboundTicket.priceBreakdown"
                  :key="`outbound-con-content-${indx}`"
                >
                  <p class="text-success">
                    {{ pbdItem.quantity + " x " }}{{ pbdItem.code }}
                  </p>
                  <div
                    v-for="(conItem, iiddxx) in pbdItem.conditions"
                    :key="`outbound-con-p-${iiddxx}`"
                  >
                    <p class="text-success">
                      {{
                        selectedOutboundTicket.trainSegments[iiddxx]
                          .departureStation
                      }}&nbsp;to&nbsp;{{
                        selectedOutboundTicket.trainSegments[iiddxx]
                          .arrivalStation
                      }}
                    </p>
                    <span class="text-success">{{
                      selectedOutboundTicket.trains[iiddxx]
                    }}</span>
                    <p v-html="conItem"></p>
                  </div>
                </div>
              </b-modal>
            </div>

            <div v-if="isRoundTrip" class="border-top border-bottom mt-3 p-2">
              <PriceBreakdownVue
                :currency="selectedInboundTicket.currencyCode"
                :total-amount="selectedInboundTicket.totalAmount"
                :selling-currency-code="
                  selectedInboundTicket.sellingCurrencyCode
                "
                :selling-total-amount="selectedInboundTicket.sellingTotalAmount"
                :conditions="selectedInboundTicket.conditions"
                :price-details="selectedInboundTicket.priceBreakdown"
              />
            </div>
            <div v-if="isRoundTrip" class="mt-2 p-2 border-top">
              <a v-b-modal.ticket-details-in-conditions-modal class="text-info"
                >Terms and Conditions</a
              >
              <b-modal
                id="ticket-details-in-conditions-modal"
                centered
                scrollable
                hide-footer
                title="Ticket Conditions"
              >
                <div
                  class="my-2"
                  v-for="(pbdItem,
                  indx) in selectedInboundTicket.priceBreakdown"
                  :key="`inbound-con-content-${indx}`"
                >
                  <span class="text-success"
                    >{{ pbdItem.quantity + " x " }}{{ pbdItem.code }}</span
                  >
                  <div
                    v-for="(conItem, iiddxx) in pbdItem.conditions"
                    :key="`inbound-con-p-${iiddxx}`"
                  >
                    <p class="text-success">
                      {{
                        selectedInboundTicket.trainSegments[iiddxx]
                          .departureStation
                      }}&nbsp;to&nbsp;{{
                        selectedInboundTicket.trainSegments[iiddxx]
                          .arrivalStation
                      }}
                    </p>
                    <span class="text-success">{{
                      selectedInboundTicket.trains[iiddxx]
                    }}</span>
                    <p v-html="conItem"></p>
                  </div>
                </div>
              </b-modal>
            </div>

            <div v-if="isRoundTrip" class="text-info text-right p-3">
              <b>TOTAL</b>&nbsp;&nbsp;<span class="text-danger">
                <b
                  >{{ selectedOutboundTicket.sellingCurrencyCode }}&nbsp;&nbsp;
                  {{
                    selectedOutboundTicket.sellingTotalAmount +
                      selectedInboundTicket.sellingTotalAmount
                  }}</b
                ></span
              >
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-alert
      :show="alertSettings.dismissCountDown"
      class="position-fixed fixed-top m-0 rounded-0 text-center"
      style="z-index: 2000;"
      :variant="alertSettings.alertVariant"
      dismissible
      @dismiss-count-down="countDownChanged"
    >
      <!--        <div v-html="alertSettings.title"></div>-->
      <div v-html="alertSettings.alertMsg"></div>
    </b-alert>

    <b-modal
      id="issue-ticket-confirm-modal"
      v-model="isIssueTicketConfirmModalVisible"
      centered
      hide-footer
      title="Issue ticket application"
    >
      <div class="my-1">
        Total Amount:
        <span class="text-danger"
          ><b
            ><span class="mr-1">{{ bookedPrice.sellingCurrencyCode }}</span
            >{{ bookedPrice.sellingTotalAmount }}</b
          ></span
        >
      </div>

      <div>
        <p>
          <input v-model="isIssueTicketAgreed" type="checkbox" class="mr-2" />
          I agree to make payment and issue ticket by clicking "Issue Ticket"
          button
        </p>
      </div>
      <div class="d-flex justify-content-between">
        <b-button @click="hideIssueTicketModal" variant="secondary">
          Cancel
        </b-button>

        <b-button
          @click="checkOut"
          variant="primary"
          :disabled="!isIssueTicketAgreed"
        >
          Issue Ticket
        </b-button>
      </div>
    </b-modal>
  </Layout>
</template>

<style scoped></style>
