import axios from "../utils/axiosUtils"
const packageUrl = "/api/v1/dynamicpkg";
export default {
    searchPackageAvailCitysApi(packageAvailRQ) {
        //保证对象是Json字符串
        if (typeof packageAvailRQ === 'object'){
            packageAvailRQ = JSON.stringify(packageAvailRQ);
        }
        return axios.post(packageUrl+"/avail", packageAvailRQ,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },

    searchPackageAvailHotelApi(packageAvailHotelRQ) {
        //保证对象是Json字符串
        if (typeof packageAvailHotelRQ === 'object'){
            packageAvailHotelRQ = JSON.stringify(packageAvailHotelRQ);
        }
        return axios.post(packageUrl+"/avail", packageAvailHotelRQ,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },

    packageVerifyApi(packageVerifyRQ) {
        //保证对象是Json字符串
        if (typeof packageVerifyRQ === 'object'){
            packageVerifyRQ = JSON.stringify(packageVerifyRQ);
        }
        return axios.post(packageUrl+"/verify-price", packageVerifyRQ,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },
    packagePreBookApi(packagePreBookRQ) {
        //保证对象是Json字符串
        if (typeof packagePreBookRQ === 'object'){
            packagePreBookRQ = JSON.stringify(packagePreBookRQ);
        }
        return axios.post(packageUrl+"/prebook", packagePreBookRQ,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },

}