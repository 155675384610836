<template>
<Layout>
        <PageHeader :title="title" :items="items" />
    <div class=" container-fluid p-4">

        <!--                建立表单-->
        <b-card bg-variant="light" style="background-color:white!important;">
            <div style="position:relative;bottom: 35px;background-color:transparent;font-weight: bolder;font-size: 20px">Group Name</div>
            <b-form action="carrental/list">
                <b-form-group
                        label-cols-lg="12"
                        label=""
                        label-size="lg"
                        label-class="font-weight-bold pt-0"
                        class="mb-0">
                    <b-form-input  placeholder="Where are you going?" v-model="hotel_destination" required></b-form-input>
                    <b-row style="margin-top: 30px">
                        <b-col md="3">
                            <b-form-datepicker id="check_in_date" v-model="check_in_date" class="mb-2" required></b-form-datepicker>
                        </b-col>
                        <b-col md="3">
                            <b-form-datepicker id="check_out_date" v-model="check_out_date" class="mb-2" required></b-form-datepicker>
                        </b-col>

                    </b-row>
                    <b-row>
                        <b-col offset-md="5">
                            <b-button variant="secondary" offset-md="4" @click="HotelCitySearch()" type="submit">Search Car</b-button>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-form>
        </b-card>
    </div>
    <div class="c_wrap container-fluid p-4" style="margin-top: 10%" >
        <div class="c_carousel"></div>
        <carousel-3d
                :autoplay="true"
                :autoplayTimeout="3000"
                :display="5"
                :width="800"
                :height="400"
                :animationSpeed="1000"
        >

            <slide class="carousel_box" v-for="(item, index) in earthData" :key="index" :index="index">
                <div class="carousel_flashlight">
                    <img :src="item.url" alt="" style="min-width: 500px;min-height: 500px">
                </div>
            </slide>
        </carousel-3d>
    </div>
</Layout>
</template>

<script>

    import Layout from "../layouts/main";
    import PageHeader from "@/components/page-header";

    import { Carousel3d, Slide } from 'vue-carousel-3d'
    import {mapState} from "vuex";

    export default {
        name: "CartransferHome",
        components:{
            Layout,
            PageHeader,
            Carousel3d,
            Slide
        },
        data(){
            return{
                title: "Car Transfer",
                items: [
                    {
                        // #TODO
                        text: "Jebsen Travel",
                    },
                    {
                        text: "Car Transfer",
                        active: true,
                    },
                ],
                check_in_date:'',
                check_out_date:'',
                hotel_destination:'',
                earthData: [
                    {
                        stext: "标题1",
                        sdescription: "1",
                        url:"https://www.avis.com/content/dam/cars/l/2019/ford/2019-ford-fiesta-se-5door-2wd-hatchback-white.png"
                    },
                    {
                        stext: "标题2",
                        sdescription: "2",
                        url:"https://www.avis.com/content/dam/cars/l/2021/kia/2021-kia-soul-s-5door-hatchback-white.png"
                    },
                    {
                        stext: "标题3",
                        sdescription: "3",
                        url:"https://www.avis.com/content/dam/cars/l/2020/toyota/2020-toyota-corolla-le-sedan-white.png"
                    },
                    {
                        stext: "标题4",
                        sdescription: "4",
                        url:"https://www.avis.com/content/dam/cars/l/2020/toyota/2020-toyota-corolla-le-sedan-white.png"
                    },
                    {
                        stext: "标题5",
                        sdescription: "5",
                        url:"https://www.avis.com/content/dam/cars/l/2021/toyota/2021-toyota-camry-se-sedan-white.png"
                    },
                    {
                        stext: "标题1",
                        sdescription: "1",
                        url:"https://www.avis.com/content/dam/cars/l/2020/nissan/2020-nissan-pathfinder-s-4wd-suv-white.png"
                    },
                    {
                        stext: "标题2",
                        sdescription: "2",
                        url:"https://www.avis.com/content/dam/cars/l/2019/chrysler/2019-chrysler-300-limited-sedan-white.png"
                    },
                    {
                        stext: "标题3",
                        sdescription: "3",
                        url:"https://www.avis.com/content/dam/cars/l/2021/mazda/2021-mazda-cx-5-grand-touring-4wd-suv-white.png"
                    },
                    {
                        stext: "标题4",
                        sdescription: "4",
                        url:"https://www.avis.com/content/dam/cars/l/2020/ford/2020-ford-mustang-ecoboost-premium-convertible-white.png"
                    },
                    {
                        stext: "标题5",
                        sdescription: "5",
                        url:"https://www.avis.com/content/dam/cars/l/2020/ford/2020-ford-expedition-xlt-suv-white.png"
                    },
                ],
            }
        },
        methods:{
            HotelCitySearch(){
                console.log(this.check_in_date);
                console.log(this.check_out_date);
                console.log(this.hotel_destination);
                console.log(this.room_selected);
                console.log(this.adult_selected);
                console.log(this.child_selected);
                // this.$router.push({path:'/hotellist'})
            },
            //根据电脑分辨率设置轮播图宽度
            changeWidth() {
                if (
                    document.body.clientWidth >= 1700 &&
                    document.body.clientWidth < 1920
                ) {
                    this.cWidth = 260;
                }
                if (
                    document.body.clientWidth >= 1500 &&
                    document.body.clientWidth < 1700
                ) {
                    this.cWidth = 220;
                }
                if (document.body.clientWidth < 1500) {
                    this.cWidth = 180;
                }
            }
        },
        computed:{
            ...mapState(["LayoutType", "Theme", "setHorizontalLayout"]),
        },
        created(){
            this.changeWidth();
        },
        mounted() {
            window.onresize = () => {
                this.changeWidth();
            };
        },

    }
</script>

<style scoped>

</style>