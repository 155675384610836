import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../state/store'

import routes from './routes'

Vue.use(VueRouter)
const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((routeTo, routeFrom, next) => {

  //todo get session info from backbend when logedIn is null
  const whiteList = ['login', 'Forgot-password', 'promotion', 'sso']

  if(whiteList.includes(routeTo.name)){
    return next()
  }

  //1.先判断vuex中的登录状态是否登录
  let logedIn = store.getters['account/logedIn']

  //1.1是则放行
  if(logedIn){
    return next()
  }

  store.dispatch('account/reloadLoginInfoAction').then(()=> {
    let logedIn = store.getters['account/logedIn']
    //2.不是的话向后端接口获取状态
    if (!logedIn){
      return next({
        //2.1如果未登录则跳到登录
        name: 'login'
      })
    }else {
      //2.2 否则放行
      return next()
    }
  });

})


// router.beforeEach(async (routeTo, routeFrom, next) => {
//   const whiteList = ['login', 'Forgot-password', 'promotion', 'sso']
//
//   if(whiteList.includes(routeTo.name)){
//     return next()
//   }
//
//   const logedIn = store.getters['account/logedIn']
//
//   if (logedIn==null){
//     await store.dispatch('reloadLoginInfoAction');
//     if(logedIn){
//       return next()
//     }else{
//       return next({
//         name: 'login'
//       })
//     }
//   }else {
//     return next()
//   }
//
//
//
// })
export default router
