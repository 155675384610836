<script>
import Layout from "../layouts/main.vue";
// import TravelerInfoVue from './TravelerInfo.vue';
import PassTravelerFormVue from "../../components/rail/PassTravelerForm.vue";
import PageHeader from "../../components/page-header.vue";
import RailUtils from "../../utils/RailUtils";
import RailAPI from "../../api/RailAPI";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "",
  components: {
    Layout,
    PageHeader,
    PassTravelerFormVue
  },
  data() {
    return {
      title: "Pass Ticket",
      items: [
        {
          // #TODO
          text: "Rail",
          to: { name: "rail-home" }
        },
        {
          text: "Pass Details",
          active: true
        }
      ],
      alertSettings: {
        dismissCountDown: 0,
        countDownSecs: 4,
        title: "",
        alertMsg: "",
        alertVariant: "warning"
      },
      isOverLayShow: false,
      isInitiateStep: true,
      isQuoteStep: false,
      isPreBooked: false,
      isConfirmed: false,
      travelerQuantity: {
        total: 3,
        adult: 1,
        senior: 1,
        youth: 1
      },
      travelerFormValues: [],
      travelersForm: [],
      travelers: [],
      correlationID: "",
      confirmBookingRQ: "",
      bookedPrice: {
        currencyCode: "",
        totalAmount: 0,
        sellingCurrencyCode: "",
        sellingTotalAmount: 0
      },
      isIssueTicketConfirmModalVisible: false,
      isIssueTicketAgreed: false,
      passCriteria: JSON.parse(sessionStorage.getItem("passCriteria"))
    };
  },
  computed: {
    ...mapGetters("rail", [
      "selectedValidity",
      "selectedPass",
      "bookInitiateRS",
      "bookTicketRS"
    ]),
    ...mapGetters("account", ["accountInfo", "loginStatusRS", "logedIn"])
  },
  watch: {
    bookInitiateRS() {
      //initiate
      this.isOverLayShow = false;
      if (this.bookInitiateRS.success) {
        this.isInitiateStep = false;
        this.isQuoteStep = true;
        this.correlationID = this.bookInitiateRS.correlationID;
        this.bookedPrice = RailUtils.getInitiateBookedPrice(
          this.bookInitiateRS.railReservations[0]
        );
        this.travelersForm = RailUtils.getTravelersFormArray(
          this.bookInitiateRS.railReservations[0]
        );
      } else {
        this.alertSettings.title = this.bookInitiateRS.errorTitle;
        this.alertSettings.alertMsg = this.bookInitiateRS.errorMsg;
        this.alertSettings.alertVariant = "danger";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;
      }
    },
    bookTicketRS() {
      this.isOverLayShow = false;
      if (this.bookTicketRS.success) {
        this.isQuoteStep = false;
        this.isPreBooked = true;
        // this.bookedPrice = RailUtils.getBookedPrice(this.bookTicketRS.railReservations[0])
        this.confirmBookingRQ = RailUtils.getConfirmBookingRQ(
          this.bookTicketRS
        );
        this.alertSettings.title = "Successfully!";
        this.alertSettings.alertMsg =
          "Booking was created, please check out to issue tickets.";
        this.alertSettings.alertVariant = "success";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;
      } else {
        this.alertSettings.title = this.bookTicketRS.errorTitle;
        this.alertSettings.alertMsg = this.bookTicketRS.errorMsg;
        this.alertSettings.alertVariant = "danger";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;
      }
    }
  },
  created() {
    let selectedValiditySeaaion = JSON.parse(
      sessionStorage.getItem("selectedValidity")
    );
    this.updateValidity({
      selectedValidity: selectedValiditySeaaion
    });
  },
  methods: {
    ...mapActions("rail", ["bookTicket", "bookInitiate", "updateValidity"]),
    countDownChanged(dismissCountDown) {
      this.alertSettings.dismissCountDown = dismissCountDown;
    },
    toOpenIssueTicketConfirmModal() {
      this.isIssueTicketConfirmModalVisible = true;
    },
    hideIssueTicketModal() {
      this.isIssueTicketConfirmModalVisible = false;
      this.isIssueTicketAgreed = false;
    },
    checkOut: function() {
      //hide modal
      this.isIssueTicketConfirmModalVisible = false;
      this.isIssueTicketAgreed = false;

      if (this.confirmBookingRQ != "") {
        this.isOverLayShow = true;
        RailAPI.confirmBooking(this.confirmBookingRQ)
          .then(res => {
            this.isOverLayShow = false;
            let confirmbookingRS = res.data;
            if (
              confirmbookingRS.confirmations != undefined &&
              confirmbookingRS.confirmations.length > 0
            ) {
              this.isPreBooked = false;
              this.isConfirmed = true;
              this.alertSettings.title = "Successfully!";
              this.alertSettings.alertMsg =
                "Booking was confirmed. Tickets were issued.";
              this.alertSettings.alertVariant = "success";
              this.alertSettings.dismissCountDown =
                this.alertSettings.countDownSecs + 10;
            } else if (
              !confirmbookingRS.success &&
              confirmbookingRS.errors &&
              confirmbookingRS.errors.errors
            ) {
              let error = confirmbookingRS.errors.errors[0];
              let stringValue = error.stringValue;
              this.setAlertMessage("danger", "Sorry!", stringValue, 15);
            } else {
              this.alertSettings.title = "Sorry!";
              this.alertSettings.alertMsg = "Supplier response errors.";
              this.alertSettings.alertVariant = "danger";
              this.alertSettings.dismissCountDown =
                this.alertSettings.countDownSecs + 10;
            }
          })
          .catch(error => {
            let title = "Sorry!";
            let errorMsg = "";
            if (error.response) {
              try {
                errorMsg = error.response.data.errors.errors[0].stringValue;
              } catch {
                console.info(error.response);
                errorMsg = "Some reponse errors";
              }
            } else if (error.request) {
              console.info(error.request);
              errorMsg = "Some request errors";
            } else {
              console.info(error);
              errorMsg = "Some data errors";
            }
            this.isOverLayShow = false;

            this.alertSettings.title = title;
            this.alertSettings.alertMsg = errorMsg;
            this.alertSettings.alertVariant = "danger";
            this.alertSettings.dismissCountDown =
              this.alertSettings.countDownSecs + 10;
          });
      } else {
        this.alertSettings.title = "Sorry!";
        this.alertSettings.alertMsg = "Supplier request error.";
        this.alertSettings.alertVariant = "danger";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;
      }
    },
    setAlertMessage(type, title, message, countDown) {
      this.alertSettings.title = title;
      this.alertSettings.alertMsg = message;
      this.alertSettings.alertVariant = type;
      this.alertSettings.dismissCountDown =
        this.alertSettings.countDownSecs + countDown;
    },
    toRailHome() {
      this.$router.push({
        name: "rail-home"
      });
    },
    toBookingList() {
      this.$router.push({
        name: "bookingRail"
      });
    },
    toPreBook: function(travelerForm) {
      //从TravelerFormVue子组件传递的traveler信息
      console.log(travelerForm);
      this.travelerFormValues = travelerForm;
      this.travelers = RailUtils.getPassTravelersInfo(travelerForm);
      // this.componentName = "TravelerInfoVue"
      let islogedIn = this.logedIn;
      let accountInfoFromSession = JSON.parse(
        sessionStorage.getItem("accountInfo")
      );
      let username = accountInfoFromSession.id;
      if (islogedIn) {
        let bookRQ = RailUtils.getBookRQ({
          correlationID: this.correlationID,
          travelerFormValues: travelerForm,
          username: username
        });
        this.bookTicket(bookRQ);
        this.isOverLayShow = true;
      } else {
        this.alertSettings.title = "Sorry!";
        this.alertSettings.alertMsg = "Please log in.";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;
      }
    },
    toInitiate() {
      let islogedIn = this.logedIn;
      let accountInfoFromSession = JSON.parse(
        sessionStorage.getItem("accountInfo")
      );
      let username = accountInfoFromSession.id;
      if (islogedIn) {
        let rphs = [];
        rphs.push(this.selectedValidity.rph);
        let bookRQ = RailUtils.getBookInitiateRQ({
          rphs: rphs,
          username: username
        });
        this.isOverLayShow = true;
        this.bookInitiate(bookRQ);
      } else {
        this.alertSettings.title = "Sorry!";
        this.alertSettings.alertMsg = "Please log in.";
        this.alertSettings.dismissCountDown =
          this.alertSettings.countDownSecs + 10;
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-overlay :show="isOverLayShow" rounded="sm">
      <b-row>
        <b-col cols="9">
          <b-card no-body>
            <!-- <b-card-header header="Pass"></b-card-header> -->
            <b-card-body>
              <div>
                <h5 class="font-size-14 text-primary">
                  {{ selectedValidity.label }}
                </h5>
                <ul class="list-unstyled">
                  <li>
                    {{
                      selectedValidity.passType == "CONSECUTIVE"
                        ? "Continuous Travel"
                        : "Flexible Travel"
                    }}
                  </li>
                  <li>
                    Choose to travel{{
                      selectedValidity.passType == "CONSECUTIVE"
                        ? " continuously on either "
                        : " flexibly between "
                    }}
                    <span
                      v-for="(validity, idx) in selectedPass.validityOptions"
                      :key="idx"
                      >{{ validity }},&nbsp;</span
                    >
                  </li>
                  <li v-if="selectedValidity.passType == 'FLEXIBLE'">
                    Travel days can be anytime within
                    {{ selectedPass.within }} of the first date of travel, as
                    written on your pass.
                  </li>
                  <li v-html="selectedValidity.includes"></li>
                </ul>
              </div>

              <div class="mt-2">
                <ul class="list-unstyled">
                  <li>
                    <b-icon icon="circle-fill" variant="danger"></b-icon>&nbsp;
                    <b>From &nbsp; &nbsp;{{ passCriteria.validFrom }}</b>
                  </li>
                  <li>
                    <b-icon icon="circle-fill" variant="danger"></b-icon>&nbsp;
                    <b
                      >{{ selectedValidity.validity }}
                      {{
                        selectedValidity.passType == "CONSECUTIVE"
                          ? "continuous "
                          : "flexible "
                      }}
                      pass -

                      {{
                        selectedValidity.class == "STANDARD" ? "2nd " : "1st "
                      }}
                      Class -

                      {{ selectedValidity.sellingCurrencyCode }}&nbsp;&nbsp;
                      {{ selectedValidity.sellingAmount }}</b
                    >
                  </li>
                </ul>
              </div>
              <div v-if="isPreBooked || isConfirmed">
                <b-row>
                  <b-col cols="2">
                    Travelers
                  </b-col>
                  <b-col>
                    <p v-for="(item, index) in travelers" :key="index">
                      {{ item.passenger }}
                    </p>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
          </b-card>

          <div class="p-2 d-flex justify-content-end" v-if="isInitiateStep">
            <b-button variant="success" @click="toInitiate"
              >Continue to Passengers</b-button
            >
          </div>

          <!-- Traveler info -->
          <PassTravelerFormVue
            v-if="isQuoteStep"
            :travelers="travelersForm"
            @confirm="toPreBook"
          ></PassTravelerFormVue>

          <div
            v-if="isPreBooked"
            :disabled="isConfirmed"
            class="my-3 d-flex justify-content-end"
          >
            <b-button
              variant="success"
              @click="toOpenIssueTicketConfirmModal"
              v-permission="{
                permission: 'agencyportal.rail.ticketing',
                effect: 'hidden'
              }"
              >Check Out</b-button
            >
          </div>

          <div class="p-2 d-flex justify-content-end" v-if="isConfirmed">
            <!-- <b-button variant="success" @click="toRailHome">Find more Tickets</b-button> -->
            <b-button variant="success" @click="toBookingList"
              >Booking List</b-button
            >
          </div>
        </b-col>

        <b-col cols="3">
          <b-card no-body>
            <b-card-header
              header-class="bg-soft-primary"
              header="Price Summary"
            ></b-card-header>
            <b-card-body>
              <div>
                <h6 class="font-size-14">{{ selectedValidity.label }}</h6>
                <p>
                  {{
                    selectedValidity.passType == "CONSECUTIVE"
                      ? "Continuous Travel"
                      : "Flexible Travel"
                  }}
                </p>
              </div>
              <div class="mt-4" v-if="isInitiateStep">
                TOTAL&nbsp; &nbsp;<span class="text-danger"
                  ><b
                    >{{ selectedValidity.sellingCurrencyCode }}&nbsp;&nbsp;
                    {{ selectedValidity.sellingAmount }}</b
                  ></span
                >
              </div>
              <div class="mt-4" v-if="!isInitiateStep">
                Total after commission&nbsp; &nbsp;<span class="text-danger"
                  ><b
                    >{{ bookedPrice.sellingCurrencyCode }}&nbsp;&nbsp;
                    {{ bookedPrice.sellingTotalAmount }}</b
                  ></span
                >
                <div
                  v-for="(taxItem, taxIndex) in bookedPrice.taxes"
                  :key="`tax-${taxIndex}`"
                >
                  <small
                    >{{ taxItem.name }}
                    <span class="text-danger">{{
                      taxItem.sellingCurrencyCode + " " + taxItem.sellingAmount
                    }}</span></small
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <b-alert
      :show="alertSettings.dismissCountDown"
      class="position-fixed fixed-top m-0 rounded-0 text-center"
      style="z-index: 2000;"
      :variant="alertSettings.alertVariant"
      dismissible
      @dismiss-count-down="countDownChanged"
    >
      <!--            <div v-html="alertSettings.title"></div>-->
      <div v-html="alertSettings.alertMsg"></div>
    </b-alert>

    <b-modal
      id="issue-ticket-confirm-modal"
      v-model="isIssueTicketConfirmModalVisible"
      centered
      hide-footer
      title="Issue ticket application"
    >
      <div class="my-1">
        Total Amount:
        <span class="text-danger"
          ><b
            ><span class="mr-1">{{ bookedPrice.sellingCurrencyCode }}</span
            >{{ bookedPrice.sellingTotalAmount }}</b
          ></span
        >
      </div>

      <div>
        <p>
          <input v-model="isIssueTicketAgreed" type="checkbox" class="mr-2" />
          I agree to make payment and issue ticket by clicking "Issue Ticket"
          button
        </p>
      </div>
      <div class="d-flex justify-content-between">
        <b-button @click="hideIssueTicketModal" variant="secondary">
          Cancel
        </b-button>

        <b-button
          @click="checkOut"
          variant="primary"
          :disabled="!isIssueTicketAgreed"
        >
          Issue Ticket
        </b-button>
      </div>
    </b-modal>
  </Layout>
</template>
