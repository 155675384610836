/*
* 代替视图，与mutation进行交互，根据用户需求通过hotelApi工具包请求后端数据，通过mutations更新state中的状态
* */
import * as types from "./mutationsTypes"
import bookingApi from "../../../api/bookingApi";
// import{ sampleBookingHistory } from '../../../bookingHistory';
// import { sampleRailBooking } from "../../../zsampledata/sampleRailBooking";

const actions = {
    getBookingHistoryRS({commit}, bookingHistoryRQ){
        bookingApi.getBookingList(bookingHistoryRQ).then(res =>{
            let bookingHistoryRS = res.data;
            if(bookingHistoryRS.totalElements !== undefined){
                bookingHistoryRS.success = true   
            }else {
                bookingHistoryRS.success = false
                bookingHistoryRS.errorTitle = "Sorry!"
                bookingHistoryRS.errorMsg = "Loading data unsuccessfully"

            }
            commit(types.UPDATE_BOOKING_HISTORY_RS, {
                bookingHistoryRS: bookingHistoryRS,
            });
        }).catch(error => {
            let errorRS = {}
            let title = 'Sorry!'
            let errorMsg = ''
            if(error.response){
                try{
                    errorMsg = error.response.data.errors.errors[0].stringValue
                }catch{
                    console.info(error.response)
                    errorMsg = "Some reponse errors"
                }
                
            }else if(error.request){
                console.info(error.request)
                errorMsg = "Some request errors"
            }else{
                console.info(error)
                errorMsg = "Some data errors"
            }
            errorRS.errorTitle = title
            errorRS.errorMsg = errorMsg
            errorRS.success = false
            commit(types.UPDATE_BOOKING_HISTORY_RS, {
                bookingHistoryRS: errorRS,
            }); 
        });
    },

    getUserInfo({commit}, payload){
        bookingApi.getUserInfoApi(payload.getUserInfoRQ).then(res =>{
            let userInfoRS = "";
            userInfoRS = res.data;
            commit(types.UPDATE_USERINFO, {
                userInfoRS: userInfoRS,
            });
        });
    },
    cancelBookingActions({commit}, payload){
        bookingApi.cancelBookingApi(payload.cancelRQ).then(res =>{
            let cancelRS = "";
            cancelRS = res.data;
            commit(types.UPDATE_CANCELRS, {
                cancelRS: cancelRS,
            });
        });
    },

    getBookingDetails({commit}, payload){
        bookingApi.getBookingDetails(payload.searchDetailsRQ).then(res => {
            let bookingDetailsRS = res.data
            if(bookingDetailsRS.errors) {
                bookingDetailsRS.success = false
                let title = "Sorry!"
                let errorMsg = ""
                try{
                    let errorString = bookingDetailsRS.errors.errors[0].stringValue
                    title = JSON.parse(errorString.replace('\\','').substring(errorString.indexOf('{'))).label
                    errorMsg = JSON.parse(errorString.replace('\\','').substring(errorString.indexOf('{'))).details
                }catch{
                    errorMsg = 'Booking not found with given identifier.'
                }
                bookingDetailsRS.errorTitle = title
                bookingDetailsRS.errorMsg = errorMsg
            }else{
                bookingDetailsRS.success = true
            }
            commit(types.UPDATE_BOOKING_DETAILS_RS, {
                bookingDetailsRS: bookingDetailsRS
            })
        }).catch(error => {
            let errorRS = {}
            let title = 'Sorry!'
            let errorMsg = ''
            if(error.response){
                try{
                    errorMsg = error.response.data.errors.errors[0].stringValue
                }catch{
                    console.info(error.response)
                    errorMsg = "Some reponse errors"
                }
                
            }else if(error.request){
                console.info(error.request)
                errorMsg = "Some request errors"
            }else{
                console.info(error)
                errorMsg = "Some data errors"
            }
            errorRS.errorTitle = title
            errorRS.errorMsg = errorMsg
            errorRS.success = false
            commit(types.UPDATE_BOOKING_DETAILS_RS, {
                bookingDetailsRS: errorRS
            })
        })
    },
    

    updateSelectedBooking({commit}, payload) {
        commit(types.UPDATE_SELECTED_BOOKING, {
            selectedBooking: payload.selectedBooking    
        })
    },

    updateBookingDetails({commit}, payload) {
        commit(types.UPDATE_BOOKING_DETAILS, {
            bookingDetails: payload.bookingDetails    
        })
    },
    addGoodsToCart({commit}, payload){
        console.info(payload.goods);
        bookingApi.addGoodsToCart(payload.goods).then(res => {
            let shoppingCart = [];
            if (res.data && res.data.length>0){
                res.data.forEach(data => {
                    let parse = JSON.parse(data);
                    shoppingCart.push(parse)
                })
            }
            commit(types.UPDATE_SHOPPING_CART, {
                shoppingCart: shoppingCart
            })
        }).catch(() => {
            commit(types.UPDATE_SHOPPING_CART, {
                shoppingCart: []
            })
        })
    },

    removeGoodsFromCart({commit}, payload){
        return bookingApi.removeGoodsFromCart(payload.indexes).then(res => {
            let shoppingCart = [];
            if (res.data && res.data.length>0){
                res.data.forEach(data => {
                    let parse = JSON.parse(data);
                    shoppingCart.push(parse)
                })
            }
            commit(types.UPDATE_SHOPPING_CART, {
                shoppingCart: shoppingCart
            })
        }).catch(() => {
            commit(types.UPDATE_SHOPPING_CART, {
                shoppingCart: []
            })
        })
    },
    loadGoodsFromCart({commit}){
        return bookingApi.getGoodsFromCart().then(res => {
            let shoppingCart = [];
            if (res.data && res.data.length>0){
                res.data.forEach(data => {
                    let parse = JSON.parse(data);
                    shoppingCart.push(parse)
                })
            }
            commit(types.UPDATE_SHOPPING_CART, {
                shoppingCart: shoppingCart
            })
        }).catch(() => {
            commit(types.UPDATE_SHOPPING_CART, {
                shoppingCart: []
            })
        })
    },
}

export default actions;