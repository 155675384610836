/**
*  获取state中各类信息，包括原始信息与重构后的信息
* */

const getters = {
    dynamicpkgRS(state){
        return state.dynamicpkgRS
    },
    selectedPkgHotelGetter(state){
        console.log(state.selectedPkgHotel)
        return state.selectedPkgHotel;
    },
    pkgVerifyRS(state){
        return state.pkgVerifyRS
    },
    pkgPreBookRS(state){
        return state.pkgPreBookRS
    }
}

export default getters;