const state = {
    oneWayFlightList:[],
    roundTripFlightList:[],
    verifyPriceRS:{},
    bookRS:{},
    mutipleCities:[]
}

export default state;

