const getAuthenticationRQ = function (username,password) {
    //todo
    let loginURL = "/api/v1/account/authentication?username="+username+"&password="+password+"&systemType=6"
    return loginURL
}

const getB2BAuthenticationRQ = function (username,password, captcha) {
    let loginURL = "/api/v2/b2b/security/login/authentication?username="+username+"&password="+password+"&captcha="+captcha;
    return loginURL
}

const getModifyPasswordRequest = function(userInfo,newPassword) {

    let userId = {
        "pin_number": userInfo.password,
        "id": userInfo.username
    }
    return {
        "profileModify":{
            "profileModify": {
                "customer": {
                },
                "userIDList": [
                    userId
                ]
            },
        },
        "uri":"/api/v1/account/modification?newPassword="+newPassword
    }
}

const getProfileModifyRequest=function(customer, userInfo) {

    let userId ;

    if (userInfo.changePassword!=undefined && userInfo.changePassword!=""){
        userId = {
            "pin_number": userInfo.changePassword,
            "id": userInfo.username
        }
    }else {
        userId = {
            "id": userInfo.username
        }
    }
    return {
        "profileModify": {
            "customer":customer,
            "userIDList": [
                userId
            ]
        }
    }
}


// const getAddNewAccountRQ = function(payload) {
//     let newAccount = payload.newAccount
//     let addNewAccountRQ = {}
//     addNewAccountRQ.username = newAccount.username
//     addNewAccountRQ.hashedPassword = newAccount.password
//     addNewAccountRQ.active = newAccount.active
//     addNewAccountRQ.enabled = true
//     addNewAccountRQ.employerPartyId = 1
//     addNewAccountRQ.securityGroupSet = []
//     addNewAccountRQ.securityGroupSet.push({"groupId": newAccount.groupId})

//     return addNewAccountRQ
// }

const getAddNewAccountRQ = function(payload) {
    let newAccount = payload.newAccount
    let addNewAccountRQ = {
        "username": "",
        "surname": newAccount.lastName,
        "givenName": newAccount.firstName,
        "nameTitle": "",
        "role": "",
        "password": newAccount.password,
        "confirmPassword": newAccount.confirmPassword,
        "telephones": [
            {
                "phoneNumber": ""
            }
        ],
        "emails": [
            {
                "emailAddress": newAccount.username
            }
        ],
        "securityGroups": [
        ]
    }

    addNewAccountRQ.securityGroups = newAccount.groupIds

    return addNewAccountRQ
}

export default  {
    getAuthenticationRQ,
    getB2BAuthenticationRQ,
    getModifyPasswordRequest,
    getProfileModifyRequest,
    getAddNewAccountRQ
}