import Vue from "vue";
import store from '../state/store'

//自定义指令，用来控制按钮权限
const permission = Vue.directive('permission', {
  inserted (el, binding) {
    // 当前按钮传递的值( v-permission="{action:'add',effect:'hidden" 中的值)
    const requiredPermission = binding.value.permission
    const effect = binding.value.effect
    // 获取到存放在 store 中的权限数据

    let permissions = store.getters['account/permissions']
    // let permissions = JSON.parse(sessionStorage.getItem('permissions'))
    // console.info(permissions)
    // 判断是否存在对应的按钮权限
    let item = permissions.filter((item) => {
      return item === requiredPermission
    })

    //不具备权限则删除（隐藏）或者禁用该按钮 (el.parentNode 获取当前节点的父节点),根据在芫荽绑定的值去决定
    if (item.length === 0) {
      if (effect === 'disabled') {
        el.disabled = true
      } else {
        el.classList.add('is-hidden')
        // el.parentNode.removeChild(el)
      }
    }
  }
})

export default permission