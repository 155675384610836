/**
*  获取state中各类信息，包括原始信息与重构后的信息
* */

const getters = {
    availRS(state){
       if (state.availRS == undefined){
           return {}
       }
        return state.availRS;
    },
    selectedHotel(state){
        return state.selectedHotel;
    },
    hotelDescription(state){
      return state.hotelDescription;
    },
    hotels(state){
        return state.hotels
    },
    passengerConfirmation(state){
        return state.passengerConfirmation
    },
    hotelRoomConfirmation(state){
        return state.hotelRoomConfirmation
    },
    confirmRS(state){
        return state.confirmRS
    },
    B2BBookRS(state){
        return state.B2BBookRS
    },
    preBookRS(state){
        return state.preBookRS
    }
}

export default getters;