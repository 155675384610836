<script>
import Layout from "../layouts/main.vue";
import PageHeader from "../../components/page-header.vue";
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";

export default {
  name: "",
  components: {
    Layout,
    PageHeader
  },
  mixins: [validationMixin],
  data() {
    return {
      title: "Pass Options",
      items: [
        {
          // #TODO
          text: "Rail",
          to: { name: "rail-home" }
        },
        {
          text: "Pass Options",
          active: true
        }
      ],
      optionSelected: {
        classSelected: "firstClass",
        typeSelected: "consecutive",
        validitySelected: "3Days",
        collectionSelected: "E_TICKET"
      },
      passOptions: {
        classOptions: [
          { text: "First Class", value: "firstClass" },
          { text: "Second Class", value: "secondClass" },
          { text: "Third Class", value: "thirdClass" }
        ],
        typeOptions: [
          { text: "Consecutive", value: "CONSECUTIVE" },
          { text: "Flexible", value: "FLEXIBLE" }
        ],
        validityOptions: [
          { text: "3 Days", value: "3Days" },
          { text: "4 Days", value: "4Days" },
          { text: "6 Days", value: "6Days" },
          { text: "8 Days", value: "8Days" },
          { text: "15 Days", value: "15Days" }
        ],
        collectionOptions: [{ text: "E-Ticket", value: "E_TICKET" }]
      },
      errorMsgs: {
        checkDate: {
          text: "Please select a date"
        },
        validity: {
          text: "Please select a validity"
        }
      },
      isAddMoreItemsInquiryModalVisible: false,
      isValidityNotSelected: null,
      checkDate: "",
      displayedConditions: [],
      selectedClass: "",
      selectedClassOptions: [],
      selectedValidity: {},
      cartBuffer: {}
    };
  },
  validations() {
    return {
      // checkDate: {
      //     required
      // },
    };
  },
  computed: {
    ...mapGetters("rail", ["selectedPass", "passCriteria"])
  },
  watch: {
    selectedClass: function() {
      this.selectedClassOptions = this.selectedPass[this.selectedClass];
      this.selectedValidity = {};
    },
    selectedValidity: function() {
      if (this.selectedValidity.label) {
        this.isValidityNotSelected = false;
      }
    }
  },
  created() {
    let selectedPasses = JSON.parse(sessionStorage.getItem("selectedPass"));
    console.info(selectedPasses);
    if (selectedPasses.classes != undefined) {
      this.selectedClass = selectedPasses.classes[0];
    }

    if (
      selectedPasses["STANDARD"] != undefined &&
      selectedPasses["STANDARD"] != null
    ) {
      if (selectedPasses["STANDARD"].length > 0) {
        for (
          let i = 0;
          i < selectedPasses["STANDARD"][0].conditions.length;
          i++
        ) {
          let con = {};
          let item = selectedPasses["STANDARD"][0].conditions[i];
          if (item.code != "INCLUDES") {
            if (item.code == "PASS_RANGE_SALES") {
              con.title = "Pass Range Sales";
              con.stringValue = item.stringValue;
              this.displayedConditions.push(con);
            }
            if (item.code == "PASS_RANGE_AFTER_SALES") {
              con.title = "Pass After Sales";
              con.stringValue = item.stringValue;
              this.displayedConditions.push(con);
            }
            if (item.code == "TICKETING_METHOD") {
              con.title = "Ticketing Method";
              con.stringValue = item.stringValue;
              this.displayedConditions.push(con);
            }
            if (item.code == "GLOBAL_DESCRIPTION") {
              con.title = "Global Description";
              con.stringValue = item.stringValue
                .replace("<p><br></p>", "")
                .replace("<br>", "")
                .replace("<p><br><br><br><br><br></p>", "");
              this.displayedConditions.push(con);
            }
          }
        }
      }
    }
    let passCriterias = JSON.parse(sessionStorage.getItem("passCriteria"));
    if (passCriterias.validFrom != undefined) {
      this.checkDate = passCriterias.validFrom;
    }

    this.updateRailPasses({
      selectedPass: selectedPasses,
      passCriteria: passCriterias
    });
  },
  methods: {
    ...mapActions("rail", ["selectValidity", "updateRailPasses"]),
    ...mapActions("booking", ["addGoodsToCart"]),
    validateState(field) {
      const { $dirty, $error } = this.$v[field];
      return $dirty ? !$error : null;
    },
    confirmOption() {
      // this.$v.$touch()
      // if(!this.$v.$invalid) {
      //     if(!this.selectedValidity.label){
      //         this.isValidityNotSelected = true
      //     }else {
      //         this.isValidityNotSelected = false
      //         this.selectValidity({
      //             selectedValidity: this.selectedValidity
      //         })
      //         this.$router.push({
      //             path: 'passDetails'
      //         })
      //     }
      // }

      if (!this.selectedValidity.label) {
        this.isValidityNotSelected = true;
      } else {
        this.isValidityNotSelected = false;
        this.selectValidity({
          selectedValidity: this.selectedValidity
        });
        this.cartBuffer.pass = this.selectedValidity;
        this.cartBuffer.pass.validFrom = this.passCriteria.validFrom;
        this.isAddMoreItemsInquiryModalVisible = true;
        // this.$router.push({
        //     path: 'passDetails'
        // })
      }
    },
    putIntoCart() {
      this.isAddMoreItemsInquiryModalVisible = false;
      this.addGoodsToCart({ goods: this.cartBuffer });
      this.$router.push({
        name: "rail-home"
      });
    },
    bookTicketNow() {
      this.isAddMoreItemsInquiryModalVisible = false;
      this.$router.push({
        path: "passDetails"
      });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-row v-if="selectedPass.label != undefined">
      <!--   Pass options  -->
      <b-col cols="6">
        <b-card>
          <div>
            <span class="font-size-16">
              <b>{{ selectedPass.label }}</b>
            </span>
          </div>

          <div class="mt-2">
            <p>
              <b>Includes:</b
              ><span
                v-html="selectedPass.includes.replace(/<[^>]*>/g, '')"
              ></span>
            </p>
          </div>

          <div>
            <h5 class="font-size-14">Select Options</h5>

            <b-form-group label="Check Availability">
              Valid from {{ checkDate }}
            </b-form-group>
            <!-- Options -->
            <!-- <b-form-group 
                    label="Check Availability"
                    >
                        <b-row>
                            <b-col cols="6">
                                <b-form-datepicker
                                v-model="$v.checkDate.$model"
                                :min="selectedPass.earliestDate" 
                                :max="selectedPass.latestDate"
                                :state="validateState('checkDate')"
                                aria-describedby="check-date-feedback"
                                ></b-form-datepicker>
                                <b-form-invalid-feedback id="check-date-feedback">
                                    {{errorMsgs.checkDate.text}}</b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        
                    </b-form-group> -->

            <!-- <b-form-group 
                    label="Pass Type"
                    >
                        <b-form-radio-group
                            id="btn-radios-2"
                            :checked="selectedPass.passType"
                            size="sm"
                            button-variant="outline-info"
                            buttons
                        >
                            <b-form-radio value="CONSECUTIVE" :disabled="selectedPass.passType != 'CONSECUTIVE'">Consecutive</b-form-radio>
                            <b-form-radio value="FLEXIBLE" :disabled="selectedPass.passType != 'FLEXIBLE'">Flexible</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group> -->

            <b-form-group label="Comfort Category">
              <b-form-radio-group
                id="btn-radios-1"
                v-model="selectedClass"
                :options="selectedPass.classes"
                name="radios-btn-default"
                size="sm"
                button-variant="outline-secondary"
                buttons
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group label="Validity">
              <!-- <b-form-radio-group
                            id="btn-radios-3"
                            class="mx-1"
                            v-model="optionSelected.validitySelected"
                            :options="passOptions.validityOptions"
                            name="radios-btn-default"
                            size="sm"
                            button-variant="outline-primary"
                            buttons
                        ></b-form-radio-group> -->
              <b-form-radio-group
                id="btn-radios-3"
                class="mx-1"
                v-model="selectedValidity"
                name="radios-btn-default"
                size="sm"
                button-variant="outline-primary"
                buttons
              >
                <b-form-radio
                  v-for="(option, index) in selectedClassOptions"
                  :value="option"
                  :key="`validity-radio-${index}`"
                  >{{ option.validity }}</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>

            <b-form-group label="Collection">
              <b-form-radio-group
                id="btn-radios-4"
                v-model="optionSelected.collectionSelected"
                :options="passOptions.collectionOptions"
                name="radios-btn-default"
                size="sm"
                button-variant="outline-danger"
                buttons
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group label="Quantity">
              <b-row class="my-1" align-v="center">
                <b-col cols="2">
                  <label>Adult(28-59)</label>
                </b-col>
                <b-col cols="1">
                  <b-form-input
                    size="sm"
                    readonly
                    :value="passCriteria.adult"
                  ></b-form-input>
                </b-col>
              </b-row>

              <b-row class="my-1" align-v="center">
                <b-col cols="2">
                  <label>Senior(60+)</label>
                </b-col>
                <b-col cols="1">
                  <b-form-input
                    size="sm"
                    readonly
                    :value="passCriteria.senior"
                  ></b-form-input>
                </b-col>
              </b-row>

              <b-row class="my-1" align-v="center">
                <b-col cols="2">
                  <label>Youth(0-27)</label>
                </b-col>
                <b-col cols="1">
                  <b-form-input
                    size="sm"
                    readonly
                    :value="passCriteria.youth"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <b-form-input
                    style="width: 2rem"
                    v-for="(ageItem, a_idx) in passCriteria.youthAges"
                    :key="`age_${a_idx}`"
                    size="sm"
                    readonly
                    :value="ageItem.age"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-invalid-feedback :state="!isValidityNotSelected">
              {{ errorMsgs.validity.text }}</b-form-invalid-feedback
            >
          </div>
          <div>
            <p>
              From&nbsp; &nbsp;<span class="text-danger"
                ><b
                  >{{ selectedPass.sellingCurrencyCode }}&nbsp;&nbsp;{{
                    selectedValidity.sellingAmount
                  }}</b
                ></span
              >
            </p>
            <b-button variant="success" @click="confirmOption">Next</b-button>
          </div>
        </b-card>
      </b-col>

      <!-- Price summary -->
      <b-col cols="6">
        <b-card no-body>
          <b-card-header
            header-class="bg-soft-primary"
            header="Price Summary"
          ></b-card-header>
          <b-card-body>
            <div>
              <h5 class="font-size-14">{{ selectedPass.label }}</h5>
              <p>
                {{
                  selectedPass.passType == "CONSECUTIVE"
                    ? "Continuous Travel"
                    : "Flexible Travel"
                }}
              </p>
            </div>
            <div>
              From&nbsp; &nbsp;<span class="text-danger">
                <b
                  >{{ selectedPass.sellingCurrencyCode }}&nbsp;&nbsp;
                  {{ selectedValidity.sellingAmount }}</b
                >
              </span>
            </div>

            <div class="mt-3 border-top pt-3">
              <h6>Terms and Conditions</h6>
              <div
                class="mt-2"
                v-for="(conItem, idx) in displayedConditions"
                :key="`con-${idx}`"
              >
                <p>
                  <b>{{ conItem.title }}</b>
                </p>
                <p v-html="conItem.stringValue"></p>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <div>
      <b-modal
        v-model="isAddMoreItemsInquiryModalVisible"
        id="add-more-items-inquiry-modal"
        title="Inquiry"
        centered
        size="lg"
        no-close-on-backdrop
        hide-footer
        ok-title="Save"
        ok-variant="success"
      >
        <div class="text-center">
          <b-row>
            <b-col>
              <p class="font-size-16">
                You could book the ticket now.
                <br />You could also put it into cart, add more tickets, and
                then book them at once.
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button variant="info" @click="putIntoCart"
                >Put Into Cart</b-button
              >
            </b-col>
            <b-col>
              <b-button variant="success" @click="bookTicketNow"
                >Book Ticket Now</b-button
              >
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </div>
  </Layout>
</template>
