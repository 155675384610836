import axios from "../utils/axiosUtils"

export default {
    getCaptcha () {
        let url = '/api/v1/b2b/security/login/captcha'
        return axios.get(url, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'image/jpeg',
            }
        })
    },
}