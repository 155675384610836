<script>
import Layout from '../layouts/main.vue';
import PageHeader from '../../components/page-header.vue';
import RailAPI from '../../api/RailAPI';
import {mapActions, mapGetters, mapState} from 'vuex'
import bookingUtils from "../../utils/booking/bookingUtils";
// import DateUtils from '../../utils/DateUtils';

export default {
    name: "BookingRailList",
    components:{
        Layout,
        PageHeader
    },
    data(){
        return{
            title: "Rail Reservations",
            items: [
                {
                    text: "Booking",
                },
                {
                    text: "Rail Reservations",
                    active: true,
                }
            ],
            alertSettings: {
                dismissCountDown: 0,
                countDownSecs: 4,
                title: '',
                alertMsg: '',
                alertVariant: 'warning'
            },
            username: '',
            pageOffset: 0,
            perPage: 10,
            currentPage: 1,
            isOverLayShow: false,
            isIssueTicketConfirmModalVisible: false,
            isIssueTicketAgreed: false,
            isBusy: true,
            filter: null,
            fields: [
                "actions",
                { key: "bookingId", label: "JTL no." },
                {key: "reference", label: "Supplier Ref."},
                {key: "resStatus", label: "Booking Status"},
                { key: "project", label: "Type"  },
                { key: "projectName", label: "From/To", sortable: true, },
                { key: "bookingDate", label: "DepTime/ArrTime", sortable: true, },
                { key: "passengers", label: "Passengers", sortable: true, },
                // { key: "status", label: "Status", sortable: true, },
                // { key: "view",   label: "View Detail", sortable: false, },
            ],
            bookingItems: [
            ],
            checkOutRailEuropeBookingId: '',
            checkOutBookingId: '',
            checkOutPrice: {},
            refreshBookingStatusBtnDisabled: false,
        }
    },
    computed:{
        ...mapState(["LayoutType", "Theme", "setHorizontalLayout"]),
        ...mapGetters("booking",[
            "bookingHistoryRS"
        ]),
        ...mapGetters("account",[
            "loginStatusRS"
        ]),
        totalRows: function(){
            let rows = 0
            if(this.bookingHistoryRS.success){
                rows = this.bookingHistoryRS.totalElements
            }
            return rows
        }
    },
    created() {
        let accountInfo = JSON.parse(sessionStorage.getItem('accountInfo'))
        this.username = accountInfo.id
        this.loadBookings()

    },
    watch:{
        bookingHistoryRS: function() {
            this.$nextTick(function(){
                this.isBusy = false
                this.refreshBookingStatusBtnDisabled = false
                if(this.bookingHistoryRS.success){
                    this.bookingItems = bookingUtils.getRailReservations(this.bookingHistoryRS)    
                }else{
                    this.setAlertMessage('danger', this.bookingHistoryRS.errorTitle, this.bookingHistoryRS.errorMsg, 15)
                }
            })
        },

        currentPage: function() {
            this.$nextTick(function(){
                this.isBusy = true
                this.pageOffset = (this.currentPage-1)*this.perPage
                this.loadBookings()
            })
        },
    },
    methods:{
        ...mapActions("booking", [
            "getBookingHistoryRS",
            "updateSelectedBooking",
            // "searchSelectedHotel"
        ]),
        countDownChanged(dismissCountDown) {
            this.alertSettings.dismissCountDown = dismissCountDown
        },
        setAlertMessage(type, title, message, countDown){
            this.alertSettings.title = title
            this.alertSettings.alertMsg = message
            this.alertSettings.alertVariant = type
            this.alertSettings.dismissCountDown = this.alertSettings.countDownSecs + countDown
        },
        PageTo(view,bookingId){
            this.$router.push({
                path:view,
                query:{
                    bookingId:bookingId
                }
            });

        },
        toOpenIssueTicketConfirmModal(item) {
            this.checkOutBookingId = item.bookingId
            this.checkOutRailEuropeBookingId = item.railEuropeBookingId
            this.checkOutPrice = item.priceDetail
            
            this.isIssueTicketConfirmModalVisible = true
        },
        hideIssueTicketModal(){
            this.isIssueTicketConfirmModalVisible = false
            this.isIssueTicketAgreed = false
        },
        checkOut(){
            this.hideIssueTicketModal()
            this.isOverLayShow = true

            let confirmBookingRQ = {}
            confirmBookingRQ.uniqueIDs = []
            confirmBookingRQ.uniqueIDs.push({id: this.checkOutRailEuropeBookingId})

            RailAPI.confirmBooking(confirmBookingRQ).then(res => {
                this.isOverLayShow = false
                let confirmbookingRS = res.data
                if(confirmbookingRS.confirmations !== undefined && confirmbookingRS.confirmations.length > 0){
                    this.refreshBookingList()
                    this.setAlertMessage('success', 'Successfully!', 'Booking was confirmed. Tickets were issued.', 15)
                }else if (!confirmbookingRS.success && confirmbookingRS.errors && confirmbookingRS.errors.errors){
                    let error = confirmbookingRS.errors.errors[0];
                    let stringValue = error.stringValue;
                    this.setAlertMessage('danger', 'Sorry!', stringValue, 15)
                }else {
                    let title = "Sorry!"
                    let errorMsg = ""
                    try{
                        let errorString = confirmbookingRS.errors.errors[0].stringValue
                        title = JSON.parse(errorString.replace('\\','').substring(errorString.indexOf('{'))).label
                        errorMsg = JSON.parse(errorString.replace('\\','').substring(errorString.indexOf('{'))).details
                    }catch{
                        errorMsg =  'Supplier reponse errors.'
                    }    

                    this.setAlertMessage('danger', title, errorMsg, 15)
                }
            }).catch(error => {
                this.isOverLayShow = false
                let title = 'Sorry!'
                let errorMsg = ''
                if(error.response){
                    try{
                        errorMsg = error.response.errors.errors[0].stringValue
                    }catch{
                        errorMsg = 'Some response errors.'
                    }
                }else if(error.request){
                    console.info(error.request)
                    errorMsg = 'Some request errors.'
                }else {
                    console.info(error)
                    errorMsg = 'Some data errors.'
                }
                this.setAlertMessage('danger', title, errorMsg, 15)
            })
        },
        rowItemClicked(item) {
            // let route = this.$router.resolve({
            //     path:'/booking/rail/view',
            //     query:{
            //         railEuropeBookingId:item.railEuropeBookingId,
            //         bookingId: item.bookingId
            //     }
            // })
            // window.open(route.href)

            this.updateSelectedBooking({
                selectedBooking: item
            })

            this.$router.push({
                path:'/booking/rail/view',
                query:{
                    railEuropeBookingId:item.railEuropeBookingId,
                    bookingId: item.bookingId
                }
            })
        },
        refreshBookingList(){
            this.refreshBookingStatusBtnDisabled = true
            this.isBusy = true
            this.loadBookings()
        },
        loadBookings(){
            let bookingHistoryRQ = bookingUtils.getRailReservationsRQ(this.username, null, this.pageOffset, this.perPage)
            console.log(JSON.stringify(bookingHistoryRQ))
            this.getBookingHistoryRS(bookingHistoryRQ)
        }

    },
}
</script>

<template>
<Layout>
    <PageHeader :title="title" :items="items" />
    <b-overlay :show="isOverLayShow">
    <b-card>
        <div class="mt-4 w-50">
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
        </div> 
        <div class="mt-4">
            <b-button variant="success" @click.prevent="refreshBookingList" :disabled="refreshBookingStatusBtnDisabled">
                <template >
                    Refresh booking status
                    <!-- <b-spinner small v-if="refreshBookingStatusBtnDisabled"></b-spinner> -->
                    <!-- <span class="">{{refreshBookingStatusBtnDisabled ? 'Loading...' : 'Refresh booking status'}}</span> -->
                </template>
            </b-button>
        </div>

        <div class="mt-1">         
            <b-table responsive striped hover
            tbody-tr-class="cursor-pointer"
            class="mb-0" 
            head-variant="dark" 
            :busy="isBusy"
            :fields="fields"
            :items="bookingItems" :filter="filter"  
            >
                <template #table-busy>
                    <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                    </div>
                </template>

                <template #cell(actions)="data">
                    <div class="d-flex">
                        <b-badge title="View Detail" class="mr-1 font-size-15" variant="primary" to="#" @click="rowItemClicked(data.item)"><i class="mdi mdi-arrow-top-right"></i></b-badge>
                        <div
						v-permission="{permission: 'agencyportal.rail.ticketing', effect: 'hidden'}"
						>
							<b-badge v-if="data.item.providerBookingStatus === 'PREBOOKED' && !data.item.expired" 
							title="Check Out" class="mr-1 font-size-15" variant="success" to="#" 
							@click="toOpenIssueTicketConfirmModal(data.item)"><i class="mdi mdi-credit-card"></i></b-badge>
						</div>
                    </div>
                    <!-- <b-button size="sm" variant="outline-secondary" @click="rowItemClicked(data.item)"><i class="mdi mdi-arrow-top-right"></i></b-button> -->
                </template>
                <template #cell(bookingId)="data"> #{{ data.item.bookingId }}</template>
                <template #cell(reference)="data">
                    <span>{{data.item.reference}} </span>
                </template>
                <template #cell(resStatus)="data">
                    <strong>{{ data.item.providerBookingStatus ? data.item.providerBookingStatus : '--' }}</strong> 
                    <b-badge v-if="data.item.expired" variant="secondary" pill class="ml-1">EXPIRED</b-badge>
                    <b-badge v-if="data.item.providerBookingStatus === 'PREBOOKED' && !data.item.expired" 
                    variant="danger" pill class="ml-1"
                    title="Expire in"
                    >
                        <i class="mdi mdi-clock"></i>
                        {{data.item.expireIn+'m'}}
                    </b-badge>
                </template>
                <template #cell(title)="data">
                    <span>{{ data.item.projectName }}</span>
                </template>
                <template #cell(projectName)="data">
                    <span>{{data.item.projectName ? data.item.projectName : '--'}}</span>
                </template>
                <template #cell(bookingDate)="data">
                    <span>{{data.item.bookingDate ? data.item.bookingDate : '--'}} </span>
                </template>
                
               
                <!-- <template #cell(status)="data">
                    <b-badge
                            pill
                            :class="`px-2 text-white badge bg-${data.item.status.statusbg}`"
                    >
                        <i class="font-9 mdi mdi-checkbox-blank-circle"></i>
                        {{ data.item.status.statustype }}
                    </b-badge>
                </template> -->
                
                
            </b-table>
        </div>

        <div
        class="mt-4 p-2"    
        >
            <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            :first-text="$t('pagenation.first.text')"
            :prev-text="$t('pagenation.prev.text')"
            :next-text="$t('pagenation.next.text')"
            :last-text="$t('pagenation.last.text')"
            ></b-pagination>
        </div>
    </b-card>
    </b-overlay>
    <b-alert
    :show="alertSettings.dismissCountDown"
    class="position-fixed fixed-top m-0 rounded-0 text-center"
    style="z-index: 2000;"
    :variant="alertSettings.alertVariant"
    dismissible
    @dismiss-count-down="countDownChanged"
    >
<!--        <div v-html="alertSettings.title"></div>-->
        <div v-html="alertSettings.alertMsg"></div>
    </b-alert>

    <b-modal id="issue-ticket-confirm-modal" 
    v-model="isIssueTicketConfirmModalVisible"
    centered hide-footer title="Issue ticket application">
        <!-- <div class="my-1">
            Total Amount: 
            <span class="text-danger">
                <b>
                    <span class="mr-1">
                        {{bookedPriceForPass.sellingCurrencyCode}}
                    </span>
                    {{(bookedPriceForPass.sellingTotalAmount + bookedPriceForP2P.sellingTotalAmount).toFixed(2)}}
                </b>
            </span>
        </div> -->
        <div class="my-2 d-flex">
            <div>
                Booking ID: 
                <span class="ml-1"><strong>#{{ checkOutBookingId }}</strong></span>
            </div>
            <div class="ml-4">
                Booking Amount: 
                <span class="ml-1 text-danger"><strong>{{ checkOutPrice.sellingCurrencyCode }}</strong></span>
                <span class="ml-1 text-danger"><strong>{{ checkOutPrice.sellingTotal }}</strong></span>
            </div>
        </div>
        <div class="my-4">
            <p>
                <input v-model="isIssueTicketAgreed" type="checkbox" class="mr-2">
                I agree to make payment and issue ticket by clicking "Issue Ticket" button
            </p>
        </div>
        <div class="d-flex justify-content-between">
            <b-button
            @click="hideIssueTicketModal" 
            variant="secondary"
            >
            Cancel
            </b-button>

            <b-button
            @click="checkOut" 
            variant="primary"
            :disabled="!isIssueTicketAgreed"
            >
            Issue Ticket
            </b-button>
        </div>
    </b-modal>
</Layout>
 </template>

 
 <style scoped>
 
 </style>