/**
 *  根据mutationTypes中常量定义的方法名来构造指定命名的函数，函数实现state中数据的状态转变
 * */
import * as types from "./mutationsTypes"
const mutations = {
    [types.UPDATE_AVAILRS](state, payload){
        console.log(payload)
        console.log(payload.availRS)
        if(payload.availRS!=""){
            state.availRS = payload.availRS;
            state.hotels=[];
            for(let i=0;i<payload.availRS.hotelStays.length;i++){
                let item = payload.availRS.hotelStays[i]
                let hotel={
                    hotelName:"",
                    propertyName:'',
                    Price:{
                        currencyCode:"",
                        maxPrice:"",
                        minPrice:"",
                        price:""
                    },
                    rating:"",
                    shortDescription:"",
                    ttiCode:"",
                    star:"",
                    imgUrl:"",
                    addressString:"",
                }
                hotel.hotelName = item.basicPropertyInfo.hotelName;
                hotel.Price.currencyCode = payload.availRS.roomStays.roomStayList[i].total.currencyCode;
                hotel.Price.price = payload.availRS.roomStays.roomStayList[i].total.amountIncludingMarkup;
                // if(item.basicPropertyInfo.awards!=null &&item.basicPropertyInfo.awards!=undefined){
                //     for(let k=0;k<item.basicPropertyInfo.awards.length;k++) {
                //         if(item.basicPropertyInfo.awards[k].provider=="TRIPADVISOR"){
                //             if(item.basicPropertyInfo.awards[k].rating==null){
                //                 hotel.rating =0
                //             }else{
                //                 hotel.rating = item.basicPropertyInfo.awards[k].rating
                //             }
                //         }
                //     }
                // }else{
                //     hotel.rating =0
                // }
                if(item.basicPropertyInfo.propertyNames!=null){
                    for(let j=0;j<item.basicPropertyInfo.propertyNames.length;j++){
                        if(item.basicPropertyInfo.propertyNames[j].languageCode=='zh_HK'){
                            hotel.propertyName = item.basicPropertyInfo.propertyNames[j].name
                        }
                    }
                }else{
                    hotel.propertyName =hotel.hotelName
                }
                hotel.shortDescription = item.basicPropertyInfo.shortDescriptions;
                hotel.rating = item.basicPropertyInfo.awards[0].rating;
                hotel.ttiCode = item.basicPropertyInfo.TTIcode;
                if(item.basicPropertyInfo.rating==null){
                    if(payload.availRS.roomStays.roomStayList[i].basicPropertyInfo.rating==null){
                        hotel.star = 0
                    }else{
                        hotel.star = payload.availRS.roomStays.roomStayList[i].basicPropertyInfo.rating
                    }
                }else{
                    hotel.star = item.basicPropertyInfo.rating
                }
                if(item.basicPropertyInfo.mainImageURL!=null){
                    hotel.imgUrl = item.basicPropertyInfo.mainImageURL;
                }else{
                    hotel.imgUrl = payload.availRS.roomStays.roomStayList[i].basicPropertyInfo.mainImageURL;
                }
                hotel.addressString = item.basicPropertyInfo.address.addressLine[0];
                state.hotels.push(hotel)
            }
        }else{
            state.availRS = payload.availRS;
            state.hotels=[];
        }


    },

    [types.UPDATE_HOTELDESCRIPTION](state, payload){
        console.log(payload)
        state.hotelDescription = {
            description: {
                name: "",
                location:{
                    cityName: "",
                    countryCode: "",
                    countryName: "",
                    address: "",
                    position: {
                        longtitude: "",
                        latitude: ""
                    }
                },
                photos: [],
                overView:""
            },
            amenities:[],
            amenity:{
                serviceItem:"",
                code:""
            },
            roomImgList:[]
        }
        //todo hotelList
        state.hotelDescription.description.name = payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].hotelName;
        //todo photoList
        let imgList=[];
        if(payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions!=null && payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions!=""){
            for(let i=0;i<payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions.multimediaDescriptionList[0].imageItems.length;i++){
                let img={
                    url:"",
                    roomTypeCode:""
                }
                let item = payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions.multimediaDescriptionList[0].imageItems[i]
                if(item.imageFormatList){
                    if(item.imageFormatList[0].dimensionCategory=="standard"){
                        img.url = item.url;
                        img.roomTypeCode = item.roomTypeCode;
                        imgList.push(img)
                    }
                }
            }
        }
        if(imgList.length>0){
            state.hotelDescription.description.photos = imgList
        }
        else{
            let img={
                url:"",
                roomTypeCode:""
            }
            if(payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions!=null && payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions!=""){
                img.url = payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions.multimediaDescriptionList[0].imageItems[0].url
            }
            state.hotelDescription.description.photos.push(img)
        }
        //todo location
        state.hotelDescription.description.location.position.longtitude = payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].hotelInfo.position.longitude
        state.hotelDescription.description.location.position.latitude = payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].hotelInfo.position.latitude

        //todo ammentiesOfHotel
        state.hotelDescription.amenities=[]
        state.hotelDescription.roomImgList=[]
        for(let i=0;i<payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].hotelInfo.serviceList.length;i++){
            state.hotelDescription.amenity={
                serviceItem:"",
                code:""
            }
            let item =payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].hotelInfo.serviceList[i];
            state.hotelDescription.amenity.serviceItem = item.codeDetail
            state.hotelDescription.amenity.code = item.code
            state.hotelDescription.amenities.push(state.hotelDescription.amenity)
        }
        let roomImgList = [];
        if(payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions!=null && payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions!=""){
            for(let i=0;i<payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions.multimediaDescriptionList[0].imageItems.length;i++){
                if(payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions.multimediaDescriptionList[0].imageItems[i].roomTypeCode!=null && payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions.multimediaDescriptionList[0].imageItems[i].imageFormatList[0].dimensionCategory=="standard"){
                    roomImgList.push(payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions.multimediaDescriptionList[0].imageItems[i])
                }
            }
        }
        var newArr = [];
        roomImgList.forEach((item) => {
            var check = newArr.every((b) => {
                return item.roomTypeCode !== b.roomTypeCode;
            })
            check ? newArr.push(item) : '';
        })
        state.hotelDescription.roomImgList = newArr
        // state.selectedHotel={
        //     ttiCode:'',
        //     rooms:[],
        //     room:{
        //         roomTypeCode:"",
        //         roomType:"",
        //         Price:{
        //             currencyCode:"",
        //             amountIncludingMarkup:"",
        //             amountBeforeTax:"",
        //             amountAfterTax:"",
        //             rating:""
        //         },
        //         maxOccupancy:"",
        //         minOccupancy:"",
        //         meals:"",
        //         freeCancelTime:"",
        //         fitWords:"",
        //         star:0,
        //         alternate:null,
        //         quantity:0,
        //         datePrice:[]
        //     },
        //     description: {
        //         name: "",
        //         location:{
        //             cityName: "",
        //             countryCode: "",
        //             countryName: "",
        //             address: "",
        //             position: {
        //                 longtitude: "",
        //                 latitude: ""
        //             }
        //         },
        //         photos: [],
        //         overView:""
        //     },
        //     amenities:[],
        //     amenity:{
        //         serviceItem:"",
        //         code:""
        //     },
        //     rating:0,
        //     roomImgList:[],
        //     star:0,
        //     mainImgUrl:'',
        //     lowestPrice:0
        // },
        // //todo hotelList
        // state.selectedHotel.description.name = payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].hotelName;
        // //todo photoList
        // let imgList=[];
        // if(payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions!=null && payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions!=""){
        //     for(let i=0;i<payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions.multimediaDescriptionList[0].imageItems.length;i++){
        //         let img={
        //             url:"",
        //             roomTypeCode:""
        //         }
        //         let item = payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions.multimediaDescriptionList[0].imageItems[i]
        //         if(item.imageFormatList){
        //             if(item.imageFormatList[0].dimensionCategory=="standard"){
        //                 img.url = item.url;
        //                 img.roomTypeCode = item.roomTypeCode;
        //                 imgList.push(img)
        //             }
        //         }
        //     }
        // }
        // if(imgList.length>0){
        //     state.selectedHotel.description.photos = imgList
        // }
        // else{
        //     let img={
        //         url:"",
        //         roomTypeCode:""
        //     }
        //     if(payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions!=null && payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions!=""){
        //         img.url = payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions.multimediaDescriptionList[0].imageItems[0].url
        //     }
        //     state.selectedHotel.description.photos.push(img)
        // }
        // state.selectedHotel.ttiCode = payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].tticode
        // //todo location
        // state.selectedHotel.description.location.position.longtitude = payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].hotelInfo.position.longitude
        // state.selectedHotel.description.location.position.latitude = payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].hotelInfo.position.latitude
        //
        // //todo ammentiesOfHotel
        // state.selectedHotel.amenities=[]
        // for(let i=0;i<payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].hotelInfo.serviceList.length;i++){
        //     state.selectedHotel.amenity={
        //         serviceItem:"",
        //         code:""
        //     }
        //     let item =payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].hotelInfo.serviceList[i];
        //     state.selectedHotel.amenity.serviceItem = item.codeDetail
        //     state.selectedHotel.amenity.code = item.code
        //     state.selectedHotel.amenities.push(state.selectedHotel.amenity)
        // }
        // let roomImgList = [];
        // if(payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions!=null && payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions!=""){
        //     for(let i=0;i<payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions.multimediaDescriptionList[0].imageItems.length;i++){
        //         if(payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions.multimediaDescriptionList[0].imageItems[i].roomTypeCode!=null && payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions.multimediaDescriptionList[0].imageItems[i].imageFormatList[0].dimensionCategory=="standard"){
        //             roomImgList.push(payload.hotelDescriptiveContents.hotelDescriptiveContentList[0].multimediaDescriptions.multimediaDescriptionList[0].imageItems[i])
        //         }
        //     }
        // }
        // // console.log(roomImgList);
        //
        // var newArr = [];
        // roomImgList.forEach((item) => {
        //     var check = newArr.every((b) => {
        //         return item.roomTypeCode !== b.roomTypeCode;
        //     })
        //     check ? newArr.push(item) : '';
        // })
        // state.selectedHotel.roomImgList = newArr
    },

    [types.UPDATE_AVAILROOMRS](state, payload){
        //todo Description(overView)
        state.selectedHotel={
            rooms:[],
            room:{
                roomType:"",
                roomTypeCode:"",
                Price:{
                    currencyCode:"",
                    amountIncludingMarkup:"",
                    amountBeforeTax:"",
                    amountAfterTax:"",
                },
                maxOccupancy:"",
                minOccupancy:"",
                meals:"",
                freeCancelTime:"",
                referenceId:"",
                hotelCodeContext:"",
                fitWords:"",
                star:0,
                alternate:null,
                quantity:0,
                datePrice:[]
            },
            description: {
                name: "",
                location:{
                    cityName: "",
                    countryCode: "",
                    countryName: "",
                    address: "",
                    position: {
                        longtitude: "",
                        latitude: ""
                    }
                },
                photos: [],
                overView:""
            },
            amenities:[],
            amenity:{
                serviceItem:"",
                code:""
            },
            rating:0,
            roomImgList:[],
            star:0,
            mainImgUrl:'',
            lowestPrice:0
        }
        state.selectedHotel.description.overView = payload.hotelStays[0].basicPropertyInfo.shortDescriptions
        state.selectedHotel.ttiCode = payload.hotelStays[0].basicPropertyInfo.TTIcode
        console.log(payload);
        if(payload.services!='' && payload.services!=null){
            sessionStorage.setItem('hotelServices',JSON.stringify(payload.services))
        }
        state.selectedHotel.rooms=[];
        let lowestPrice = 0
        for(let i=0;i<payload.roomStays.roomStayList.length;i++){
            state.selectedHotel.room ={
                roomType:"",
                roomTypeCode:"",
                Price:{
                    currencyCode:"",
                    amountIncludingMarkup:"",
                    amountBeforeTax:"",
                    amountAfterTax:"",
                },
                maxOccupancy:"",
                minOccupancy:"",
                meals:"",
                propertyMeals:'',
                freeCancelTime:"",
                propertyFreeCancelTime:'',
                referenceId:"",
                hotelCodeContext:"",
                fitWords:"",
                propertyFitWords:'',
                star:0,
                alternate:null,
                quantity:0,
                datePrice:[],
                roomPolicyList:[],
                adultAgeRange:{
                    min:0,
                    max:0
                },
                childAgeRange:{
                    min:0,
                    max:0
                }
            };

            let datePriceInMap = []
            let item = payload.roomStays.roomStayList[i];
            for(let j=0;j<item.roomRates.roomRates[0].rates.rateList.length;j++){
                datePriceInMap[item.roomRates.roomRates[0].rates.rateList[j].effectiveDate] = item.roomRates.roomRates[0].rates.rateList[j].total.amountIncludingMarkup
            }
            let datePriceArray = []
            for(var date in datePriceInMap){
                let datePriceObject = {
                    price:'',
                    date:''
                };
                datePriceObject.price = datePriceInMap[date]
                datePriceObject.date = date
                datePriceArray.push(datePriceObject)
            }
            if(lowestPrice> item.total.amountIncludingMarkup || lowestPrice==0){
                lowestPrice =  item.total.amountIncludingMarkup
            }
            state.selectedHotel.lowestPrice = lowestPrice
            state.selectedHotel.room.datePrice = datePriceArray
            state.selectedHotel.room.roomType = item.roomTypes[0].roomType;
            state.selectedHotel.mainImgUrl = payload.hotelStays[0].basicPropertyInfo.mainImageURL
            state.selectedHotel.room.roomTypeCode = item.roomTypes[0].roomTypeCode;
            state.selectedHotel.room.Price.currencyCode = item.total.currencyCode;
            state.selectedHotel.room.Price.amountIncludingMarkup = item.total.amountIncludingMarkup;
            state.selectedHotel.room.Price.amountBeforeTax = item.total.amountBeforeTax;
            state.selectedHotel.room.Price.amountAfterTax = item.total.amountAfterTax;
            state.selectedHotel.room.referenceId = item.reference.id;
            state.selectedHotel.room.hotelCodeContext = item.basicPropertyInfo.hotelCodeContext;
            state.selectedHotel.room.maxOccupancy = item.roomTypes[0].occupancies[0].maxOccupancy;
            state.selectedHotel.room.minOccupancy = item.roomTypes[0].occupancies[0].minOccupancy;
            state.selectedHotel.room.amenities ="Nothing";
            state.selectedHotel.room.roomTypeCode = item.roomTypes[0].roomTypeCode;
            state.selectedHotel.room.quantity = item.roomTypes[0].quantity
            state.selectedHotel.room.bookingChannelCodes = item.ratePlans[0].bookingChannelCodes==null? '':item.ratePlans[0].bookingChannelCodes;
            state.selectedHotel.room.roomPolicyList.push(item.ratePlans[0].additionalDetails)
            state.selectedHotel.room.ratePlanCode=item.ratePlans[0].ratePlanCode;
            if (item.roomRates.roomRates[0].total.pricingDetails.listItems!=null && item.roomRates.roomRates[0].total.pricingDetails.listItems.length>0){
                state.selectedHotel.room.priceRule=item.roomRates.roomRates[0].total.pricingDetails.listItems[0].stringValue;
            }else {
                state.selectedHotel.room.priceRule="Not applied";
            }

            if(item.roomRates.roomRates[0].rates.rateList[0].ageQualifyingCode){
                if(item.roomRates.roomRates[0].rates.rateList[0].ageQualifyingCode=="10"){
                    state.selectedHotel.room.adultAgeRange.min = item.roomRates.roomRates[0].rates.rateList[0].minAge
                    state.selectedHotel.room.adultAgeRange.max = item.roomRates.roomRates[0].rates.rateList[0].maxAge
                    state.selectedHotel.room.childAgeRange.min = 0
                    state.selectedHotel.room.childAgeRange.max = item.roomRates.roomRates[0].rates.rateList[0].minAge
                }else{
                    state.selectedHotel.room.childAgeRange.min = item.roomRates.roomRates[0].rates.rateList[0].minAge
                    state.selectedHotel.room.childAgeRange.max = item.roomRates.roomRates[0].rates.rateList[0].maxAge
                    state.selectedHotel.room.adultAgeRange.min = item.roomRates.roomRates[0].rates.rateList[0].maxAge
                    state.selectedHotel.room.adultAgeRange.max = 100
                }
            }
            if(item.roomTypes[0].alternate==true){
                state.selectedHotel.room.alternate = item.roomTypes[0].alternate;
            }
            else{
                state.selectedHotel.room.alternate = item.roomTypes[0].alternate;
            }
            //todo userrating
            state.selectedHotel.star = payload.hotelStays[0].basicPropertyInfo.rating;
            state.selectedHotel.rating = payload.hotelStays[0].basicPropertyInfo.awards[0].rating;
            let fitwordMin = ""
            if(state.selectedHotel.room.minOccupancy>1){
                fitwordMin = "1 room fit ocupancy "+state.selectedHotel.room.minOccupancy+" to "
                // +state.selectedHotel.room.maxOccupancy+"adults"
            }else{
                fitwordMin = "1 room fit ocupancy "+state.selectedHotel.room.minOccupancy+" to "
            }
            let fitwordMax = ""
            if(state.selectedHotel.room.maxOccupancy>1){
                fitwordMax = state.selectedHotel.room.maxOccupancy
            }else{
                fitwordMax = state.selectedHotel.room.maxOccupancy
            }

            state.selectedHotel.room.fitWords = fitwordMin+fitwordMax;
            // state.selectedHotel.room.fitWords = state.selectedHotel.room.minOccupancy+'-'+state.selectedHotel.room.maxOccupancy;


            let propertyFitwordMin = ""
            if(state.selectedHotel.room.minOccupancy>1){
                propertyFitwordMin = "一間房間適合"+state.selectedHotel.room.minOccupancy+"個成人到"
                // +state.selectedHotel.room.maxOccupancy+"adults"
            }else{
                propertyFitwordMin = "一間房間適合"+state.selectedHotel.room.minOccupancy+"個成人到"
            }
            let propertyFitwordMax = ""
            if(state.selectedHotel.room.maxOccupancy>1){
                propertyFitwordMax = state.selectedHotel.room.maxOccupancy+"個成人"
            }else{
                propertyFitwordMax = state.selectedHotel.room.maxOccupancy+"個成人"
            }

            state.selectedHotel.room.propertyFitWords = propertyFitwordMin+propertyFitwordMax;

            if (item.ratePlans[0].mealsIncluded.breakfast && item.ratePlans[0].mealsIncluded.Lunch && item.ratePlans[0].mealsIncluded.Dinner){
            state.selectedHotel.room.meals = 'Breakfast, Lunch, Dinner';
            state.selectedHotel.room.propertyMeals = '早餐，午餐，晚餐';
        }//早午晚
            else if (item.ratePlans[0].mealsIncluded.breakfast && item.ratePlans[0].mealsIncluded.Lunch && !item.ratePlans[0].mealsIncluded.Dinner){
                state.selectedHotel.room.meals = 'Breakfast, Lunch';
                state.selectedHotel.room.propertyMeals = '早餐，午餐';
            }//早午
            else if(item.ratePlans[0].mealsIncluded.breakfast && !item.ratePlans[0].mealsIncluded.Lunch && !item.ratePlans[0].mealsIncluded.Dinner){
                state.selectedHotel.room.meals = 'Breakfast';
                state.selectedHotel.room.propertyMeals = '早餐';
            }//早
            else if(item.ratePlans[0].mealsIncluded.breakfast && !item.ratePlans[0].mealsIncluded.Lunch && item.ratePlans[0].mealsIncluded.Dinner){
                state.selectedHotel.room.meals = 'Breakfast and Dinner';
                state.selectedHotel.room.propertyMeals = '早餐,晚餐';
            }//早晚
            else if(!item.ratePlans[0].mealsIncluded.breakfast && item.ratePlans[0].mealsIncluded.Lunch && item.ratePlans[0].mealsIncluded.Dinner){
                state.selectedHotel.room.meals = 'Lunch and Dinner';
                state.selectedHotel.room.propertyMeals = '午餐,晚餐';
            }//午晚
            else if(!item.ratePlans[0].mealsIncluded.breakfast && !item.ratePlans[0].mealsIncluded.Lunch && item.ratePlans[0].mealsIncluded.Dinner){
                state.selectedHotel.room.meals = 'Dinner';
                state.selectedHotel.room.propertyMeals = '晚餐';
            }//晚
            else if(!item.ratePlans[0].mealsIncluded.breakfast && item.ratePlans[0].mealsIncluded.Lunch && !item.ratePlans[0].mealsIncluded.Dinner){
                state.selectedHotel.room.meals = 'Lunch';
                state.selectedHotel.room.propertyMeals = '午餐';
            }//午
            else {
                state.selectedHotel.room.meals = 'None';
                state.selectedHotel.room.propertyMeals = '無';
            }//无
            if(item.cancelPenalties!=null){
                if(item.cancelPenalties.cancelPenalties[0].deadline!=null){
                    state.selectedHotel.room.freeCancelTime = item.cancelPenalties.cancelPenalties[0].deadline;
                    // let time = new Date(item.cancelPenalties.cancelPenalties[0].deadline)
                    // var YY = time.getFullYear() + '-';
                    // var MM = (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-';
                    // var DD = (time.getDate() < 10 ? '0' + (time.getDate()) : time.getDate());
                    // state.selectedHotel.room.freeCancelTime =YY + MM + DD +""
                }else{
                    state.selectedHotel.room.freeCancelTime = "Non-Cancelable";
                    state.selectedHotel.room.propertyFreeCancelTime = "不可取消";

                }
            }else{
                state.selectedHotel.room.freeCancelTime = "Non-Cancelable";
                state.selectedHotel.room.propertyFreeCancelTime = "不可取消";
            }
            state.selectedHotel.rooms.push(state.selectedHotel.room)
        }
        console.log(state.selectedHotel)
    },

    [types.UPDATE_CONFIRMRS](state, payload){
        console.log(payload)
        console.log(payload.confirmaRS);
        state.confirmRS = payload.confirmaRS
        state.passengerConfirmation=[];
        let passengers = [];
        for (let i = 0; i < payload.confirmaRS.hotelReservationList[0].resGuestList.length; i++) {
            passengers.push(payload.confirmaRS.hotelReservationList[0].resGuestList[i]);
        }
        let roomsArr = [];
        for(let i=0;i<payload.confirmaRS.hotelReservationList[0].roomStayList.length;i++){
            //forloop房间
            let roomStay = payload.confirmaRS.hotelReservationList[0].roomStayList[i];
            let room = {
                guests: [],
                adultGuests:[],
                childGuests:[]
            };
            for (let j = 0; j < roomStay.guestCounts.guestCountList.length; j++) {
                let guestCount = roomStay.guestCounts.guestCountList[j];
                let guest = {
                    firstName: '',
                    lastName: '',
                    age: 0,
                    birthDate: '',
                    emailAddress: '',
                    phoneNumber: '',
                    nameTitle: '',
                    ageQualifyingCode: ''
                };
                for (let k = 0; k < passengers.length; k++) {
                    let passenger = passengers[k];
                    if (guestCount.resGuestRPH == passenger.resGuestRPH) {
                        guest.lastName = passenger.surname;
                        guest.firstName = passenger.givenName;
                        guest.age = passenger.age;
                        guest.nameTitle = passenger.nameTitle;
                        guest.phoneNumber = passenger.phoneNumber;
                        guest.emailAddress = passenger.emailAddress;
                        guest.birthDate = passenger.birthDate;
                        guest.ageQualifyingCode = passenger.ageQualifyingCode;
                    }
                }
                room.guests.push(guest);
                if(guest.ageQualifyingCode=="10"){room.adultGuests.push(guest)}
                else{room.childGuests.push(guest)}
            }
            roomsArr.push(room);
        }
        console.log(roomsArr)
        state.passengerConfirmation = roomsArr;
        state.hotelRoomConfirmation.hotelName = payload.confirmaRS.hotelReservationList[0].roomStayList[0].basicPropertyInfo.hotelName;
        state.hotelRoomConfirmation.roomType = payload.confirmaRS.hotelReservationList[0].roomStayList[0].roomTypes[0].roomType;
        state.hotelRoomConfirmation.checkin = payload.confirmaRS.hotelReservationList[0].resGlobalInfo.timeSpan.start;
        state.hotelRoomConfirmation.checkout = payload.confirmaRS.hotelReservationList[0].resGlobalInfo.timeSpan.end;
        state.hotelRoomConfirmation.currencyCode = payload.confirmaRS.hotelReservationList[0].resGlobalInfo.total.currencyCode;
        state.hotelRoomConfirmation.totalPrice = payload.confirmaRS.hotelReservationList[0].resGlobalInfo.total.amountIncludingMarkup/payload.confirmaRS.hotelReservationList[0].roomStayList.length;
        state.hotelRoomConfirmation.roomPrice =payload.confirmaRS.hotelReservationList[0].resGlobalInfo.total.amountIncludingMarkup;
        state.hotelRoomConfirmation.roomTypeCode =payload.confirmaRS.hotelReservationList[0].roomStayList[0].roomTypes[0].roomTypeCode;
        state.hotelRoomConfirmation.referenceId =payload.confirmaRS.hotelReservationList[0].roomStayList[0].reference.id;
        state.hotelRoomConfirmation.ttiCode =payload.confirmaRS.hotelReservationList[0].roomStayList[0].basicPropertyInfo.TTIcode;
        state.hotelRoomConfirmation.hotelCodeContext =payload.confirmaRS.hotelReservationList[0].roomStayList[0].basicPropertyInfo.hotelCodeContext;
    },


    [types.UPDATE_PREBOOKRS](state, payload){
        console.log(payload)
        console.log(payload.preBookRS);
        state.preBookRS = payload.preBookRS
        sessionStorage.setItem('hotelPreBooking',JSON.stringify(payload.preBookRS))
    },

    [types.UPDATE_B2BBOOKRS](state, payload){
        console.log(payload)
        console.log(payload.B2BBookRS);
        state.B2BBookRS = payload.B2BBookRS;
        sessionStorage.setItem('hotelB2BBooking',JSON.stringify(payload.B2BBookRS));
    },

    getDateToDAte(date) {
        var now = new Date(date);
        var YY = now.getFullYear() + '-';
        var MM = (now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1) + '-';
        var DD = (now.getDate() < 10 ? '0' + (now.getDate()) : now.getDate());
        return YY + MM + DD +"";
    },

}

export default mutations;