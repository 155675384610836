/**
 * 从api获取数据的动作
 *
 */
import * as mutationName from "./mutationTypes";
import RailAPI from "@/api/RailAPI";
import RailUtils from "../../../utils/RailUtils";

const actions = {
  updateSearchCriteria({ commit }, payload) {
    if (payload.searchInputValues) {
      commit(mutationName.UPDATE_SEARCH_CRITERIA, {
        isRoundTrip: payload.isRoundTrip,
        searchInputValues: payload.searchInputValues
      });
    } else {
      commit(mutationName.UPDATE_SEARCH_CRITERIA, {
        passCriteria: payload.passCriteria
      });
    }
  },

  //搜索车票
  searchTrainTickets({ commit }, payload) {
    let searchRQ = RailUtils.getSearchRQ(
      payload.searchInputValues,
      payload.isRoundTrip
    );

    RailAPI.searchTrainTickets(searchRQ)
      .then(res => {
        let trainTicketsRS = {};
        if (res.data.errors) {
          trainTicketsRS.success = false;
          let title = "Sorry!";
          let errorMsg = "";
          try {
            let errorString = res.data.errors.errors[0].stringValue;
            errorMsg = errorString;
            // title = JSON.parse(
            //   errorString.replace("\\", "").substring(errorString.indexOf("{"))
            // ).label;
            // errorMsg = JSON.parse(
            //   errorString.replace("\\", "").substring(errorString.indexOf("{"))
            // ).details;
          } catch {
            errorMsg = "Supplier response errors.";
          }
          trainTicketsRS.errorTitle = title;
          trainTicketsRS.errorMsg = errorMsg;
          commit(mutationName.UPDATE_TRAIN_TICKETS_RS, {
            trainTicketsRS: trainTicketsRS,
            searchInputValues: payload.searchInputValues
          });
        } else {
          trainTicketsRS = res.data;
          trainTicketsRS.success = true;

          commit(mutationName.UPDATE_TRAIN_TICKETS_RS, {
            trainTicketsRS: trainTicketsRS,
            searchInputValues: payload.searchInputValues
          });
        }
      })
      .catch(error => {
        let errorRS = {};
        let title = "Sorry!";
        let errorMsg = "";
        if (error.response) {
          try {
            errorMsg = error.response.data.errors.errors[0].stringValue;
          } catch {
            console.info(error.response);
            errorMsg = "Some reponse errors";
          }
        } else if (error.request) {
          console.info(error.request);
          errorMsg = "Some request errors";
        } else {
          console.info(error);
          errorMsg = "Some data errors";
        }
        errorRS.errorTitle = title;
        errorRS.errorMsg = errorMsg;
        errorRS.success = false;
        commit(mutationName.UPDATE_TRAIN_TICKETS_RS, {
          trainTicketsRS: errorRS,
          searchInputValues: payload.searchInputValues
        });
      });
  },

  updateTrainTickets({ commit }, payload) {
    //车票信息

    commit(mutationName.UPDATE_TRAIN_TICKETS, {
      inboundTrainTicketResults: payload.inboundTrainTicketResults,
      outboundTrainTicketResults: payload.outboundTrainTicketResults,
      isRoundTrip: payload.isRoundTrip,
      searchInputValues: payload.searchInputValues
    });
  },

  searchRailPasses({ commit }, payload) {
    let searchRQ = RailUtils.getPassSearchRQ(payload.passCriteria);

    RailAPI.searchRailPasses(searchRQ)
      .then(res => {
        let railPassesRS = {};
        if (res.data.errors) {
          railPassesRS.success = false;
          let title = "Sorry!";
          let errorMsg = "";
          try {
            let errorString = res.data.errors.errors[0].stringValue;
            title = JSON.parse(
              errorString.replace("\\", "").substring(errorString.indexOf("{"))
            ).label;
            errorMsg = JSON.parse(
              errorString.replace("\\", "").substring(errorString.indexOf("{"))
            ).details;
          } catch {
            errorMsg = "Supplier response errors.";
          }
          railPassesRS.errorTitle = title;
          railPassesRS.errorMsg = errorMsg;
        } else {
          railPassesRS = res.data;
          railPassesRS.success = true;
        }
        commit(mutationName.UPDATE_RAIL_PASSES_RS, {
          railPassesRS: railPassesRS,
          passCriteria: payload.passCriteria
        });
      })
      .catch(error => {
        let errorRS = {};
        let title = "Sorry!";
        let errorMsg = "";
        if (error.response) {
          try {
            errorMsg = error.response.data.errors.errors[0].stringValue;
          } catch {
            console.info(error.response);
            errorMsg = "Some reponse errors";
          }
        } else if (error.request) {
          console.info(error.request);
          errorMsg = "Some request errors";
        } else {
          console.info(error);
          errorMsg = "Some data errors";
        }
        errorRS.errorTitle = title;
        errorRS.errorMsg = errorMsg;
        errorRS.success = false;

        commit(mutationName.UPDATE_RAIL_PASSES_RS, {
          railPassesRS: errorRS,
          passCriteria: payload.passCriteria
        });
      });
  },

  updateRailPasses({ commit }, payload) {
    commit(mutationName.UPDATE_RAIL_PASSES, {
      selectedPass: payload.selectedPass,
      passCriteria: payload.passCriteria
    });
  },

  selectTicket({ commit }, payload) {
    if (payload.selectedOutboundTicket) {
      commit(mutationName.UPDATE_SELECTED_TICKET, {
        selectedOutboundTicket: payload.selectedOutboundTicket
      });
    }
    if (payload.selectedInboundTicket) {
      commit(mutationName.UPDATE_SELECTED_TICKET, {
        selectedInboundTicket: payload.selectedInboundTicket
      });
    }
  },

  selectPass({ commit }, payload) {
    commit(mutationName.UPDATE_SELECTED_PASS, {
      selectedPass: payload.selectedPass
    });
  },

  selectValidity({ commit }, payload) {
    commit(mutationName.UPDATE_SELECTED_VALIDITY, {
      selectedValidity: payload.selectedValidity
    });
  },
  updateValidity({ commit }, payload) {
    commit(mutationName.UPDATE_VALIDITY, {
      selectedValidity: payload.selectedValidity
    });
  },

  updateItemForCart({ commit }, payload) {
    commit(mutationName.UPDATE_CART, {
      bookFromCart: payload.bookFromCart
    });
  },

  updatebookFromCart({ commit }, payload) {
    commit(mutationName.UPDATE_BOOKE_FROM_CART, {
      bookFromCart: payload.bookFromCart
    });
  },

  //update ticket details
  updateTiketDetails({ commit }, payload) {
    if (payload.selectedOutboundTicket) {
      commit(mutationName.UPDATE_TICKET_TICKET, {
        selectedOutboundTicket: payload.selectedOutboundTicket,
        isRoundTrip: payload.isRoundTrip
      });
    }
    if (payload.selectedInboundTicket) {
      commit(mutationName.UPDATE_TICKET_TICKET, {
        selectedInboundTicket: payload.selectedInboundTicket,
        isRoundTrip: payload.isRoundTrip
      });
    }
  },
  //book initiate
  bookInitiate({ commit }, bookRQ) {
    RailAPI.bookTicket(bookRQ)
      .then(res => {
        let bookTicketRS = {};
        if (res.data.errors) {
          bookTicketRS.success = false;
          let title = "Sorry!";
          let errorMsg = "";
          try {
            let errorString = res.data.errors.errors[0].stringValue;
            title = JSON.parse(
              errorString.replace("\\", "").substring(errorString.indexOf("{"))
            ).label;
            errorMsg = JSON.parse(
              errorString.replace("\\", "").substring(errorString.indexOf("{"))
            ).details;
          } catch {
            errorMsg = "Supplier response errors.";
          }
          bookTicketRS.errorTitle = title;
          bookTicketRS.errorMsg = errorMsg;
        } else {
          bookTicketRS = res.data;
          bookTicketRS.success = true;
        }
        commit(mutationName.UPDATE_BOOK_TICKET_RS, {
          bookInitiateRS: bookTicketRS
        });
      })
      .catch(error => {
        let errorRS = {};
        let title = "Sorry!";
        let errorMsg = "";
        if (error.response) {
          try {
            errorMsg = error.response.data.errors.errors[0].stringValue;
          } catch {
            console.info(error.response);
            errorMsg = "Some reponse errors";
          }
        } else if (error.request) {
          console.info(error.request);
          errorMsg = "Some request errors";
        } else {
          console.info(error);
          errorMsg = "Some data errors";
        }
        errorRS.errorTitle = title;
        errorRS.errorMsg = errorMsg;
        errorRS.success = false;
        commit(mutationName.UPDATE_BOOK_TICKET_RS, {
          bookInitiateRS: errorRS
        });
      });
  },

  //book quote
  bookTicket({ commit }, bookRQ) {
    RailAPI.bookTicket(bookRQ)
      .then(res => {
        let bookTicketRS = {};
        if (res.data.errors) {
          bookTicketRS.success = false;
          let title = "Sorry!";
          let errorMsg = "";
          try {
            let errorString = res.data.errors.errors[0].stringValue;
            title = JSON.parse(
              errorString.replace("\\", "").substring(errorString.indexOf("{"))
            ).label;
            errorMsg = JSON.parse(
              errorString.replace("\\", "").substring(errorString.indexOf("{"))
            ).details;
          } catch {
            errorMsg = "Supplier response errors.";
          }
          bookTicketRS.errorTitle = title;
          bookTicketRS.errorMsg = errorMsg;
        } else {
          bookTicketRS = res.data;
          bookTicketRS.success = true;
        }
        commit(mutationName.UPDATE_BOOK_TICKET_RS, {
          bookTicketRS: bookTicketRS
        });
      })
      .catch(error => {
        let errorRS = {};
        let title = "Sorry!";
        let errorMsg = "";
        if (error.response) {
          try {
            errorMsg = error.response.data.errors.errors[0].stringValue;
          } catch {
            console.info(error.response);
            errorMsg = "Some reponse errors";
          }
        } else if (error.request) {
          console.info(error.request);
          errorMsg = "Some request errors";
        } else {
          console.info(error);
          errorMsg = "Some data errors";
        }
        errorRS.errorTitle = title;
        errorRS.errorMsg = errorMsg;
        errorRS.success = false;
        commit(mutationName.UPDATE_BOOK_TICKET_RS, {
          bookTicketRS: errorRS
        });
      });
  }
};

export default actions;
