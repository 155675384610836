const state = {

    searchInputValues: {},
    isRoundTrip: {},
    passCriteria: {},
    /**
     * Searched responses
     * Raw data
     */
    trainTicketsRS: {},
//    outboundTrainTicketsRS: {},
//    inboundTrainTicketsRS: {},
   railPassesRS: {},



   /**
    * Displayed results
    * generated from raw data
    * keep what we need in particular formats
    * remove unused parts 
    */
//    trainTicketResults: [],
   outboundTrainTicketResults: [],
   inboundTrainTicketResults: [],
   railPassResults: [],



   /**
    *  Ticket details
    */
    selectedOutboundTicket: {},
    selectedInboundTicket: {},
    selectedPass: {},
    selectedValidity: {},
    
    ticketDetails: {
        trains: [],
        travelers: [],
        priceList: [],
        conditions: {},
        currency: '',
        totalAmount: 0
    },
    bookFromCart:{},

    bookInitiateRS: {},
    bookTicketRS: {},
    ticketBooked: {}



}

export default state;