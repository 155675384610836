import DateUtils from "../DateUtils";

const getBookingHistoryRQ = function (userName, bookingId, offset, limit){
    let uniqueID = {};
    if (bookingId != undefined){
        uniqueID = {
            "type": "BOOKING_ID",
            "id": bookingId,
            "id_context": "ERP"
        }
    }else {
        uniqueID= {
            "type": "USERNAME",
            "id": userName,
            "id_context": "ERP"
        }
    }
    return {
        "uniqueID":uniqueID,
        "offset": offset,
        "limit": limit
    }
}

const getUserInfoRQ = function (username) {
    let readRQ = {
        "uniqueID": {
            "id": username,
            "id_context": "USERNAME"
        }
    };
    return readRQ
}


/**
 * 
 * @param {*} userName 
 * @param {*} bookingId 
 * @param {*} offset 
 * @param {*} limit 
 * @returns 
 */
const getRailReservationsRQ = function (userName, bookingId, offset, limit){
    let uniqueID = {};
    if (bookingId != null){
        uniqueID = {
            "type": "BOOKING_ID",
            "id": bookingId,
            "id_context": "ERP"
        }
    }else {
        uniqueID= {
            "type": "USERNAME",
            "id": userName,
            "id_context": "ERP"
        }
    }
    return {
        "uniqueID":uniqueID,
        "offset": offset,
        "limit": limit
    }
}

function getRefundQuoteRQ(bookingId){
    let refundQuoteRQ = {
        "uniqueIDs": [
            {
                "type": "BOOKING_ID",
                "id": bookingId,
                "id_context": "ERP"
            }
        ]
    }
    return refundQuoteRQ
}

/**
 * 将数据转化成前端页面可识别的格式，为列表生成数据模型
 * @param {*} bookingHistoryRS 
 */
function getRailReservations (bookingHistoryRS) {

    let railReservationResults = []; //最终返回前端的数组

    let historyElementNum = bookingHistoryRS.elements.length;
    let historyElements = bookingHistoryRS.elements;
    let railResrElements = [] //过滤只有rail的element的数组

    if(historyElementNum == 0) return []

    //#TODO filtered: 只保留elements里面仅含railReservations不含其他产品的条目
    for(let i=0; i < historyElementNum; i++){
        let elementItem = historyElements[i]
        if((elementItem.railReservations != undefined) && 
            (elementItem.railReservations.length > 0)) {
                railResrElements.push(elementItem)
            }
    }

    

    //以下代码是将过滤数据railResrElements转化成前端页面可识别的格式，前端页面UI有变化，只需要修改这之后的代码即可
    let railResrElementNum = railResrElements.length;
    for(let i=0; i < railResrElementNum; i++){
            let railResrElement = railResrElements[i];

            //为兼容旧数据，不得不加这个跳过旧数据的循环
            // if(!railResrElement.railReservations[0].itinerary.originAndDestinations) break
                

            let bookingItem = {
                uniqueIDs: [],
                railEuropeBookingId: "",
                bookingId: "",
                reference: "",
                supplierReference: "",
                projectName: "",
                bookingPrice: "",
                project: "",
                bookingDate: "",
                passengers: "",
                providerBookingStatus: "",
                status: {
                    statusbg: "",
                    statustype: ""
                },
                view:"",
                // booking/bookingair
            }

            railResrElement.railReservations.forEach(element => {
                bookingItem.uniqueIDs.push(element.uniqueID)   
            })
            

            //由于bookign_id包含在返回数据的globalReservations字段数组里，用下面for loop找到booking_id的值
            for(let j=0; j < railResrElement.railReservations[0].uniqueID.length; j++){
                if(railResrElement.railReservations[0].uniqueID[j].type == "BOOKING_ID"){
                    bookingItem.bookingId = railResrElement.railReservations[0].uniqueID[j].id
                }
                if(railResrElement.railReservations[0].uniqueID[j].type == "RAILEUROPE_BOOKING_ID"){
                    bookingItem.railEuropeBookingId = railResrElement.railReservations[0].uniqueID[j].id
                }
                if(railResrElement.railReservations[0].uniqueID[j].type == "REFERENCE"){
                    bookingItem.reference = railResrElement.railReservations[0].uniqueID[j].id
                }
                if(railResrElement.railReservations[0].uniqueID[j].type == "SUPPLIER_REFERENCE"){
                    bookingItem.supplierReference = railResrElement.railReservations[0].uniqueID[j].id
                }
                if(railResrElement.railReservations[0].uniqueID[j].type == "PROVIDER_BOOKING_STATUS"){
                    bookingItem.providerBookingStatus = railResrElement.railReservations[0].uniqueID[j].id
                }
                
            }

            if(railResrElement.railReservations.length > 0){
                if(railResrElement.railReservations[0].itinerary.originAndDestinations == undefined){
                    bookingItem.project = "Pass"
                }else{
                    bookingItem.project = "Ticket"
                }
                

                bookingItem.status.statustype = railResrElement.statusId
                if(railResrElement.statusId=="BOOKING_IN_PROCESS"){
                    bookingItem.status.statusbg = "info"
                }else if(railResrElement.resStatus=="BOOKING_CANCELLED"){
                    bookingItem.status.statusbg = "danger"
                }else if(railResrElement.statusId=="BOOKING_CONFIRMED"){
                    bookingItem.status.statusbg = "success"
                }

                if(bookingItem.project === 'Ticket'){
                    //from/to  出发车站/终点车站
                    let originStation = railResrElement.railReservations[0].itinerary.originAndDestinations[0].trainSegments[0].departureStation.details.stationName;
                    let destinationStation = railResrElement.railReservations[0].itinerary.originAndDestinations[0].trainSegments[0].arrivalStation.details.stationName;
                    bookingItem.projectName = originStation + ' / ' + destinationStation
                    
                    // smaple data: dateTimeStr = "2022-11-29T12:00:00"
                    // departureTime is the first journeySegment's departureDateTime
                    let dateTimeStr = railResrElement.railReservations[0].itinerary.originAndDestinations[0].trainSegments[0].departureDateTime
                    let dateTimeStr1 = railResrElement.railReservations[0].itinerary.originAndDestinations[0].trainSegments[0].arrivalDateTime
                    let departureDate = ''
                    let departureTime = ''
                    let arrivalDate = ''
                    let arrivalTime = ''
                    if (dateTimeStr != null && dateTimeStr != '') {
                        departureDate = dateTimeStr.slice(0, dateTimeStr.indexOf("T"))
                        departureTime = dateTimeStr.substr(dateTimeStr.indexOf("T")+1, 5)
                    }
                    if (dateTimeStr1 != null && dateTimeStr1 != '') {
                        arrivalDate = dateTimeStr1.slice(0, dateTimeStr1.indexOf("T"))
                        arrivalTime = dateTimeStr1.substr(dateTimeStr1.indexOf("T")+1, 5)
                    }
                    // 出发和到达是同一天， 显示格式为： 2022-11-29 / 09:30 to 12:00
                    if (departureDate == arrivalDate) 
                        bookingItem.bookingDate = departureDate + ' / ' + departureTime + ' to ' + arrivalTime
                    else bookingItem.bookingDate = departureDate + ' ' + departureTime + ' to ' + arrivalDate + ' ' + arrivalTime
                }

                bookingItem.priceDetail = {}
                
                let priceDetailTem = {}
                let totalTem = Number(0)
                let sellingTotalTem = Number(0)
                priceDetailTem.currencyCode = ""
                priceDetailTem.sellingCurrencyCode = ""
                //计算booking金额，各个item的金额相加
                railResrElement.railReservations.forEach(element => {
                    try{
                        let railChargesTem = element.itinerary.railCharges

                        totalTem = totalTem + railChargesTem.total.amountAfterTax
                        sellingTotalTem = sellingTotalTem + railChargesTem.total.sellingAmount
                        if(priceDetailTem.currencyCode == ""){
                            priceDetailTem.currencyCode = railChargesTem.total.currencyCode
                        }
                        if(priceDetailTem.sellingCurrencyCode == ""){
                            priceDetailTem.sellingCurrencyCode = railChargesTem.total.sellingCurrencyCode
                        }
                        
                        railChargesTem.total.taxes.taxes.forEach(item => {
                            if(item.type != "inclusive"){
                                totalTem = totalTem + item.amount
                                sellingTotalTem = sellingTotalTem + item.sellingAmount   
                            }
                        })
                    }catch{
                        console.info("Some data errors")
                    }
                   
                })
                priceDetailTem.total = totalTem
                priceDetailTem.sellingTotal = sellingTotalTem
                bookingItem.priceDetail = priceDetailTem

                // bookingItem.bookingPrice = currencyCode + ' ' + priceStr
                
                let passengersStr = ''
                let passengerInfosArr = railResrElement.railReservations[0].passengerInfos
                for(let i = 0; i < passengerInfosArr.length; i++) {
                    
                    passengersStr = passengerInfosArr[0].passengerDetails[0].identification.surname
                                    + '/' + passengerInfosArr[0].passengerDetails[0].identification.givenName
                                    + ' ' + passengerInfosArr[0].passengerDetails[0].identification.nameTitle
                }
                bookingItem.passengers = passengersStr //#TODO 不确定显示多少个人名，暂时实现只显示其中一个

                bookingItem.view = 'booking/rail/view'
                
                bookingItem.expiredDateTime = ''
                bookingItem.expireIn = ''
                // let expiredDateTimeTem = []
                railResrElement.railReservations.forEach(element => {
                    try{
                        let valueMapTem = element.tpaExtensions.valueMap
                        if(bookingItem.expiredDateTime == ''){
                            if(valueMapTem.PROVIDER_BOOKING_EXPIRED_DATETIME != null && valueMapTem.PROVIDER_BOOKING_EXPIRED_DATETIME !== undefined){
                                bookingItem.expiredDateTime = valueMapTem.PROVIDER_BOOKING_EXPIRED_DATETIME
                            }
                        }
                    }catch{
                        console.info("Some data errors")
                    }
                })

                if(bookingItem.expiredDateTime != ''){
                    let expiredDateTimeStr = bookingItem.expiredDateTime.slice(0, 19)
                    bookingItem.expiredDateTime = DateUtils.formatDate(expiredDateTimeStr, "YYYY-MM-DD HH:mm:ss")
                    bookingItem.expireIn = DateUtils.getDuration(expiredDateTimeStr, '', 'minute')
                }

                if(bookingItem.providerBookingStatus === "PREBOOKED"){
                    bookingItem.expired = false
                    if(bookingItem.expireIn <= 0){
                        bookingItem.expired = true   
                    }
                }
                
            }
        railReservationResults.push(bookingItem)
    }

    return railReservationResults
}

//为详情页生成数据模型
function getBookingDetailsResult(reservation){

    let railBookingDetails = {}
    
    let trains = [] //渲染数据模型
    let passengers = []//渲染数据模型 
    //原货币单位EUR和selling货币单位HKD
    let price = {}
    price.total = 0
    price.currencyCode = ""
    price.sellingTotal = 0
    price.sellingCurrencyCode = ""
    railBookingDetails.trains = trains
    railBookingDetails.passengers = passengers
    railBookingDetails.price = price

    railBookingDetails.railEuropeBookingId = ""
    railBookingDetails.railEuropeBookingItemId = ""
    railBookingDetails.pnr = "--"

    railBookingDetails.type = ""
    railBookingDetails.ticketOption = ""
    railBookingDetails.resStatusId = ""

    let uniqueIDArr = reservation.uniqueID
    for(let i=0; i<uniqueIDArr.length; i++){
        if(uniqueIDArr[i].type == "RAILEUROPE_BOOKING_ID"){
            railBookingDetails.railEuropeBookingId = uniqueIDArr[i].id
        }
        if(uniqueIDArr[i].type == "RAILEUROPE_BOOKING_ITEM_ID"){
            railBookingDetails.railEuropeBookingItemId = uniqueIDArr[i].id
        }
        if(uniqueIDArr[i].type == "SUPPLIER_REFERENCE"){
            railBookingDetails.pnr = uniqueIDArr[i].id
        }
        if(uniqueIDArr[i].type == "PROVIDER_BOOKING_ITEM_STATUS"){
            railBookingDetails.resStatusId = uniqueIDArr[i].id
        }
    }

    let tpaExtensions = reservation.tpaExtensions
    if(tpaExtensions != null && tpaExtensions != undefined){
        if(tpaExtensions.valueMap != null && tpaExtensions.valueMap !== undefined){
            if(tpaExtensions.valueMap.TICKETING_OPTION_SELECTED != null && tpaExtensions.valueMap.TICKETING_OPTION_SELECTED !== undefined){
                railBookingDetails.ticketOption = tpaExtensions.valueMap.TICKETING_OPTION_SELECTED
            }
            // if(tpaExtensions.valueMap.providerBookingStatus != null && tpaExtensions.valueMap.providerBookingStatus !== undefined){
            //     railBookingDetails.resStatusId = tpaExtensions.valueMap.providerBookingStatus
            // }
            if(tpaExtensions.valueMap.reservationType != null && tpaExtensions.valueMap.reservationType !== undefined){
                railBookingDetails.type = tpaExtensions.valueMap.reservationType
            }
            if(tpaExtensions.valueMap.ticketsCollectionReferences != null && tpaExtensions.valueMap.ticketsCollectionReferences !== undefined){
                railBookingDetails.pnr = tpaExtensions.valueMap.ticketsCollectionReferences
            }           
        }
    }

    let itinerary = reservation.itinerary
    let originAndDestinations = itinerary.originAndDestinations
    let vendorMessages = itinerary.vendorMessages
    let passengerInfos = reservation.passengerInfos

    if(railBookingDetails.type == "P2P"){

        railBookingDetails.isRoundTrip = false
        railBookingDetails.originLocation = ""
        railBookingDetails.destinationLocation = ""
        if(originAndDestinations.length > 1){
            railBookingDetails.isRoundTrip = true    
        }

        originAndDestinations.forEach(element => {//单程、双程
            let train = {}
            train.originLocation = ""
            train.destinationLocation = ""
            train.originLocation = element.originLocation.locationName
            train.destinationLocation =element.destinationLocation.locationName

            if(railBookingDetails.originLocation == ""){
                railBookingDetails.originLocation = element.originLocation.locationName
            }
            if(railBookingDetails.destinationLocation == ""){
                railBookingDetails.destinationLocation = element.destinationLocation.locationName
            }

            train.trainSegments = []
            let trainSegments = element.trainSegments
            trainSegments.forEach(item => {
                let trainSegment = {}

                trainSegment.departureDate = DateUtils.formatDate(item.departureDateTime, "YYYY-MM-DD")
                trainSegment.arrivalDate = DateUtils.formatDate(item.arrivalDateTime, "YYYY-MM-DD")
                trainSegment.departureTime = DateUtils.formatDate(item.departureDateTime, "HH:mm")
                trainSegment.arrivalTime = DateUtils.formatDate(item.arrivalDateTime, "HH:mm")

                trainSegment.departureStation = item.departureStation.details.stationName
                trainSegment.arrivalStation = item.arrivalStation.details.stationName

                if(item.trainInfo.train.networkCode.code != null) {
                    trainSegment.networkCode = item.trainInfo.train.networkCode.code
                }else{
                    trainSegment.networkCode = ""
                }

                if(item.trainInfo.train.trainNumber != null) {
                    trainSegment.trainNumber = item.trainInfo.train.trainNumber
                }else{
                    trainSegment.trainNumber = ""
                }

                trainSegment.duration = DateUtils.getTrainDuration(item.arrivalDateTime, item.departureDateTime)
                
                train.trainSegments.push(trainSegment)
            })
            trains.push(train)

        })

        railBookingDetails.trains = trains
    }else{
        railBookingDetails.label = ""
        railBookingDetails.firstTravelDate = ""
    }

    if(itinerary.railCharges != null && itinerary.railCharges !== undefined ){
        price.total = itinerary.railCharges.total.amountAfterTax.toFixed(2)
        price.currencyCode = itinerary.railCharges.total.currencyCode
        price.sellingTotal = itinerary.railCharges.total.sellingAmount.toFixed(2)
        price.sellingCurrencyCode = itinerary.railCharges.total.sellingCurrencyCode
        
        price.taxes = []

        let taxes = itinerary.railCharges.total.taxes

        if(taxes != null && taxes != undefined){
            if(taxes.taxes != null && taxes.taxes != undefined && taxes.taxes.length > 0){
                taxes.taxes.forEach(item => {
                    if(item.type != "inclusive"){
                        price.total = price.total + item.amount
                        price.sellingTotal = price.sellingTotal + item.sellingAmount   
                    }
                    item.name = item.taxDescriptions[0].name
                    price.taxes.push(item)
                })
            }
        }
    }
    

    if(passengerInfos != null && passengerInfos != undefined){
        passengerInfos.forEach(element => {
        
            let code = element.passengerQualifyingInfo[0].code
            element.passengerDetails.forEach(item => {
                let passenger = {}
                passenger.code = code
                passenger.birthDate = item.birthDate
                passenger.firstName = item.identification.givenName
                passenger.lastName = item.identification.surname
                passenger.nameTitle = item.identification.nameTitle
                passenger.passenger = item.identification.nameTitle + " " + item.identification.givenName + " " + item.identification.surname
                if(item.telephones && item.telephones.length > 0){
                    passenger.phoneNumber = item.telephones[0].phoneNumber
                }
                if(item.emails && item.emails.length > 0){
                    passenger.email = item.emails[0].stringValue
                }
                passengers.push(passenger)
            })
        })
    }

    railBookingDetails.label = ""
    railBookingDetails.firstTravelDate = ""
    railBookingDetails.cabinClass = []
    railBookingDetails.travelClass = ""
    railBookingDetails.p2pConditions = []
    railBookingDetails.passConditions = []
    railBookingDetails.ticketPdfUrl = ""
    railBookingDetails.tickets = []


    if(vendorMessages != null && vendorMessages != undefined){
        vendorMessages.forEach(vendorMessageItem => {
            vendorMessageItem.subSections.forEach(element => {
                if(element.subCode === "product"){
                    element.paragraphs[0].listItems[0].subSections.forEach(item => {
                        if(item.subCode === "label"){
                            railBookingDetails.label = item.paragraphs[0].text
                        }
                        if(item.subCode === "firstTravelDate"){
                            railBookingDetails.firstTravelDate = DateUtils.formatDate(item.paragraphs[0].text) 
                        }
                        if(item.subCode === "cabinClass"){
                            railBookingDetails.cabinClass.push(decodeCabinClass(item.paragraphs[0].text))
                        }
                        if(item.subCode === "travelClass"){
                            railBookingDetails.travelClass = decodeCabinClass(item.paragraphs[0].text) 
                        }
                        if(item.subCode === "conditions"){
                            railBookingDetails.p2pConditions.push(item.paragraphs[0].text)
                        }
                    }) 
                }

                if(element.subCode === "conditions"){
                    element.paragraphs.forEach(item => {
                        let con = {}
                        if(item.name == 'CARD_INTRODUCTION'){
                            con.title = 'Card Introduction'
                            con.stringValue = item.text
                            railBookingDetails.passConditions.push(con)
                        }
                        if(item.name == 'CARD_SALES'){
                            con.title = 'Card Sales'
                            con.stringValue = item.text
                            railBookingDetails.passConditions.push(con)
                        }
                        if(item.name == 'CARD_LABEL'){
                            con.title = 'Card Label'
                            con.stringValue = item.text
                            railBookingDetails.passConditions.push(con)
                        }

                        if(item.name == 'GLOBAL_INTRODUCTION_DETAILS'){
                            con.title = 'Global Introduction Details'
                            con.stringValue = item.text
                            railBookingDetails.passConditions.push(con)
                        }
                        if(item.name == 'GLOBAL_INTRODUCTION'){
                            con.title = 'Global Introduction'
                            con.stringValue = item.text
                            railBookingDetails.passConditions.push(con)
                        }
                        if(item.name == 'GLOBAL_DESCRIPTION'){
                            con.title = 'Global Description'
                            con.stringValue = item.text
                            railBookingDetails.passConditions.push(con)
                        }
                        if(item.name == 'INCLUDES'){
                            con.title = 'Includes'
                            con.stringValue = item.text
                            railBookingDetails.passConditions.push(con)
                        }
                        if(item.name == 'PASS_RANGE_SALES'){
                            con.title = 'Pass Range Sales'
                            con.stringValue = item.text
                            railBookingDetails.passConditions.push(con)
                        }
                        if(item.name == 'PASS_RANGE_AFTER_SALES'){
                            con.title = 'Pass After Sales'
                            con.stringValue = item.text
                            railBookingDetails.passConditions.push(con)
                        }  
                        if(item.name == 'TICKETING_METHOD'){
                            con.title = 'Ticketing Method'
                            con.stringValue = item.text
                            railBookingDetails.passConditions.push(con)
                        }
                        if(item.name == 'PAXTYPE_SALES'){
                            con.title = 'Paxtype Sales'
                            con.stringValue = item.text
                            railBookingDetails.passConditions.push(con)
                        }
                        
                    })   
                }

                if(element.subCode === "ticket"){
                    element.paragraphs[0].listItems[0].subSections.forEach(item => {
                        if(item.subCode === "url"){
                            railBookingDetails.tickets.push(item.paragraphs[0].text)
                        }
                    }) 
                }
            })
        })
    }
    
    railBookingDetails.passengers = passengers
    railBookingDetails.price = price

    return railBookingDetails
}

//为详情页的id、status、reference.生成数据模型
function getRailReservationDetails (bookingHistoryRS) {
    //获取到的虽然是跟获取列表一样的数组，因查询条件是明确的booking_id，所以只有一条element
    let railReservationDetails = {}
    let trains = [] //渲染数据模型
    let passengers = []//渲染数据模型 
    let price = {
        "total": 0,
        "currencyCode": ""
    }

    if(!bookingHistoryRS.success){
        railReservationDetails.trains = trains
        railReservationDetails.passengers = passengers
        railReservationDetails.price = price    

        return railReservationDetails
    }

    /**
     * 数据处理过程
     * start
     */
    let statusId = bookingHistoryRS.elements[0].statusId
    railReservationDetails.statusId = statusId

    railReservationDetails.supplierReference = ""
    railReservationDetails.reference = ""
    railReservationDetails.bookingId = ""
    let uniqueID = bookingHistoryRS.elements[0].railReservations[0].uniqueID
    if(uniqueID != null && uniqueID != undefined && uniqueID.length > 0){
        for(let i = 0; i < uniqueID.length; i++){
            if(uniqueID[i].type == "SUPPLIER_REFERENCE"){
                railReservationDetails.supplierReference =  uniqueID[i].id   
            }else if(uniqueID[i].type == "REFERENCE"){
                railReservationDetails.reference =  uniqueID[i].id
            }else if(uniqueID[i].type == "BOOKING_ID"){
                railReservationDetails.bookingId =  uniqueID[i].id
            }
        }
    }

    let railReservation = bookingHistoryRS.elements[0].railReservations[0]
    let originAndDestinations = railReservation.itinerary.originAndDestinations
    let passengerInfos = railReservation.passengerInfos
    if(originAndDestinations != undefined){
        originAndDestinations.forEach(element => {//单程、双程
            let train = {}
            train.trainSegments = []
            let trainSegments = element.trainSegments
            trainSegments.forEach(item => {
                let trainSegment = {}

                trainSegment.departureTime = DateUtils.formatDate(item.departureDateTime, "HH:mm")
                trainSegment.arrivalTime = DateUtils.formatDate(item.arrivalDateTime, "HH:mm")

                trainSegment.departureStation = item.departureStation.details.stationName
                trainSegment.arrivalStation = item.arrivalStation.details.stationName

                trainSegment.networkCode = item.trainInfo.train.networkCode.code
                trainSegment.trainNumber = item.trainInfo.train.trainNumber

                trainSegment.duration = DateUtils.getTrainDuration(item.arrivalDateTime, item.departureDateTime)
                
                train.trainSegments.push(trainSegment)
            })
            trains.push(train)

        })
    }

    price.total = railReservation.itinerary.railCharges.total.amountBeforeTax
    price.currencyCode = railReservation.itinerary.railCharges.total.currencyCode


    passengerInfos.forEach(element => {
        
        let code = element.passengerQualifyingInfo[0].code
        element.passengerDetails.forEach(item => {
            let passenger = {}
            passenger.code = code
            passenger.birthDate = item.birthDate
            passenger.firstName = item.identification.givenName
            passenger.lastName = item.identification.surname
            passenger.nameTitle = item.identification.nameTitle
            passenger.passenger = item.identification.surname + "/" + item.identification.givenName
                                    + item.identification.nameTitle
            passengers.push(passenger)
        })
    })

    railReservationDetails.trains = trains
    railReservationDetails.passengers = passengers
    railReservationDetails.price = price


    //end

    return railReservationDetails

}

function getRefundRQ(uniqueID, uniqueIDs) {
    let bookingId = ""
    let bookingItemId = ""
    let railEuropeBookingItemId = ""

    let uniqueIDTem = []

    console.log('refund')
    console.log(uniqueID)
    console.log(uniqueIDs)

    uniqueID.forEach(item => {
        if(item.type == "RAILEUROPE_BOOKING_ITEM_ID"){
            railEuropeBookingItemId = item.id
        }   
    })

    uniqueIDs.forEach(uniqueIDItem => {
        uniqueIDItem.forEach(item => {
            if(item.type == "RAILEUROPE_BOOKING_ITEM_ID"){
                if(item.id == railEuropeBookingItemId){
                    uniqueIDTem = uniqueIDItem
                }
            }   
        })   
    })

    uniqueIDTem.forEach(item => {
        if(item.type == "BOOKING_ITEM_ID"){
            bookingItemId = item.id
        }
        if(item.type == "BOOKING_ID"){
            bookingId = item.id
        }    
    })
    


    let refundRQ = 
    {
        "cancelType": "QUOTE",
        "uniqueIDs": [
            {
                "type": "BOOKING_ITEM_ID",
                "id": bookingItemId,
                "id_Context": "ERP"
            },
            {
                "type": "BOOKING_ID",
                "id": bookingId,
                "id_context": "ERP"
            }
        ]
    }

    return refundRQ
}


function decodeCabinClass(cabinClassCode){
    let cabinClassText = cabinClassCode
    if(cabinClassCode == "1"){
        cabinClassText = "1st Class"
    }

    if(cabinClassCode == "2"){
        cabinClassText = "2nd Class"
    }

    return cabinClassText
}

export default  {
    getBookingHistoryRQ,
    getUserInfoRQ,
    getRailReservationsRQ,
    getRailReservations,
    getRailReservationDetails,
    getBookingDetailsResult,
    getRefundQuoteRQ,
    getRefundRQ
}