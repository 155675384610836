import axios from "../utils/axiosUtils";

export default {
  forgetPassword(email) {
    let url =
      "/api/v1/b2b/user-manage/users/forget-password?verifiedEmail=" + email;
    return axios.get(url, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
  }
};
