<script>
export default {
  props: {
    selectedOutboundTicket: {},
    selectedInboundTicket: {},
    passengerTypeInfo: {}
  }
};
</script>

<template>
  <b-card>
    <div>
      <h5 class="font-size-14">
        <i class="mdi mdi-chevron-triple-right text-danger"></i>
        <span class="text-secondary">Outbound</span>
        <i class="mdi mdi-chevron-triple-right text-danger"></i>
      </h5>
      <h6
        class="p-2 text-primary"
        v-if="selectedOutboundTicket.seatClass !== undefined"
      >
        {{
          selectedOutboundTicket.seatClass != undefined
            ? selectedOutboundTicket.seatClass + ","
            : ""
        }}
        {{
          selectedOutboundTicket.flexibilityType != undefined
            ? selectedOutboundTicket.flexibilityType
            : ""
        }}
      </h6>
      <ul
        v-for="(item, index) in selectedOutboundTicket.trainSegments"
        :key="'outbound-train-' + index"
        class="list-inline v-align-middle"
      >
        <li class="list-inline-item mx-3">{{ item.departureDate }}</li>
        <li class="list-inline-item mx-3 v-align-middle">
          <span>{{ item.departureStation }}</span
          ><br />

          &nbsp;&nbsp;to &nbsp;<span>{{ item.arrivalStation }}</span>
        </li>
        <li class="list-inline-item mx-3">
          {{ item.networkCode }} {{ item.trainNumber }}
        </li>
        <li class="list-inline-item mx-3 v-align-middle">
          {{ item.departureTime }} to {{ item.arrivalTime }}
        </li>
        <li class="list-inline-item mx-3">{{ item.duration }}</li>
      </ul>
    </div>

    <div v-if="selectedInboundTicket">
      <h5 class="font-size-14">
        <i class="mdi mdi-chevron-triple-left text-danger"></i>
        <span class="text-secondary">Inbound</span>
        <i class="mdi mdi-chevron-triple-left text-danger"></i>
      </h5>
      <h6
        class="p-2 text-primary"
        v-if="selectedInboundTicket.seatClass !== undefined"
      >
        {{
          selectedInboundTicket.seatClass != undefined
            ? selectedInboundTicket.seatClass + ","
            : ""
        }}
        {{
          selectedInboundTicket.flexibilityType != undefined
            ? selectedInboundTicket.flexibilityType
            : ""
        }}
      </h6>
      <ul
        v-for="(item, index) in selectedInboundTicket.trainSegments"
        :key="'outbound-train-' + index"
        class="list-inline v-align-middle"
      >
        <li class="list-inline-item mx-3">{{ item.departureDate }}</li>
        <li class="list-inline-item mx-3 v-align-middle">
          <span>{{ item.departureStation }}</span
          ><br />

          &nbsp;&nbsp; to &nbsp;<span>{{ item.arrivalStation }}</span>
        </li>
        <li class="list-inline-item mx-3">
          {{ item.networkCode }} {{ item.trainNumber }}
        </li>
        <li class="list-inline-item mx-3 v-align-middle">
          {{ item.departureTime }} to {{ item.arrivalTime }}
        </li>
        <li class="list-inline-item mx-3">{{ item.duration }}</li>
      </ul>
    </div>

    <div v-if="passengerTypeInfo">
      <h5 class="font-size-14">
        <span>Passenger Types ({{ passengerTypeInfo.company }})</span>
      </h5>
      <div
        v-for="(item, index) in passengerTypeInfo.passengerTypes"
        :key="index"
        class="row"
      >
        <div class="col-1 ">{{ item.type }}:</div>
        <div class="col-10 " v-if="item.toAge == 999">
          {{ item.fromAge }} years old and over
        </div>
        <div class="col-10 " v-else>
          {{ item.fromAge }} to {{ item.toAge }} years old
        </div>
      </div>
    </div>
  </b-card>
</template>
