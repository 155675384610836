const state = {
    activitySearchRS:{},
    activityDetail:{},
    necessaryInfoDetailList:[
        {
            tourName:'',
            tourId:'',
            groupName:'',
            groupRph:'',
            groupPrice:{
                currencyCode:'',
                amount:''
            },
            pricePolicy:[],
            location:{
                start:{
                    countryName:'',
                    cityName:'',
                    locationName:''
                },
                end:{
                    countryName:'',
                    cityName:'',
                    locationName:''
                }

            },
            cancelPolicy:'',
            fareList:[]
        }
    ]
}

export default state;

