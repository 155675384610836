<script>
import Layout from '../layouts/main.vue';
import PageHeader from '../../components/page-header.vue';
import AccountApi from '../../api/accountApi'
import AccountUtils from '../../utils/account/accountUtils'
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from "vuelidate";
import { maxLength, minLength, required, email, sameAs } from "vuelidate/lib/validators";

    export default {
        name: '',
        components: {
            Layout,
            PageHeader
        },
        mixins: [validationMixin],
        data() {
            return {
                title: "Accounts",
                items: [
                    {
                        text: "Accounts",
                        active: true,
                    }
                ], 
                isTableloading: true,
                saveBtnDisabled: false,
                isAddAccountModalVisible: false,
                alertSettings: {
                    dismissCountDown: 0,
                    countDownSecs: 4,
                    title: '',
                    alertMsg: '',
                    alertVariant: 'warning'
                },
                filter: null,
                searchCriteria: {
                    name: '',
                    rSelected: null,
                    // tSelected: null,
                },
                roleOptions: [],
                teamOptions: [
                    // {value: null, text: 'Select Team'},
                    // {value: 1, text: 'team 1'},
                    // {value: 2, text: 'team 2'},
                    // {value: 3, text: 'team 3'}
                ],
                //account list data
                accountFields: [
                    'actions',
                    {key: 'name', label: 'Name (Job Title)', sortable: true},
                    // {key: 'team', label: 'Team', sortable: true},
                    {key: 'username', label: 'Login Account', sortable: true},
                    {key: 'role', label: 'Login Id', sortable: true},
                    'active'
                ],
                accountItems: [
                ],
                newAccount: {
                    userLoginId: '',
                    username: '',
                    firstName: '',
                    lastName: '',
                    groupIds: [],
                    active: true,
                    password: '',
                    confirmPassword: '',
                },
                groupIdss: [],
                errorMsgs: {
                    required: {
                        text: 'This Field is required'
                    },
                    username: {
                        text: 'Required as an email address between 5 and 50 characters'
                    },
                    role: {
                        text: 'Please at least assign a role'
                    },
                    password: {
                        text: 'Required between 8 and 20 characters'
                    },
                    notSameAs: {
                        text: 'Please confirm your password'
                    }
                },
				accountInfo: {},
				isAlterPassword: false,
				alteredUsername: '',
				newPassword: '',
				alterPasswordBtnDisabled: false,
            }
        },
        validations() {
            return {
                newAccount: {
                    username: {
                        required,
                        email,
                        minLength: minLength(5),
                        maxLength: maxLength(50),
                    },
                    firstName: {
                        required,
                        minLength: minLength(3),
                        maxLength: maxLength(20),
                    },
                    lastName: {
                        required,
                        minLength: minLength(3),
                        maxLength: maxLength(20),
                    },
                    groupIds: {
                        required,
                    },
                    active: {

                    },
                    password: {
                        required,
                        minLength: minLength(8),
                        maxLength: maxLength(20),
                    },
                    confirmPassword: {
                        required,
                        sameAs: sameAs('password')
                    }
                },
				newPassword: {
					required,
					minLength: minLength(8),
					maxLength: maxLength(20),
				}
			}
        },
        computed: {
            ...mapGetters('account', [
                'accountsRS',
                'allRoles'
            ]),
        },
        watch: {
            accountsRS: function() {
                this.isTableloading = false
                if(this.accountsRS.success) {
                    this.accountItems = this.accountsRS.elements
                }else{
                    this.setAlertMessage('danger', this.accountsRS.errorTitle, this.accountsRS.errorMsg, 15)
                }
            },
            allRoles: function() {
				let allRolesStr = JSON.stringify(this.allRoles)
                this.roleOptions = JSON.parse(allRolesStr)
                this.roleOptions.sort((a, b) => {
                    return a.name - b.name    
                })
            }
        },
        created() {
			this.toGetLoginInfo()
            this.toGetAccounts()
            this.toGetAllRoles()
        },
        methods: {
            ...mapActions('account', [
                'getAccounts',
                'getAllRoles',
                'addNewAccount'
            ]),
            countDownChanged(dismissCountDown) {
                this.alertSettings.dismissCountDown = dismissCountDown
            },
            setAlertMessage(type, title, message, countDown){
                this.alertSettings.title = title
                this.alertSettings.alertMsg = message
                this.alertSettings.alertVariant = type
                this.alertSettings.dismissCountDown = this.alertSettings.countDownSecs + countDown
            },
            validateState(field) {
                const { $dirty, $error } = this.$v.newAccount[field]
                return $dirty ? !$error : null
            },
			validatePasswordState() {
				const { $dirty, $error } = this.$v.newPassword
				return $dirty ? !$error : null
			},
			toGetLoginInfo() {
				this.accountInfo = JSON.parse(sessionStorage.getItem('accountInfo'))
			},
            toGetAccounts() {
				this.getAccounts()
            },
            toGetAllRoles() {
				let accountInfoFromSession = JSON.parse(sessionStorage.getItem('accountInfo'))
				let userLoginId = accountInfoFromSession.loginId
				this.getAllRoles({
					userLoginId: userLoginId
				})
            },
            saveAccount: function() {
                this.$v.$touch()
                if(!this.$v.newAccount.$invalid){
                    this.saveBtnDisabled = true
                    let addNewAccountRQ = AccountUtils.getAddNewAccountRQ({
                        newAccount: this.newAccount
                    })
                    AccountApi.addNewAccount(addNewAccountRQ).then(res => {
                        this.saveBtnDisabled = false
                        if(res.data.success != undefined && !res.data.success){
                            let title = 'Sorry!'
                            let errorMsg = ''
                            try{
                                errorMsg = res.data.errors.errors[0].stringValue
                            }catch{
                                errorMsg = 'Please try again later.'
                            }
                            this.setAlertMessage('danger', title, errorMsg, 15)
                            
                        }else{
                            this.setAlertMessage('success', 'Successfully!', 'Account was created.', 15)
                            
                            this.reloadAccounts()
                            this.isAddAccountModalVisible = false
                        }
                    }).catch(error => {
                        let title = 'Sorry!'
                        let errorMsg = ''
                        if(error.response){
                            try{
                                errorMsg = error.response.data.errors.errors[0].stringValue
                            }catch{
                                console.info(error.response)
                                errorMsg = "Some reponse errors"
                            }
                            
                        }else if(error.request){
                            console.info(error.request)
                            errorMsg = "Some request errors"
                        }else{
                            console.info(error)
                            errorMsg = "Some data errors"
                        }
                        this.saveBtnDisabled = false
                        this.setAlertMessage('danger', title, errorMsg, 15)
                    })   
                }
            },
            toAlterPassword(item){
				this.newPassword = ''
				this.$v.newPassword.$reset()
				this.isAlterPassword = true
				this.alteredUsername = item.username
                console.log(item)
            },
			toUpdateAccount(item) {
				this.$router.push({
					name: 'accountDetails',
					query: {
						username: item.username
					}
				})
			},
			toSaveNewPassword(){
				this.$v.newPassword.$touch()
				if(!this.$v.newPassword.$invalid){
					this.alterPasswordBtnDisabled = true
					let payload = {}
					payload.username = this.alteredUsername
					payload.newPasswordRQ = {"password": this.newPassword}
					AccountApi.changePassword(payload).then(res => {
						this.alterPasswordBtnDisabled = false
						if(res.data.success){
							this.isAlterPassword = false
							
							this.setAlertMessage('success', 'Successfully!', 'Password was changed.', 15)
						}else{
							let title = 'Sorry!'
							let errorMsg = ''
							try{
								errorMsg = res.data.errors.errors[0].stringValue
							}catch{
								errorMsg = 'Please try again later.'
							}
							this.setAlertMessage('danger', title, errorMsg, 15)
						}
					}).catch(error => {
						let title = 'Sorry!'
						let errorMsg = ''
						if(error.response){
							try{
								errorMsg = error.response.data.errors.errors[0].stringValue
							}catch{
								console.info(error.response)
								errorMsg = "Some reponse errors"
							}
							
						}else if(error.request){
							console.info(error.request)
							errorMsg = "Some request errors"
						}else{
							console.info(error)
							errorMsg = "Some data errors"
						}
						this.alterPasswordBtnDisabled = false
						this.setAlertMessage('danger', title, errorMsg, 15)
					})   
				}
			},
            reloadAccounts(){
                this.resetForm()
                this.isTableloading = true
                this.getAccounts()
            },
            resetForm(){
                this.newAccount = {
                    userLoginId: '',
                    username: '',
                    firstName: '',
                    lastName: '',
                    groupIds: [],
                    active: true,
                    password: '',
                    confirmPassword: '',
                }
                this.$v.$reset()
            } 

        }
    }
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items"></PageHeader>
        <!-- search box -->
        <b-card bg-variant="white">
            <b-row>
                <b-col cols="4">
                    <b-form-input v-model="filter" placeholder="Type to search"></b-form-input>
                </b-col>
                <!-- <b-col cols="3">
                    <b-form-select v-model="rSelected" :options="roleOptions"
                    value-field="groupId" text-field="name">
                        <template #first>
                            <b-form-select-option :value="null" disabled>All Role</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col> -->
                <!-- <b-col cols="3">
                    <b-form-select v-model="tSelected" :options="teamOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>All Team</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col> -->
                <!-- <b-col cols="1">
                    <b-button variant="success">Search</b-button>  
                </b-col> -->
                <b-col>
                    <b-button 
                    v-b-modal.add-account-modal
					v-permission="{permission: 'agencyportal.security.user', effect: 'hidden'}"
                    variant="primary">Add Account</b-button>
                </b-col>
            </b-row>
			<b-row v-if="isAlterPassword">
				<b-col>
					<div class="mt-2 bg-soft-secondary p-3">
						You are changing password for account <span class="text-primary ml-1"><strong>{{ alteredUsername }}</strong></span>
					</div>
					<div class="bg-soft-secondary d-flex p-3">
						<div class="">
							<b-form-group>
								<b-form-input v-model="$v.newPassword.$model" placeholder="New Password"
								:state="validatePasswordState()"
								aria-describedby="new-password-feedback"
								autocomplete="off"
								></b-form-input>
								<b-form-invalid-feedback id="new-password-feedback">
									{{errorMsgs.password.text}}</b-form-invalid-feedback>
							</b-form-group>
						</div>
						<div class="ml-2">
							<b-button
							variant="success"
							@click="toSaveNewPassword()"
							>
								<template >
									<b-spinner small v-if="alterPasswordBtnDisabled"></b-spinner>
									<span class="">{{alterPasswordBtnDisabled ? 'Loading...' : 'Change Password'}}</span>
								</template>
							</b-button>
						</div>
						<div class="ml-2">
							<b-button
							variant="secondary" v-if="!alterPasswordBtnDisabled"
							@click="isAlterPassword = false">Cancel</b-button>
						</div>
					</div>
				</b-col>
			</b-row>
            <b-row>
                <b-col cols="12">
                    <b-table striped :fields="accountFields" 
                    :items="accountItems" :filter="filter" 
                    :busy="isTableloading"
                    class="mt-3"
                    head-variant="dark">

                        <template #table-busy>
                            <div class="text-center text-info my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                            </div>
                        </template>

                        <template #cell(actions)="data">
                            <!-- <b-button size="sm" variant="outline-secondary" v-if="accountInfo.userType == 'Admin'"
							@click="toAlterPassword(data.item)"
							>
								<i class="mdi mdi-arrow-top-right"></i>
							</b-button> -->
							<b-button size="sm" variant="outline-secondary"
                        v-permission="{permission: 'agencyportal.security.user', effect: 'hidden'}"
							@click="toUpdateAccount(data.item)"
							>
								<i class="mdi mdi-pencil"></i>
							</b-button>
                        </template>
                        <!-- A virtual column -->
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>

                        <!-- A custom formatted column -->
                        <template #cell(name)="data">
                            <b class="text-info">{{ data.item.partyShortName}}</b>
                        </template>

                        <!-- A virtual composite column -->
                        <template #cell(username)="data">
                            {{ data.value }}
                        </template>

                        <!-- Optional default data cell scoped slot -->
                        <template #cell(role)="data">
                            <i>User Login Id: {{ data.item.userLoginId }}</i>
                        </template>

                        <template #cell(active)="data">
                            <i>{{ data.item.loginEnabled ? 'Active' : 'Inactive'}}</i>
                        </template>


                    </b-table>
                </b-col>
            </b-row>
        </b-card>

        <!-- add account modal -->
        <b-modal 
        v-model="isAddAccountModalVisible"
        id="add-account-modal" title="Add Account"
        centered
        no-close-on-backdrop
        hide-footer
        size="lg"
        ok-title="Save"
        ok-variant="success"
        >
            <div>
                <b-row>
                    <b-col>
                        <b-form-group>
                            <b-form-input  placeholder="First Name"
                            v-model="$v.newAccount.firstName.$model"
                            :state="validateState('firstName')"
                            aria-describedby="first-name-feedback"
                            autocomplete="off"
                            ></b-form-input>
                            <b-form-invalid-feedback id="first-name-feedback">
                                {{errorMsgs.required.text}}</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group>
                            <b-form-input placeholder="Last Name"
                            v-model="$v.newAccount.lastName.$model"
                            :state="validateState('lastName')"
                            aria-describedby="last-name-feedback"
                            autocomplete="off"
                            ></b-form-input>
                            <b-form-invalid-feedback id="last-name-feedback">
                                {{errorMsgs.required.text}}</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-form-group>
                    <b-form-input id="user-account" placeholder="Account Name" name="accountUsername"
                    v-model="$v.newAccount.username.$model"
                    :state="validateState('username')"
                    aria-describedby="account-name-feedback"
                    autocomplete="off"
                    ></b-form-input>
                    <b-form-invalid-feedback id="account-name-feedback">
                        {{errorMsgs.username.text}}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group>
                    <b-form-select v-model="$v.newAccount.active.$model">
                        <b-form-select-option :value="true">Active</b-form-select-option>
                        <!-- <b-form-select-option :value="false">Inactive</b-form-select-option> -->
                    </b-form-select>
                </b-form-group>
                <b-form-group>
                    <b-form-input id="user-account-psw" type="password" v-model="$v.newAccount.password.$model" placeholder="Password"
                    :state="validateState('password')"
                    aria-describedby="account-password-feedback"
                    autocomplete="off"
                    ></b-form-input>
                    <b-form-invalid-feedback id="account-password-feedback">
                        {{errorMsgs.password.text}}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group>
                    <b-form-input type="password" v-model="$v.newAccount.confirmPassword.$model" placeholder="Confirm Password"
                    :state="validateState('confirmPassword')"
                    aria-describedby="account-confirm-password-feedback"
                    autocomplete="off"
                    ></b-form-input>
                    <b-form-invalid-feedback id="account-confirm-password-feedback">
                        {{errorMsgs.notSameAs.text}}</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                label="Roles"
                >
                    <b-form-checkbox-group
                    v-model="$v.newAccount.groupIds.$model"
                    :options="roleOptions"
                    value-field="groupId" text-field="name"
                    :state="validateState('groupIds')"
                    stacked
                    >
                        <b-form-invalid-feedback :state="validateState('groupIds')">
                            {{errorMsgs.role.text}}</b-form-invalid-feedback> 
                    </b-form-checkbox-group>
                </b-form-group>
                
                <!-- <b-form-group>
                    <b-form-select v-model="$v.newAccount.groupId.$model" :options="roleOptions"
                    value-field="groupId" text-field="name"
                    :state="validateState('groupId')"
                    aria-describedby="account-role-feedback"
                    >
                        <template #first>
                            <b-form-select-option value="" disabled>Assign a role</b-form-select-option>
                        </template>     
                    </b-form-select>
                    <b-form-invalid-feedback id="account-role-feedback">
                        {{errorMsgs.role.text}}</b-form-invalid-feedback>
                </b-form-group> -->
                <div class="d-flex justify-content-end">
                    <b-button
                    @click="saveAccount" 
                    variant="primary"
                    :disabled="saveBtnDisabled"
                    >
                    <template >
                        <b-spinner small v-if="saveBtnDisabled"></b-spinner>
                        <span class="">{{saveBtnDisabled ? 'Processing...' : 'Save'}}</span>
                    </template>
                    </b-button>
                </div>
            </div>
        </b-modal>
        
        
		
		<b-alert
        :show="alertSettings.dismissCountDown"
        class="position-fixed fixed-top m-0 rounded-0 text-center"
        style="z-index: 2000;"
        :variant="alertSettings.alertVariant"
        dismissible
        @dismiss-count-down="countDownChanged"
        >
<!--            <div v-html="alertSettings.title"></div>-->
            <div v-html="alertSettings.alertMsg"></div>
        </b-alert>
    </Layout>
</template>



<style scoped>

</style>