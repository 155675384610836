<script>
import Layout from '../layouts/main.vue';
import PageHeader from '../../components/page-header.vue';
import AccountApi from '../../api/accountApi'
// import AccountUtils from '../../utils/account/accountUtils'
import { mapGetters } from 'vuex';
import { validationMixin } from "vuelidate";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
export default {
	components: {
		Layout,
		PageHeader
	},
	mixins: [validationMixin],
    data () {
        return {
            title: "Account Details",
            items: [
                {
                    text: "Accounts",
                },
				{
					text: "Account Details",
					active: true,
				}
            ],
			alertSettings: {
				dismissCountDown: 0,
				countDownSecs: 4,
				title: '',
				alertMsg: '',
				alertVariant: 'warning'
			},
			errorMsgs: {
				required: {
					text: 'This Field is required'
				},
				username: {
					text: 'Required as an email address between 5 and 50 characters'
				},
				role: {
					text: 'Please at least assign a role'
				},
				password: {
					text: 'Required between 8 and 20 characters'
				},
				notSameAs: {
					text: 'Please confirm your password'
				}
			},
			isEditAccountModalVisible: false,
			updatedAccount: {},
			roles: [],
			username: {},
			isAlteringPassword: false,
			newPassword: {},
			alterPasswordBtnDisabled: false,
        }
    },
	validations() {
		return {
			newPassword: {
				required,
				minLength: minLength(8),
				maxLength: maxLength(20),
			}
		}
	},
	computed: {
		...mapGetters('account', [
			'allRoles'
		]),
	},
    methods: {
		countDownChanged(dismissCountDown) {
			this.alertSettings.dismissCountDown = dismissCountDown
		},
		setAlertMessage(type, title, message, countDown){
			this.alertSettings.title = title
			this.alertSettings.alertMsg = message
			this.alertSettings.alertVariant = type
			this.alertSettings.dismissCountDown = this.alertSettings.countDownSecs + countDown
		},
		validatePasswordState() {
			const { $dirty, $error } = this.$v.newPassword
			return $dirty ? !$error : null
		},
		toLoadAccountInfo(username) {
			AccountApi.getUserInfoByUsername(username).then(res => {
				this.updatedAccount = res.data[0]
				this.roles = this.updatedAccount.roles.split(',')
				this.setAlertMessage('success', 'Successfully!', 'Account information loaded.', 15)
			}).catch(error => {
				let title = 'Sorry!'
				let errorMsg = ''
				if(error.response){
					try{
						errorMsg = error.response.data.errors.errors[0].stringValue
					}catch{
						console.info(error.response)
						errorMsg = "Some reponse errors"
					}
					
				}else if(error.request){
					console.info(error.request)
					errorMsg = "Some request errors"
				}else{
					console.info(error)
					errorMsg = "Some data errors"
				}
				this.alterPasswordBtnDisabled = false
				this.setAlertMessage('danger', title, errorMsg, 15)
			})
		},
		toAlterPassword(){
			this.newPassword = ''
			this.$v.newPassword.$reset()
			this.isAlteringPassword = true
		},
		toSaveNewPassword(){
			this.$v.newPassword.$touch()
			if(!this.$v.newPassword.$invalid){
				this.alterPasswordBtnDisabled = true
				let payload = {}
				payload.username = this.username
				payload.newPasswordRQ = {"password": this.newPassword}
				AccountApi.changePassword(payload).then(res => {
					this.alterPasswordBtnDisabled = false
					if(res.data.success){
						this.isAlteringPassword = false
						this.setAlertMessage('success', 'Successfully!', 'Password was changed.', 15)
					}else{
						let title = 'Sorry!'
						let errorMsg = ''
						try{
							errorMsg = res.data.errors.errors[0].stringValue
						}catch{
							errorMsg = 'Please try again later.'
						}
						this.setAlertMessage('danger', title, errorMsg, 15)
					}
				}).catch(error => {
					let title = 'Sorry!'
					let errorMsg = ''
					if(error.response){
						try{
							errorMsg = error.response.data.errors.errors[0].stringValue
						}catch{
							console.info(error.response)
							errorMsg = "Some reponse errors"
						}
						
					}else if(error.request){
						console.info(error.request)
						errorMsg = "Some request errors"
					}else{
						console.info(error)
						errorMsg = "Some data errors"
					}
					this.alterPasswordBtnDisabled = false
					this.setAlertMessage('danger', title, errorMsg, 15)
				})   
			}
		},
    },
	created() {
		this.username = this.$route.query.username
		this.toLoadAccountInfo(this.username)
	}
}

</script>

<template>
<Layout>
	<PageHeader :title="title" :items="items"></PageHeader>
	<b-card>
    <div>
		<div class="d-flex justify-content-start" v-if="!isAlteringPassword"
		v-permission="{permission: 'agencyportal.security.user', effect: 'hidden'}"
		>
			<!-- <div>
				<b-button
				size="sm"
				variant="success"
				>Edit Account</b-button>
			</div> -->
			<div class="ml-3">
				<b-button
				size="sm"
				@click="toAlterPassword()" 
				variant="success"
				>Change Password</b-button>
			</div>
		</div>
		<div v-if="isAlteringPassword">
			<b-row>
				<b-col>
					<div class="mt-2 bg-soft-secondary p-3">
						You are changing password.
					</div>
					<div class="bg-soft-secondary d-flex p-3">
						<div class="">
							<b-form-group>
								<b-form-input v-model="$v.newPassword.$model" placeholder="New Password"
								:state="validatePasswordState()"
								aria-describedby="new-password-feedback"
								autocomplete="off"
								></b-form-input>
								<b-form-invalid-feedback id="new-password-feedback">
									{{errorMsgs.password.text}}</b-form-invalid-feedback>
							</b-form-group>
						</div>
						<div class="ml-2">
							<b-button
							variant="success"
							@click="toSaveNewPassword()"
							>
								<template >
									<b-spinner small v-if="alterPasswordBtnDisabled"></b-spinner>
									<span class="">{{alterPasswordBtnDisabled ? 'Loading...' : 'Save'}}</span>
								</template>
							</b-button>
						</div>
						<div class="ml-2">
							<b-button
							variant="secondary" v-if="!alterPasswordBtnDisabled"
							@click="isAlteringPassword = false">Cancel</b-button>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
		<div class="mt-3">
			<h5>Account information</h5>
			<b-row>
				<b-col>
					<label>Given name:</label><span class="ml-1">{{ updatedAccount.givenName }}</span>
				</b-col>
				<b-col>
					<label>Surname:</label><span class="ml-1">{{ updatedAccount.surname }}</span>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<label>Username:</label><span class="ml-1">{{ updatedAccount.id }}</span>
				</b-col>
				<b-col>
					<label>Enabled:</label><span class="ml-1">{{ updatedAccount.enabled }}</span>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<label>Email:</label><span class="ml-1">
					{{ updatedAccount.emails && updatedAccount.emails.length > 0 ? updatedAccount.emails[0].emailAddress : ''}}</span>
				</b-col>
				<b-col>
					<label>Telephone:</label><span class="ml-1">
					{{ updatedAccount.telephones && updatedAccount.telephones.length > 0 ? updatedAccount.telephones[0].phoneNumber : ''}}</span>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<label>Roles:</label><span class="ml-1"></span>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<div v-for="role, index in roles" :key="index">
						<span class="p-2">{{ role }}</span>
					</div>
				</b-col>
			</b-row>
		</div>
    </div>
	</b-card>
	<b-modal
	v-model="isEditAccountModalVisible"
	id="add-account-modal" title="Add Account"
	centered
	no-close-on-backdrop
	hide-footer
	size="lg"
	ok-title="Save"
	ok-variant="success"
	>
		<div class="d-flex justify-content-start">
		</div>
		<div>
			
		</div>
	</b-modal>
	<b-alert
	:show="alertSettings.dismissCountDown"
	class="position-fixed fixed-top m-0 rounded-0 text-center"
	style="z-index: 2000;"
	:variant="alertSettings.alertVariant"
	dismissible
	@dismiss-count-down="countDownChanged"
	>
<!--		<div v-html="alertSettings.title"></div>-->
		<div v-html="alertSettings.alertMsg"></div>
	</b-alert>
</Layout>
</template>

