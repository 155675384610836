/*
* 代替视图，与mutation进行交互，根据用户需求通过hotelApi工具包请求后端数据，通过mutations更新state中的状态
* */
import * as types from "./mutationsTypes"
import airApi from "../../../api/airApi";

const actions = {
    lowFareFlights({commit}, payload){
        airApi.lowFareSearch(payload.lowFareSearchRQ).then(res =>{
            let lowFareFlightRS = "";
            lowFareFlightRS = res.data;
            // console.log(res.data)
            commit(types.UPDATE_LOWFAREFLIGHTSRS, {
                lowFareFlightRS: lowFareFlightRS,
            });
        });
    },
    multipleFareFilghts({commit}, payload){
        airApi.lowFareSearch(payload.multipleFareSearchRQ).then(res =>{
            let multipleFareSearchRS = "";
            multipleFareSearchRS = res.data;
            // console.log(res.data)
            commit(types.UPDATE_MULTIPLELOWFAREFLIGHTSRS, {
                multipleFareSearchRS: multipleFareSearchRS,
            });
        });
    },
    lowFareFlightsRoundTrip({commit}, payload){
        airApi.lowFareSearch(payload.lowFareSearchRQ).then(res =>{
            let lowFareFlightsRoundTripRS = "";
            lowFareFlightsRoundTripRS = res.data;
            // console.log(res.data)
            commit(types.UPDATE_LOWFAREFLIGHTSROUNDTRIPRS, {
                lowFareFlightsRoundTripRS: lowFareFlightsRoundTripRS,
            });
        });
    },
    verifyPriceSearch({commit}, payload){
        airApi.verifySearch(payload.verifyPriceRQ).then(res =>{
            let verifyPriceRS = "";
            verifyPriceRS = res.data;
            // console.log(res.data)
            commit(types.UPDATE_VERIFYPRICERS, {
                verifyPriceRS: verifyPriceRS,
            });
        });
    },
    bookTickets({commit}, payload){
        airApi.book(payload.bookRQ).then(res =>{
            let bookRS = "";
            bookRS = res.data;
            // console.log(res.data)
            commit(types.UPDATE_BOOKRS, {
                bookRS: bookRS,
            });
        });
    },
}

export default actions;