<script>
import Layout from "../layouts/main.vue";

import PageHeader from "../../components/page-header.vue";
import TrainInfoVue from "./TrainInfo.vue";
import TravelerFormVue from "../../components/rail/TravelerForm.vue";

import { mapActions, mapGetters } from "vuex";
import RailUtils from "../../utils/RailUtils";
import RailAPI from "../../api/RailAPI";

export default {
  name: "CartBookVue",
  components: {
    Layout,
    PageHeader,
    TrainInfoVue,
    TravelerFormVue
  },
  data() {
    return {
      title: "Cart",
      items: [
        {
          text: "Cart"
        },
        {
          text: "Passengers",
          active: true
        }
      ],
      isOverLayShow: false,
      alertSettings: {
        dismissCountDown: 0,
        countDownSecs: 4,
        title: "",
        alertMsg: "",
        alertVariant: "warning"
      },
      travelerFormValues: [],
      travelersFormForP2P: [],
      p2pOptions: [],
      travelersFormForPass: [],
      correlationID: "",
      travelers: [],
      confirmBookingRQ: "",
      isInitiateStep: false,
      isQuoteStep: true,
      isPreBooked: false,
      isConfirmed: false,
      cart: [],
      selectedProductIndex: [],
      selectedProductsRph: [],
      selectedProductFromCart: [],
      isIssueTicketConfirmModalVisible: false,
      isIssueTicketAgreed: false,
      reservations: [],
      sellingCurrencyCode: "",
      bookedTotalPrice: 0,
      bookedTotalTaxes: [],
      isPassengersNotAllCommitted: false
    };
  },
  computed: {
    ...mapGetters("rail", ["bookFromCart", "bookInitiateRS", "bookTicketRS",  "selectedOutboundTicket",
        "selectedInboundTicket",
        "isRoundTrip",]),
    ...mapGetters("account", ["accountInfo", "loginStatusRS", "logedIn"])
  },
  watch: {
    bookTicketRS() {
      //quote
      this.isOverLayShow = false;
      if (this.bookTicketRS.success) {
        this.isQuoteStep = false;
        this.isPreBooked = true;
        this.isPassengersNotAllCommitted = false;

        //book 成功，删除cart里对应的items
        this.toUpdateCart();
        this.confirmBookingRQ = RailUtils.getConfirmBookingRQ(
          this.bookTicketRS
        );
        this.setAlertMessage(
          "success",
          "Successfully!",
          "Booking was created, please check out to issue tickets.",
          15
        );
      } else {
        this.setAlertMessage(
          "danger",
          this.bookTicketRS.errorTitle,
          this.bookTicketRS.errorMsg,
          15
        );
      }
    }
  },
  created() {
    let bookFromCartSession = JSON.parse(
      sessionStorage.getItem("bookFromCart")
    );
    this.updatebookFromCart({
      bookFromCart: bookFromCartSession
    });
    this.toInitiate();
      let selectedOutboundTicketSession = sessionStorage.getItem(
          "selectedOutboundTicket"
      )
          ? JSON.parse(sessionStorage.getItem("selectedOutboundTicket"))
          : [];
      let selectedInboundTicketSession = sessionStorage.getItem(
          "selectedInboundTicket"
      )
          ? JSON.parse(sessionStorage.getItem("selectedInboundTicket"))
          : [];
      let isRoundTripSession = JSON.parse(sessionStorage.getItem("isRoundTrip"));

      //更新 vuex
      this.updateTiketDetails({
          selectedOutboundTicket: selectedOutboundTicketSession,
          selectedInboundTicket: selectedInboundTicketSession,
          isRoundTrip: isRoundTripSession
      });
  },
  methods: {
    ...mapActions("rail", ["bookTicket", "updatebookFromCart","updateTiketDetails"]),
    ...mapActions("booking", ["removeGoodsFromCart", "loadGoodsFromCart"]),
    countDownChanged(dismissCountDown) {
      this.alertSettings.dismissCountDown = dismissCountDown;
    },
    setAlertMessage(type, title, message, countDown) {
      this.alertSettings.title = title;
      this.alertSettings.alertMsg = message;
      this.alertSettings.alertVariant = type;
      this.alertSettings.dismissCountDown =
        this.alertSettings.countDownSecs + countDown;
    },
    toRailHome() {
      this.$router.push({
        name: "rail-home"
      });
    },
    toBookingList() {
      this.$router.push({
        name: "bookingRail"
      });
    },
    toOpenIssueTicketConfirmModal() {
      this.isIssueTicketConfirmModalVisible = true;
    },
    hideIssueTicketModal() {
      this.isIssueTicketConfirmModalVisible = false;
      this.isIssueTicketAgreed = false;
    },
    checkOut: function() {
      this.resetDatas();
      this.isOverLayShow = true;
      RailAPI.confirmBooking(this.confirmBookingRQ)
        .then(res => {
          this.isOverLayShow = false;
          let confirmbookingRS = res.data;
          if (
            confirmbookingRS.confirmations != undefined &&
            confirmbookingRS.confirmations.length > 0
          ) {
            this.isPreBooked = false;
            this.isConfirmed = true;

            this.setAlertMessage(
              "success",
              "Successfully!",
              "Booking was confirmed. Tickets were issued.",
              15
            );
          } else if (
            !confirmbookingRS.success &&
            confirmbookingRS.errors &&
            confirmbookingRS.errors.errors
          ) {
            let error = confirmbookingRS.errors.errors[0];
            let stringValue = error.stringValue;
            this.setAlertMessage("danger", "Sorry!", stringValue, 15);
          } else {
            this.setAlertMessage(
              "danger",
              "Sorry!",
              "Supplier response errors.",
              15
            );
          }
        })
        .catch(error => {
          let title = "Sorry!";
          let errorMsg = "";
          if (error.response) {
            try {
              errorMsg = error.response.data.errors.errors[0].stringValue;
            } catch {
              console.info(error.response);
              errorMsg = "Some reponse errors";
            }
          } else if (error.request) {
            console.info(error.request);
            errorMsg = "Some request errors";
          } else {
            console.info(error);
            errorMsg = "Some data errors";
          }
          this.isOverLayShow = false;

          this.setAlertMessage("danger", title, errorMsg, 15);
        });
    },
    toUpdateCart() {
      let thisTemp = this;
      thisTemp.removeGoodsFromCart({ indexes: thisTemp.bookFromCart.indexes });
    },
    // toUpdateCart(){
    //
    //     let cartFromSession = []
    //     let newCart = []
    //
    //     if(sessionStorage.getItem('cart') != null){
    //         cartFromSession = JSON.parse(sessionStorage.getItem('cart'))
    //     }
    //     // console.log(cartFromSession)
    //     cartFromSession.forEach((item, index) => {
    //
    //         if(!this.bookFromCart.indexes.includes(index)){
    //             newCart.push(item)
    //         }
    //     })
    //
    //     // console.log(newCart)
    //     sessionStorage.setItem('cart', JSON.stringify(newCart))
    //     this.updateCartNumber({
    //         cartNumber: newCart.length
    //     })
    //
    // },
    toCancel: function() {
      this.$router.push({
        name: "cart"
      });
    },
    toPreBook: function() {
      let isPassengersCommitted = this.toCheckIfPassegersIsCommitted();
      let islogedIn = this.logedIn;
      let accountInfoFromSession = JSON.parse(
        sessionStorage.getItem("accountInfo")
      );
      let username = accountInfoFromSession.id;
      if (isPassengersCommitted) {
        if (islogedIn) {
          let bookRQ = RailUtils.getBookQuoteRQ({
            correlationID: this.bookFromCart.correlationID,
            reservations: this.reservations,
            username: username
          });
          this.isOverLayShow = true;
          this.bookTicket(bookRQ);
        } else {
          this.setAlertMessage("danger", "Sorry!", "Please log in.", 15);
        }
      }
    },
    toInitiate() {
      this.reservations = JSON.parse(
        JSON.stringify(this.bookFromCart.reservations)
      );
      this.reservations.forEach(revItem => {
        if (revItem.tpaExtensions.valueMap.reservationType == "P2P") {
          revItem.type = "P2P";
          revItem.trip = RailUtils.getP2pItinerary(revItem);
          revItem.leadTraveler = RailUtils.getLeadTravelerForm(revItem);
          revItem.travelers = RailUtils.getTicketTravelersFormArray(revItem);
          revItem.passengersCommitted = { committed: false };
          revItem.p2pOptions = RailUtils.getP2POptions(revItem);
          revItem.bookedPrice = RailUtils.getInitiateBookedPrice(revItem);
        }
        if (revItem.tpaExtensions.valueMap.reservationType == "PASS") {
          revItem.type = "PASS";
          revItem.trip = RailUtils.getPassItinerary(revItem);
          revItem.leadTraveler = RailUtils.getLeadTravelerForm(revItem);
          revItem.travelers = RailUtils.getPassTravelersFormArray(revItem);
          revItem.passengersCommitted = { committed: false };
          revItem.bookedPrice = RailUtils.getInitiateBookedPrice(revItem);
        }
        //计算总金额
        this.bookedTotalPrice = (
          Number(this.bookedTotalPrice) +
          Number(revItem.bookedPrice.sellingTotalAmount)
        ).toFixed(2);
        if (this.sellingCurrencyCode == "") {
          this.sellingCurrencyCode = revItem.bookedPrice.sellingCurrencyCode;
        }
        //计算其他费用的总金额
        revItem.bookedPrice.taxes.forEach(taxItem => {
          let hasIndex = this.bookedTotalTaxes.findIndex(item => {
            return item.name === taxItem.name;
          });
          if (hasIndex == -1) {
            this.bookedTotalTaxes.push({
              sellingCurrencyCode: taxItem.sellingCurrencyCode,
              sellingAmount: taxItem.sellingAmount,
              name: taxItem.name
            });
          } else {
            this.bookedTotalTaxes[hasIndex].sellingAmount = (
              Number(this.bookedTotalTaxes[hasIndex].sellingAmount) +
              Number(taxItem.sellingAmount)
            ).toFixed(2);
          }
        });
      });
    },
    resetDatas() {
      //hide modal
      this.isIssueTicketConfirmModalVisible = false;
      this.isIssueTicketAgreed = false;
      //reset alert messages
      this.setAlertMessage("warning", "", "", 15);
    },
    toCheckIfPassegersIsCommitted() {
      let isCommitted = true;

      this.reservations.forEach(revItem => {
        if (!revItem.passengersCommitted.committed) {
          isCommitted = false;
          this.isPassengersNotAllCommitted = true;
        }
      });

      return isCommitted;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <b-overlay :show="isOverLayShow" rounded="sm">
      <b-row v-for="(item, index) in reservations" :key="`product-${index}`">
        <b-col v-if="item.type === 'PASS'">
          <b-card>
            <div class="border-bottom d-flex justify-content-between">
              <h5 class="font-size-14 text-primary">{{ item.trip.label }}</h5>
            </div>
            <div class="">
              <b-card>
                {{ item.trip.travelClass }}
                <br />First travel at {{ item.trip.firstTravelDate }}
              </b-card>
            </div>
            <div class="">
              <TravelerFormVue
                :travelers="item.travelers"
                :leadTravelerForm="item.leadTraveler"
                :idPrefix="`pass-${index}-`"
                :travel-date-time="selectedOutboundTicket.departureDateTime"
                :committed="item.passengersCommitted"
                :isQuoteStep="isQuoteStep"
              ></TravelerFormVue>
            </div>
            <div class="d-flex justify-content-end">
              <div
                class="px-3"
                v-for="(taxItem, taxIndex) in item.bookedPrice.taxes"
                :key="`pass-tax-${taxIndex}`"
              >
                <small
                  >{{ taxItem.name }}
                  <span class="text-danger">{{
                    taxItem.sellingCurrencyCode + " " + taxItem.sellingAmount
                  }}</span></small
                >
              </div>
              <div class="d-flex justify-content-end">
                <span class="px-1">Total after commission</span>
                <span class="text-danger px-1"
                  ><b>{{ item.bookedPrice.sellingCurrencyCode }}</b></span
                >
                <span class="text-danger px-1"
                  ><b>{{ item.bookedPrice.sellingTotalAmount }}</b></span
                >
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col v-if="item.type === 'P2P'">
          <b-card>
            <div class="border-bottom d-flex justify-content-between">
              <h5 class="font-size-14 text-success">
                {{ item.trip.originLocation }}
                <i
                  v-if="item.trip.isRoundTrip"
                  class="mdi mdi-arrow-left-right-bold"
                ></i>
                <i v-else class="mdi mdi-arrow-right-bold"></i>
                {{ item.trip.destinationLocation }}
              </h5>
            </div>
            <div>
              <TrainInfoVue
                v-if="item.trip.isRoundTrip"
                :selected-outbound-ticket="item.trip.trains[0]"
                :selected-inbound-ticket="item.trip.trains[1]"
              ></TrainInfoVue>
              <TrainInfoVue
                v-else
                :selected-outbound-ticket="item.trip.trains[0]"
              ></TrainInfoVue>
            </div>
            <div
              class="bg-white"
              v-if="isQuoteStep && item.p2pOptions.length > 0"
            >
              <h6>Ouigo Options</h6>
              <div
                class="p-2"
                v-for="(option, opIdx) in item.p2pOptions"
                :key="`p2p-option-${opIdx}`"
              >
                <div class="p-2 border bg-light">
                  {{ option.label }}
                </div>
                <div class="mt-2">
                  <p>
                    <b>{{ option.title }}</b>
                  </p>
                  <p v-html="option.description"></p>
                </div>
                <div
                  class="mt-2 border-top"
                  v-if="option.summary !== undefined"
                >
                  <span class="text-success">{{ option.summary }}</span>
                </div>
                <div class="d-flex" v-if="option.label == 'Included luggage'">
                  <div class="p-3">
                    <img src="../../assets/images/handbag.svg" />
                  </div>
                  <div class="p-3">
                    <img src="../../assets/images/cabin-luggage.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <TravelerFormVue
                :travelers="item.travelers"
                :leadTravelerForm="item.leadTraveler"
                :idPrefix="`p2p-${index}-`"
                :committed="item.passengersCommitted"
                :isQuoteStep="isQuoteStep"
                :travel-date-time="selectedOutboundTicket.departureDateTime"
              ></TravelerFormVue>
            </div>
            <div class="d-flex justify-content-end">
              <div
                class="px-4"
                v-for="(taxItem, taxIndex) in item.bookedPrice.taxes"
                :key="`p2p-tax-${taxIndex}`"
              >
                <small
                  >{{ taxItem.name }}
                  <span class="text-danger">{{
                    taxItem.sellingCurrencyCode + " " + taxItem.sellingAmount
                  }}</span></small
                >
              </div>
              <div class="px-4 d-flex justify-content-end">
                <div class="px-1">Total after commission</div>
                <div class="px-1">
                  <span class="text-danger"
                    ><b>{{ item.bookedPrice.sellingCurrencyCode }}</b></span
                  >
                </div>
                <div class="px-1">
                  <span class="text-danger"
                    ><b>{{ item.bookedPrice.sellingTotalAmount }}</b></span
                  >
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="border py-3 d-flex justify-content-end bg-soft-primary">
            <div
              class="px-4"
              v-for="(taxItem, taxIndex) in bookedTotalTaxes"
              :key="`booked-tax-${taxIndex}`"
            >
              <small
                >{{ taxItem.name }}
                <span class="text-danger">{{
                  taxItem.sellingCurrencyCode + " " + taxItem.sellingAmount
                }}</span></small
              >
            </div>
            <div class="px-4 d-flex justify-content-end">
              <div class="px-1">Total after commission</div>
              <div class="px-1">
                <span class="text-danger"
                  ><strong>{{ sellingCurrencyCode }}</strong></span
                >
              </div>
              <div class="px-1">
                <span class="text-danger"
                  ><strong>{{ bookedTotalPrice }}</strong></span
                >
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div
            class="text-center text-danger"
            v-if="isPassengersNotAllCommitted"
          >
            <p>Please save all passenger info. before Book</p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="p-2 d-flex justify-content-between" v-if="isQuoteStep">
            <div>
              <b-button variant="secondary" @click="toCancel">Cancel</b-button>
            </div>
            <div>
              <b-button
                variant="success"
                @click="toPreBook"
                v-permission="{
                  permission: 'agencyportal.rail.booking',
                  effect: 'hidden'
                }"
                >Confirm</b-button
              >
            </div>
          </div>
          <div class="p-2 d-flex justify-content-end" v-if="isPreBooked">
            <b-button
              variant="success"
              @click="toOpenIssueTicketConfirmModal"
              v-permission="{
                permission: 'agencyportal.rail.ticketing',
                effect: 'hidden'
              }"
              >Check Out</b-button
            >
          </div>
          <div class="p-2 d-flex justify-content-end" v-if="isConfirmed">
            <!-- <b-button variant="success" @click="toRailHome">Find more tickets</b-button> -->
            <b-button variant="success" @click="toBookingList"
              >Booking List</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-overlay>
    <b-alert
      :show="alertSettings.dismissCountDown"
      class="position-fixed fixed-top m-0 rounded-0 text-center"
      style="z-index: 2000;"
      :variant="alertSettings.alertVariant"
      dismissible
      @dismiss-count-down="countDownChanged"
    >
      <!--        <div v-html="alertSettings.title"></div>-->
      <div v-html="alertSettings.alertMsg"></div>
    </b-alert>

    <b-modal
      id="issue-ticket-confirm-modal"
      v-model="isIssueTicketConfirmModalVisible"
      centered
      hide-footer
      title="Issue ticket application"
    >
      <div class="my-1">
        Total Amount:
        <span class="text-danger">
          <b>
            <span class="mr-1">
              {{ sellingCurrencyCode }}
            </span>
            {{ bookedTotalPrice }}
          </b>
        </span>
      </div>

      <div>
        <p>
          <input v-model="isIssueTicketAgreed" type="checkbox" class="mr-2" />
          I agree to make payment and issue ticket by clicking "Issue Ticket"
          button
        </p>
      </div>
      <div class="d-flex justify-content-between">
        <b-button @click="hideIssueTicketModal" variant="secondary">
          Cancel
        </b-button>

        <b-button
          @click="checkOut"
          variant="primary"
          :disabled="!isIssueTicketAgreed"
        >
          Issue Ticket
        </b-button>
      </div>
    </b-modal>
  </Layout>
</template>

<style scoped></style>
