import axios from "../utils/axiosUtils"

export default {
	loginFromSSO() {
		let url = "/api/v1/account/authentication/keycloak?forwardToFailedUrl=http://localhost:8080/login&forwardToUrl=http://localhost:8080/rail"
		return axios.post(url, {
			headers: {
				// Overwrite Axios's automatically set Content-Type
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
	},

    login(formData) {
        let url = "/api/v1/account/authentication"
        return axios.post(url,formData, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    },

    loginStatus() {
        let url = "/api/v1/account/login-status"
        return axios.post(url, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })
    },

    logOut() {
        let url = "/api/v1/account/exit"
        return axios.post(url,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })
    },
    logOutB2B() {
        let url = "/api/v2/b2b/security/login/exit"
        return axios.post(url,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })
    },
    changePassword(payload) {
        //保证对象是Json字符串
        let url = "/api/v1/b2b/user-manage/users/"+ payload.username +"/password"
        return axios.put(url, payload.newPasswordRQ ,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })
    },
    profileModifyApi(payload) {
        //保证对象是Json字符串
        let request = ''
        request = JSON.stringify(payload.profileModifyRQ);
        return axios.post('/api/v1/account/modification', request,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })
    },

    //account list #TODO
    // getAccounts () {
    //     let url = '/api/v1/b2b/party/party/parties/1/employeebasicinfos'
    //     return axios.get(url, {
    //         headers: {
    //             // Overwrite Axios's automatically set Content-Type
    //             'Content-Type': 'application/json'
    //         }
    //     })
    // },
    getCapcha(){

        
        let url = '/api/v1/b2b/security/login/captcha'
        return axios.get(url, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })
    },
    getAccounts () {
        let url = '/api/v1/b2b/user-manage/employeebasicinfos'
        return axios.get(url, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })
    },
    
    getOwnInfo() {
        let url = '/api/v1/b2b/user-manage/own-info'  
        return axios.get(url, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })  
    },

    getAllRoles(userLoginId) {
        let url = '/api/v1/b2b/security/login/userlogins/'+ userLoginId +'/securitygroups'
        return axios.get(url, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })
    },

    // addNewAccount(addNewAccountRQ) {
    //     let url = '/api/v1/b2b/security/login/userlogins'
    //     return axios.post(url, addNewAccountRQ, {
    //         headers: {
    //             // Overwrite Axios's automatically set Content-Type
    //             'Content-Type': 'application/json'
    //         }
    //     })
    // },
    
    addNewAccount(addNewAccountRQ) {
        let url = '/api/v1/b2b/user-manage/users'
        return axios.post(url, addNewAccountRQ, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })
    },

    getPermissionsById(userLoginId) {
        let url = '/api/v1/b2b/security/login/userlogins/'+ userLoginId +'/permissions'
        return axios.get(url, {
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })
    },
	
	getUserInfoByUsername(username){
		let url = '/api/v1/b2b/user-manage/users?username='+username
		return axios.get(url, {
			headers: {
				// Overwrite Axios's automatically set Content-Type
				'Content-Type': 'application/json'
			}
		})
	},

	updateUserInfo(username){
		let url = '/api/v1/b2b/user-manage/users?username='+username
		return axios.get(url, {
			headers: {
				// Overwrite Axios's automatically set Content-Type
				'Content-Type': 'application/json'
			}
		})
	},

}