import DateUtils from "./DateUtils";
import dayjs from "dayjs";
/**
 *
 * @param {*} payload
 * @returns
 */
function getSearchRQ(searchInputValues, isRoundTrip) {
  let searchRQ = {
    originDestinations: [
      {
        departureDateTime: "",
        originLocation: {
          codeContext: "",
          locationCode: ""
        },
        destinationLocation: {
          codeContext: "",
          locationCode: ""
        }
      }
    ],
    passengerTypes: []
  };

  let inboundOriginDestination = {
    departureDateTime: "",
    originLocation: {
      codeContext: "",
      locationCode: ""
    },
    destinationLocation: {
      codeContext: "",
      locationCode: ""
    }
  };

  let departureDateTime =
    searchInputValues.departureDate + "T" + searchInputValues.departureTime;
  let originLocationCode = searchInputValues.fromPlace;
  let destinationLocationCode = searchInputValues.toPlace;
  let originLocationType = searchInputValues.fromPlaceType;
  let destinationLocationType = searchInputValues.toPlaceType;

  searchRQ.originDestinations[0].departureDateTime = departureDateTime;
  searchRQ.originDestinations[0].originLocation.locationCode = originLocationCode;
  searchRQ.originDestinations[0].destinationLocation.locationCode = destinationLocationCode;
  searchRQ.originDestinations[0].originLocation.codeContext = originLocationType;
  searchRQ.originDestinations[0].destinationLocation.codeContext = destinationLocationType;

  for (let i = 0; i < searchInputValues.adult; i++) {
    searchRQ.passengerTypes.push({ passengerDetails: [{ age: 31 }] });
  }
  for (let i = 0; i < searchInputValues.youth; i++) {
    searchRQ.passengerTypes.push({
      passengerDetails: [{ age: searchInputValues.youthAges[i] }.age]
    });
  }
  for (let i = 0; i < searchInputValues.senior; i++) {
    searchRQ.passengerTypes.push({ passengerDetails: [{ age: 62 }] });
  }

  let returnDateTime =
    searchInputValues.returnDate + "T" + searchInputValues.returnTime;
  inboundOriginDestination.departureDateTime = returnDateTime;
  inboundOriginDestination.originLocation.locationCode = destinationLocationCode;
  inboundOriginDestination.destinationLocation.locationCode = originLocationCode;
  inboundOriginDestination.originLocation.codeContext = destinationLocationType;
  inboundOriginDestination.destinationLocation.codeContext = originLocationType;

  //搜索往返票
  if (isRoundTrip) {
    searchRQ.originDestinations.push(inboundOriginDestination);
  }

  return searchRQ;
}

function getPassSearchRQ(passCriteria) {
  let passSearchRQ = {
    fareQuoteCriteriaList: [
      {
        originDestinationInformation: {
          departureDateTime: passCriteria.validFrom,
          originLocation: {
            locationCode: passCriteria.country
          },
          destinationLocation: {
            locationCode: passCriteria.country
          }
        },
        tripType: "Circle"
      }
    ],
    passengerCategories: []
  };

  for (let i = 0; i < passCriteria.adult; i++) {
    passSearchRQ.passengerCategories.push({
      age: 30
    });
  }
  for (let i = 0; i < passCriteria.senior; i++) {
    passSearchRQ.passengerCategories.push({
      age: 62
    });
  }
  for (let i = 0; i < passCriteria.youthAges.length; i++) {
    passSearchRQ.passengerCategories.push({
      age: passCriteria.youthAges[i].age
    });
  }
  console.info(JSON.stringify(passSearchRQ));
  return passSearchRQ;
}

/**
 * 了解数据模型请在doc文件夹下，查找trainTicketResults.js
 * @param {*} trainTicketsRS
 * @returns groupedResults
 */
function getTrainTicketResults(trainTicketsRS, searchInputValues) {
  let isRoundTrip = false;
  let adultQuantity = searchInputValues.adult;
  let youthQuantity = searchInputValues.youth;
  let seniorQuantity = searchInputValues.senior;
  if (searchInputValues.returnDate != "") {
    isRoundTrip = true;
  }

  let ungroupedOutboundResults = [];
  let ungroupedInboundResults = [];

  if (trainTicketsRS.originDestinationInformation.length == 0) {
    return { outboundTrainTicketResults: [] };
  }

  trainTicketsRS.originDestinationInformation.forEach(odiItem => {
    odiItem.originDestinationOptions.forEach(option => {
      let ticket = {
        train: "", //多行程段 Eurostar 1234/Eurostar 4567
        trains: [],
        trainIdentity: "", //多行程段 将trainNumber串起来作为该行程的同一标识，如：1234_4567
        routing: "",
        routings: [],
        departureDateTime: "", //第一个segment的departureDateTime
        arrivalDateTime: "", //最后一个segment的arrivalDateTime
        departureTime: "",
        arrivalTime: "",
        arriveNextDate: "",
        depAndArriTimeStr: "", //出发到达日期字符串，显示在页面的Departure Time/Arrival Time字段
        duration: "", //最后一个segment的arrivalTime减去第一个segment的departureTime
        direct: "",
        originStation: "",
        provider: [], //operatingCompany
        destinationStation: "",
        rph: "", //车票标识码，提交订单时需要
        // price
        comfortType: "",
        flexibilityType: "",
        seatClass: "",
        seatClassDescription: "",
        currencyCode: "",
        totalAmount: 0,
        sellingCurrencyCode: "",
        sellingTotalAmount: 0,

        // priceList object { passengerType: "ADULT", price: 76.33},
        // 有多少个乘客就有多少个object，对应不同的adult/youth/senior和price
        priceBreakdown: [],
        expireIn: "",
        expiredDateTime: "",
        conditions: "",
        trainSegments: [],
        inboundRphs: []
      };

      //ticket 的识别码
      ticket.rph = option.rph;

      let journeySegmentsQuantity = option.journeySegments.length;
      let passengerQuantity = adultQuantity + youthQuantity + seniorQuantity;

      for (let i = 0; i < journeySegmentsQuantity; i++) {
        if (i == 0) {
          ticket.originStation =
            option.journeySegments[
              i
            ].trainSegment.departureStation.details.stationName;
        }
        if (i == journeySegmentsQuantity - 1) {
          ticket.destinationStation =
            option.journeySegments[
              i
            ].trainSegment.arrivalStation.details.stationName;
        }

        if (
          !ticket.routings.includes(
            option.journeySegments[i].trainSegment.departureStation.details
              .stationName
          )
        ) {
          ticket.routings.push(
            option.journeySegments[i].trainSegment.departureStation.details
              .stationName
          );
        }
        if (
          !ticket.routings.includes(
            option.journeySegments[i].trainSegment.arrivalStation.details
              .stationName
          )
        ) {
          ticket.routings.push(
            option.journeySegments[i].trainSegment.arrivalStation.details
              .stationName
          );
        }

        //将journeySegments 转成输出结果中的trainSegments，在ticketDetails页面要展示
        let trainSegment = {};
        if (
          option.journeySegments[i].trainSegment.trainInfo.train.networkCode
            .code != null
        ) {
          trainSegment.networkCode =
            option.journeySegments[
              i
            ].trainSegment.trainInfo.train.networkCode.code;
        } else {
          trainSegment.networkCode = "";
        }

        if (
          option.journeySegments[i].trainSegment.trainInfo.train.trainNumber !=
          null
        ) {
          trainSegment.trainNumber =
            option.journeySegments[i].trainSegment.trainInfo.train.trainNumber;
        } else {
          trainSegment.trainNumber = "";
        }

        trainSegment.departureStation =
          option.journeySegments[
            i
          ].trainSegment.departureStation.details.stationName;
        trainSegment.arrivalStation =
          option.journeySegments[
            i
          ].trainSegment.arrivalStation.details.stationName;

        // smaple data: dateTimeStr = "2022-11-29T12:00:00"
        let departureDateTimeStr =
          option.journeySegments[i].trainSegment.departureDateTime;
        let arrivalDateTimeStr =
          option.journeySegments[i].trainSegment.arrivalDateTime;
        if (departureDateTimeStr != null) {
          trainSegment.departureDate = DateUtils.formatDate(
            departureDateTimeStr,
            "YYYY-MM-DD"
          );
          trainSegment.departureTime = DateUtils.formatDate(
            departureDateTimeStr,
            "HH:mm"
          );
        } else {
          trainSegment.departureDate = "";
          trainSegment.departureTime = "";
        }
        if (arrivalDateTimeStr != null) {
          trainSegment.arrivalDate = DateUtils.formatDate(
            arrivalDateTimeStr,
            "YYYY-MM-DD"
          );
          trainSegment.arrivalTime = DateUtils.formatDate(
            arrivalDateTimeStr,
            "HH:mm"
          );
        } else {
          trainSegment.arrivalDate = "";
          trainSegment.arrivalTime = "";
        }
        if (
          trainSegment.arrivalTime != "" &&
          trainSegment.departureTime != ""
        ) {
          trainSegment.duration = DateUtils.getTrainDuration(
            arrivalDateTimeStr,
            departureDateTimeStr
          );
        } else {
          trainSegment.duration = "";
        }

        if (i == 0 && departureDateTimeStr != null) {
          ticket.departureDateTime = departureDateTimeStr;
          ticket.departureTime = DateUtils.formatDate(
            departureDateTimeStr,
            "HH:mm"
          );
        }
        if (i == journeySegmentsQuantity - 1 && arrivalDateTimeStr != null) {
          ticket.arrivalDateTime = arrivalDateTimeStr;
          ticket.arrivalTime = DateUtils.formatDate(
            arrivalDateTimeStr,
            "HH:mm"
          );
        }

        //将多段行程的trainNo连成一串，以便后面进行同一行程分组
        if (ticket.trainIdentity == "") {
          ticket.trainIdentity = trainSegment.trainNumber;
        } else {
          ticket.trainIdentity =
            ticket.trainIdentity + "_" + trainSegment.trainNumber;
        }

        ticket.trains.push(
          trainSegment.networkCode + " " + trainSegment.trainNumber
        );
        ticket.trainSegments.push(trainSegment);

        //列出的provider
        let operatingCompany =
          option.journeySegments[i].trainSegment.operatingCompany.code;
        if (
          operatingCompany != null &&
          operatingCompany != undefined &&
          operatingCompany != ""
        ) {
          let hasIndex = ticket.provider.findIndex(item => {
            return item === operatingCompany;
          });
          if (hasIndex == -1) {
            ticket.provider.push(operatingCompany);
          }
        }
      }

      if (ticket.departureDateTime != "" && ticket.arrivalDateTime != "") {
        //同一天
        if (
          DateUtils.getDiffDays(
            ticket.arrivalDateTime,
            ticket.departureDateTime,
            "days"
          ) != 0
        ) {
          let day = DateUtils.getDiffDays(
            ticket.arrivalDateTime,
            ticket.departureDateTime,
            "day"
          );
          ticket.arriveNextDate = "(+" + day + ")";
        }

        ticket.duration = DateUtils.getTrainDuration(
          ticket.arrivalDateTime,
          ticket.departureDateTime
        );
      }

      if (journeySegmentsQuantity > 1) ticket.direct = "No";
      else ticket.direct = "Yes";

      //价钱详情数据建模 带selling开头的数据是转换成HKD，没有带的是原EUR
      ticket.currencyCode = option.pricingList[0].price.currencyCode;
      ticket.sellingCurrencyCode =
        option.pricingList[0].price.sellingCurrencyCode;
      ticket.totalAmount = option.pricingList[0].price.amount;
      ticket.sellingTotalAmount = option.pricingList[0].price.sellingAmount;

      //提取车票的座次等级
      let discountClasses =
        option.pricingList[0].priceBreakdown.discountClasses;
      for (let j = 0; j < discountClasses.length; j++) {
        if (discountClasses[j].codeContext === "COMFORT") {
          ticket.comfortType = discountClasses[j].code;
        }
        if (discountClasses[j].codeContext === "FLEXIBILITY") {
          ticket.flexibilityType = decodeFlexibility(discountClasses[j].code);
        }
      }

      //提取座位级别
      let accommodationAdjustment =
        option.pricingList[0].priceBreakdown.accommodationAdjustment;
      ticket.seatClass = accommodationAdjustment.name;
      ticket.seatClassDescription = accommodationAdjustment.description;

      let termAndConditions =
        option.pricingList[0].priceBreakdown.termAndConditions;
      let fareQualifierAdjustments =
        option.pricingList[0].priceBreakdown.fareQualifierAdjustments;

      //合并
      //合并相同类型乘客的数额
      let passengerPriceTem = fareQualifierAdjustments.reduce(
        (total, cur, index) => {
          let hasValue = total.findIndex(current => {
            return current.code === cur.code;
          });
          //第一次发现这个类型，放到数组，并取得对应这个乘客类型的termAndConditions
          if (hasValue === -1) {
            if (cur.code === "ADULT") {
              cur.quantity = adultQuantity;
            }
            if (cur.code === "YOUTH") {
              cur.quantity = youthQuantity;
            }
            if (cur.code === "SENIOR") {
              cur.quantity = seniorQuantity;
            }
            cur.conditions = [];
            for (
              let i = 0;
              i < termAndConditions.length / passengerQuantity;
              i++
            ) {
              if (!termAndConditions[index + i * passengerQuantity].description)
                continue;
              cur.conditions.push(
                termAndConditions[index + i * passengerQuantity].description
                  .stringValue
              );
            }
            total.push(cur);
          } else {
            //已经存在，则合并,数额相加：原货币单位EUR和selling货币单位HKD
            total[hasValue].amount += cur.amount;
            total[hasValue].sellingAmount += cur.sellingAmount;
          }
          return total;
        },
        []
      );

      ticket.priceBreakdown = passengerPriceTem;
      if (option.remarks != null) {
        option.remarks.forEach(rItem => {
          ticket.inboundRphs.push(rItem.code); //筛选可选回程票
        });
      }

      //计算是否过期
      let tpaExtensions = option.pricingList[0].tpa_Extensions;

      // ticket.expireIn = ''
      ticket.expiredDateTime = "";
      // ticket.expired = false
      try {
        let valueMapTem = tpaExtensions.valueMap;
        if (
          valueMapTem.PROVIDER_BOOKING_EXPIRED_DATETIME != null &&
          valueMapTem.PROVIDER_BOOKING_EXPIRED_DATETIME !== undefined
        ) {
          ticket.expiredDateTime =
            valueMapTem.PROVIDER_BOOKING_EXPIRED_DATETIME;
        }
      } catch {
        console.info("Some data errors");
      }

      // if(ticket.expiredDateTime != ''){
      //     let expiredDateTimeStr = ticket.expiredDateTime.slice(0, 19)
      //     ticket.expiredDateTime = DateUtils.formatDate(expiredDateTimeStr, "YYYY-MM-DD HH:mm:ss")
      //     ticket.expireIn = DateUtils.getExpireInMinute(expiredDateTimeStr)
      // }

      // if(ticket.expireIn <= 0){
      //     ticket.expired = true
      // }

      //按照Adult Senior Youth排序
      let orderPassengerType = ["ADULT", "SENIOR", "YOUTH"];
      ticket.priceBreakdown.sort((a, b) => {
        return (
          orderPassengerType.indexOf(a.code) -
          orderPassengerType.indexOf(b.code)
        );
      });

      //根据后台出发地和目的地判断outbound、inbound
      if (odiItem.originLocation.locationCode == searchInputValues.fromPlace) {
        ungroupedOutboundResults.push(ticket);
      }
      if (odiItem.originLocation.locationCode == searchInputValues.toPlace) {
        ungroupedInboundResults.push(ticket);
      }
    });
  });

  //每一个价钱就是一条originDestinationOption，因为同一班车会有多个价钱，
  //计算出每个价钱的ticket后，还需要按照前端的展示效果，按同一班次（即trainNumber相同）分组tickets，
  //按照comfortType分tab，每个tab下面按flexibility列价钱。
  //results is grouped for prices by train
  let groupedInboundResults = [];
  let inboundTempObj = {};

  ungroupedInboundResults.forEach(ticket => {
    if (inboundTempObj["train_" + ticket.trainIdentity]) {
      inboundTempObj["train_" + ticket.trainIdentity].push(ticket);
    } else {
      // 没有就新增一个trainIdentity的分组
      inboundTempObj["train_" + ticket.trainIdentity] = [ticket];
    }
  });

  for (let key in inboundTempObj) {
    //ticket按价钱，从小到大排序
    inboundTempObj[key].sort((ticketA, ticketB) => {
      return ticketA.totalAmount - ticketB.totalAmount;
    });

    let ticketGroup = {};
    //单独拿出公共信息，用于页面的filter
    ticketGroup.trains = inboundTempObj[key][0].trains;
    ticketGroup.trainIdentity = inboundTempObj[key][0].trainIdentity;
    ticketGroup.originStation = inboundTempObj[key][0].originStation;
    ticketGroup.destinationStation = inboundTempObj[key][0].destinationStation;
    ticketGroup.routings = inboundTempObj[key][0].routings;
    ticketGroup.departureDateTime = inboundTempObj[key][0].departureDateTime;
    ticketGroup.arrivalDateTime = inboundTempObj[key][0].arrivalDateTime;
    ticketGroup.departureTime = inboundTempObj[key][0].departureTime;
    ticketGroup.arrivalTime = inboundTempObj[key][0].arrivalTime;
    ticketGroup.arriveNextDate = inboundTempObj[key][0].arriveNextDate;
    ticketGroup.duration = inboundTempObj[key][0].duration;
    ticketGroup.direct = inboundTempObj[key][0].direct;
    ticketGroup.provider = inboundTempObj[key][0].provider;
    //原货币单位EUR和selling货币单位HKD
    ticketGroup.currencyCode = inboundTempObj[key][0].currencyCode;
    ticketGroup.bestPrice = inboundTempObj[key][0].totalAmount;
    ticketGroup.sellingCurrencyCode =
      inboundTempObj[key][0].sellingCurrencyCode;
    ticketGroup.sellingBestPrice = inboundTempObj[key][0].sellingTotalAmount;

    //找出同班火车所有comfortType，并按前端展示要求排序
    ticketGroup.comfortTypeTabs = [];
    inboundTempObj[key].forEach(ticket => {
      if (!ticketGroup.comfortTypeTabs.includes(ticket.comfortType)) {
        ticketGroup.comfortTypeTabs.push(ticket.comfortType);
      }
    });
    let orderTabs = ["STANDARD", "COMFORT", "PREMIER"]; //用于将tabs按照Standard Comfort Premier排序
    ticketGroup.comfortTypeTabs.sort((a, b) => {
      return orderTabs.indexOf(a) - orderTabs.indexOf(b);
    });

    //分好组的ticket数组，放在key为tickets的项里
    ticketGroup.tickets = inboundTempObj[key];

    groupedInboundResults.push(ticketGroup);
  }

  //每一个价钱就是一条originDestinationOption，因为同一班车会有多个价钱，
  //计算出每个价钱的ticket后，还需要按照前端的展示效果，按同一班次（即trainNumber相同）分组tickets，
  //按照comfortType分tab，每个tab下面按flexibility列价钱。
  //results is grouped for prices by train
  let groupedOutboundResults = [];
  let outboundTempObj = {};

  ungroupedOutboundResults.forEach(ticket => {
    if (outboundTempObj["train_" + ticket.trainIdentity]) {
      outboundTempObj["train_" + ticket.trainIdentity].push(ticket);
    } else {
      // 没有就新增一个trainIdentity的分组
      outboundTempObj["train_" + ticket.trainIdentity] = [ticket];
    }
  });

  for (let key in outboundTempObj) {
    //ticket按价钱，从小到大排序
    outboundTempObj[key].sort((ticketA, ticketB) => {
      return ticketA.totalAmount - ticketB.totalAmount;
    });

    let ticketGroup = {};
    //单独拿出公共信息，用于页面的filter
    ticketGroup.trains = outboundTempObj[key][0].trains;
    ticketGroup.trainIdentity = outboundTempObj[key][0].trainIdentity;
    ticketGroup.originStation = outboundTempObj[key][0].originStation;
    ticketGroup.destinationStation = outboundTempObj[key][0].destinationStation;
    ticketGroup.routings = outboundTempObj[key][0].routings;
    ticketGroup.departureDateTime = outboundTempObj[key][0].departureDateTime;
    ticketGroup.arrivalDateTime = outboundTempObj[key][0].arrivalDateTime;
    ticketGroup.departureTime = outboundTempObj[key][0].departureTime;
    ticketGroup.arrivalTime = outboundTempObj[key][0].arrivalTime;
    ticketGroup.arriveNextDate = outboundTempObj[key][0].arriveNextDate;
    ticketGroup.duration = outboundTempObj[key][0].duration;
    ticketGroup.direct = outboundTempObj[key][0].direct;
    ticketGroup.provider = outboundTempObj[key][0].provider;
    //原货币单位EUR和selling货币单位HKD
    ticketGroup.currencyCode = outboundTempObj[key][0].currencyCode;
    ticketGroup.bestPrice = outboundTempObj[key][0].totalAmount;
    ticketGroup.sellingCurrencyCode =
      outboundTempObj[key][0].sellingCurrencyCode;
    ticketGroup.sellingBestPrice = outboundTempObj[key][0].sellingTotalAmount;

    //找出同班火车所有comfortType，并按前端展示要求排序
    ticketGroup.comfortTypeTabs = [];
    outboundTempObj[key].forEach(ticket => {
      if (!ticketGroup.comfortTypeTabs.includes(ticket.comfortType)) {
        ticketGroup.comfortTypeTabs.push(ticket.comfortType);
      }
    });
    let orderTabs = ["STANDARD", "COMFORT", "PREMIER"]; //用于将tabs按照Standard Comfort Premier排序
    ticketGroup.comfortTypeTabs.sort((a, b) => {
      return orderTabs.indexOf(a) - orderTabs.indexOf(b);
    });

    //分好组的ticket数组，放在key为tickets的项里
    ticketGroup.tickets = outboundTempObj[key];

    groupedOutboundResults.push(ticketGroup);
  }

  //最后向页面返回按train分好组的tickets
  if (isRoundTrip) {
    return {
      outboundTrainTicketResults: groupedOutboundResults,
      inboundTrainTicketResults: groupedInboundResults
    };
  } else {
    return { outboundTrainTicketResults: groupedOutboundResults };
  }
}

function getFilteredInboundTickets(
  copyOfInboundResults,
  selectedOutboundTicket
) {
  copyOfInboundResults.forEach(ticketGroup => {
    let filteredTickets = ticketGroup.tickets.filter(inbtTicket => {
      return selectedOutboundTicket.inboundRphs.includes(inbtTicket.rph);
    });
    console.info(filteredTickets);
    ticketGroup.tickets = filteredTickets;
  });

  return copyOfInboundResults;
}

function getDepartureStationFilterOptions(allTickets) {
  let options = [];
  if (allTickets == undefined) return options;
  allTickets.forEach(item => {
    let option = { value: "", text: "" };
    let hasIndex = options.findIndex(opt => {
      return opt.value == item.originStation;
    });

    if (hasIndex == -1) {
      option.value = item.originStation;
      option.text = item.originStation;
      options.push(option);
    }
  });

  return options;
}

function getArrivalStationFilterOptions(allTickets) {
  let options = [];
  if (allTickets == undefined) return options;
  allTickets.forEach(item => {
    let option = { value: "", text: "" };
    let hasIndex = options.findIndex(opt => {
      return opt.value == item.destinationStation;
    });

    if (hasIndex == -1) {
      option.value = item.destinationStation;
      option.text = item.destinationStation;
      options.push(option);
    }
  });

  return options;
}

function getProviderOptions(allTickets) {
  let options = [];
  let optTemp = [];
  if (allTickets == undefined) return options;
  allTickets.forEach(item => {
    item.provider.forEach(providerItem => {
      if (!optTemp.includes(providerItem)) {
        optTemp.push(providerItem);
      }
    });
  });

  optTemp.forEach(item => {
    let option = { value: "", text: "" };
    option.value = item;
    option.text = item;
    options.push(option);
  });

  return options;
}

function getSortAndFilteredTickets(allTickets, sortCriteria, filterCriteria) {
  let sortAndFilteredTickets = Object.assign([], allTickets);
  //sorting
  if (sortCriteria.sortByDuration == 1) {
    sortAndFilteredTickets.sort((itemA, itemB) => {
      return compareDuration(itemA.duration, itemB.duration);
    });
  }

  if (sortCriteria.sortByPrice == 1) {
    sortAndFilteredTickets.sort((itemA, itemB) => {
      return itemA.bestPrice - itemB.bestPrice;
    });
  }

  if (filterCriteria.filterByDirect == 1) {
    sortAndFilteredTickets = sortAndFilteredTickets.filter(item => {
      return item.direct == "Yes";
    });
  }
  if (filterCriteria.departingStationSelected.length > 0) {
    sortAndFilteredTickets = sortAndFilteredTickets.filter(item => {
      return filterCriteria.departingStationSelected.includes(
        item.originStation
      );
    });
  }

  if (filterCriteria.arrivingStationSelected.length > 0) {
    sortAndFilteredTickets = sortAndFilteredTickets.filter(item => {
      return filterCriteria.arrivingStationSelected.includes(
        item.destinationStation
      );
    });
  }

  if (filterCriteria.providerSelected.length > 0) {
    sortAndFilteredTickets = sortAndFilteredTickets.filter(item => {
      return hasProvider(filterCriteria.providerSelected, item.provider);
    });
  }
  return sortAndFilteredTickets;
}

function getP2POptions(reservation) {
  let vendorMessages = reservation.itinerary.vendorMessages;
  let p2pOptions = [];

  if (vendorMessages != null && vendorMessages !== undefined) {
    vendorMessages.forEach(vendorMessageItem => {
      vendorMessageItem.subSections.forEach(element => {
        let p2pOption = {};
        if (element.subCode === "point-to-point-option") {
          element.paragraphs[0].listItems[0].subSections.forEach(item => {
            if (item.subCode === "label") {
              p2pOption.label = item.paragraphs[0].text;
            }
            if (item.subCode === "title") {
              p2pOption.title = item.paragraphs[0].text;
            }
            if (item.subCode === "summary") {
              p2pOption.summary = item.paragraphs[0].text;
            }
            if (item.subCode === "description") {
              p2pOption.description = item.paragraphs[0].text;
            }
          });
          p2pOptions.push(p2pOption);
        }
      });
    });
  }

  return p2pOptions;
}

function getLeadTravelerForm(reservation) {
  let leadTraveler = {};
  let tpaExtensions = reservation.tpaExtensions;
  let leadTravelerInformationRequired = "";

  if (tpaExtensions != null && tpaExtensions != undefined) {
    if (
      tpaExtensions.valueMap != null &&
      tpaExtensions.valueMap !== undefined
    ) {
      if (
        tpaExtensions.valueMap.leadTravelerInformationRequired != null &&
        tpaExtensions.valueMap.leadTravelerInformationRequired !== undefined
      ) {
        leadTravelerInformationRequired =
          tpaExtensions.valueMap.leadTravelerInformationRequired;
      }
    }
  }

  if (leadTravelerInformationRequired.includes("PHONE_NUMBER")) {
    leadTraveler.countryCode = "";
    leadTraveler.telephoneNumber = "";
  }
  if (leadTravelerInformationRequired.includes("EMAIL")) {
    leadTraveler.email = "";
  }
  if (leadTravelerInformationRequired.includes("TITLE")) {
    leadTraveler.title = "";
  }
  if (leadTravelerInformationRequired.includes("FIRST_NAME")) {
    leadTraveler.firstName = "";
  }
  if (leadTravelerInformationRequired.includes("LAST_NAME")) {
    leadTraveler.lastName = "";
  }
  if (leadTravelerInformationRequired.includes("DATE_OF_BIRTH")) {
    leadTraveler.birthDate = "";
  }
  if (leadTravelerInformationRequired.includes("COUNTRY_OF_RESIDENCE")) {
    leadTraveler.addressName = "";
    leadTraveler.address = "";
  }
  if (leadTravelerInformationRequired.includes("PASSPORT")) {
    leadTraveler.docID = "";
    leadTraveler.expireDate = "";
    leadTraveler.docHolderNationality = "";
    leadTraveler.docHolderNationalityName = "";
  }

  return leadTraveler;
}

function getTicketTravelersFormArray(reservation) {
  let tpaExtensions = reservation.tpaExtensions;
  // let leadTravelerInformationRequired = ""
  let defaultTravelerInformationRequired = "";
  let passengerInfos = reservation.passengerInfos;

  if (tpaExtensions != null && tpaExtensions != undefined) {
    if (
      tpaExtensions.valueMap != null &&
      tpaExtensions.valueMap !== undefined
    ) {
      // if(tpaExtensions.valueMap.leadTravelerInformationRequired != null && tpaExtensions.valueMap.leadTravelerInformationRequired !== undefined){
      //     leadTravelerInformationRequired = tpaExtensions.valueMap.leadTravelerInformationRequired
      // }
      if (
        tpaExtensions.valueMap.defaultTravelerInformationRequired != null &&
        tpaExtensions.valueMap.defaultTravelerInformationRequired !== undefined
      ) {
        defaultTravelerInformationRequired =
          tpaExtensions.valueMap.defaultTravelerInformationRequired;
      }
    }
  }

  // let isLeadTravelerSet = false
  let travelers = [];
  passengerInfos.forEach(item => {
    let traveler = {
      rph: "",
      fromAge: null,
      toAge: null,
      passengerQualifyingInfo: [],
      passengerDetails: [],
      passenger: {}
    };
    let searchAge = item.passengerDetails[0].age;
    traveler.age = searchAge;
    traveler.rph = item.rph;
    traveler.fromAge = item.fromAge;
    traveler.toAge = item.toAge;
    traveler.passengerQualifyingInfo = item.passengerQualifyingInfo;

    traveler.passenger.code = item.passengerQualifyingInfo[0].codeContext;
    if (defaultTravelerInformationRequired.includes("TITLE")) {
      traveler.passenger.title = "";
    }
    if (defaultTravelerInformationRequired.includes("FIRST_NAME")) {
      traveler.passenger.firstName = "";
    }
    if (defaultTravelerInformationRequired.includes("LAST_NAME")) {
      traveler.passenger.lastName = "";
    }
    if (defaultTravelerInformationRequired.includes("DATE_OF_BIRTH")) {
      traveler.passenger.birthDate = "";
    }
    if (defaultTravelerInformationRequired.includes("COUNTRY_OF_RESIDENCE")) {
      traveler.passenger.addressName = "";
      traveler.passenger.address = "";
    }
    if (defaultTravelerInformationRequired.includes("PASSPORT")) {
      traveler.passenger.docID = "";
      traveler.passenger.expireDate = "";
      traveler.passenger.docHolderNationality = "";
      traveler.passenger.docHolderNationalityName = "";
    }
    if (defaultTravelerInformationRequired.includes("EMAIL")) {
      traveler.passenger.email = "";
    }
    if (defaultTravelerInformationRequired.includes("PHONE_NUMBER")) {
      traveler.passenger.countryCode = "";
      traveler.passenger.telephoneNumber = "";
    }

    // if(!isLeadTravelerSet){
    //     if(item.passengerQualifyingInfo[0].codeContext == "ADULT" || item.passengerQualifyingInfo[0].codeContext == "SENIOR"){
    //         if(leadTravelerInformationRequired.includes("PHONE_NUMBER")){
    //             traveler.passenger.countryCode = ""
    //             traveler.passenger.telephoneNumber = ""
    //         }
    //         if(leadTravelerInformationRequired.includes("EMAIL")){
    //             traveler.passenger.email = ""
    //         }
    //         if(leadTravelerInformationRequired.includes("TITLE")){
    //             traveler.passenger.title = ""
    //         }
    //         if(leadTravelerInformationRequired.includes("FIRST_NAME")){
    //             traveler.passenger.firstName = ""
    //         }
    //         if(leadTravelerInformationRequired.includes("LAST_NAME")){
    //             traveler.passenger.lastName = ""
    //         }
    //         if(leadTravelerInformationRequired.includes("DATE_OF_BIRTH")){
    //             traveler.passenger.birthDate = ""
    //         }
    //         if(leadTravelerInformationRequired.includes("COUNTRY_OF_RESIDENCE")){
    //             traveler.passenger.addressName = ""
    //             traveler.passenger.address = ""
    //         }
    //         if(leadTravelerInformationRequired.includes("PASSPORT")){
    //             traveler.passenger.docID = ""
    //             traveler.passenger.expireDate = ""
    //             traveler.passenger.docHolderNationality = ""
    //             traveler.passenger.docHolderNationalityName = ""
    //         }
    //         traveler.passenger.leadTraveler = true
    //         isLeadTravelerSet = true
    //     }
    // }

    travelers.push(traveler);
  });

  //如果没有大人或者老年人，选第一个为leader信息
  // if(!isLeadTravelerSet){
  //     if(leadTravelerInformationRequired.includes("PHONE_NUMBER")){
  //         travelers[0].passenger.countryCode = ""
  //         travelers[0].passenger.telephoneNumber = ""
  //     }
  //     if(leadTravelerInformationRequired.includes("EMAIL")){
  //         travelers[0].passenger.email = ""
  //     }
  //     if(leadTravelerInformationRequired.includes("TITLE")){
  //         travelers[0].passenger.title = ""
  //     }
  //     if(leadTravelerInformationRequired.includes("FIRST_NAME")){
  //         travelers[0].passenger.firstName = ""
  //     }
  //     if(leadTravelerInformationRequired.includes("LAST_NAME")){
  //         travelers[0].passenger.lastName = ""
  //     }
  //     if(leadTravelerInformationRequired.includes("DATE_OF_BIRTH")){
  //         travelers[0].passenger.birthDate = ""
  //     }
  //     if(leadTravelerInformationRequired.includes("COUNTRY_OF_RESIDENCE")){
  //         travelers[0].passenger.addressName = ""
  //         travelers[0].passenger.address = ""
  //     }
  //     if(leadTravelerInformationRequired.includes("PASSPORT")){
  //         travelers[0].passenger.docID = ""
  //         travelers[0].passenger.expireDate = ""
  //         travelers[0].passenger.docHolderNationality = ""
  //         travelers[0].passenger.docHolderNationalityName = ""
  //     }
  //     travelers[0].passenger.leadTraveler = true
  // }

  //按照大人、老人、青年人排序
  let orderPassengerType = ["ADULT", "SENIOR", "YOUTH"];
  travelers.sort((a, b) => {
    return (
      orderPassengerType.indexOf(a.passenger.code) -
      orderPassengerType.indexOf(b.passenger.code)
    );
  });

  return travelers;
}

function getTicketTravelersInfo(travelerFormValues) {
  let travelersInfo = [];
  travelerFormValues.forEach(element => {
    let passengerForm = element.passenger;
    let traveler = {};
    traveler.code = passengerForm.code;
    traveler.passenger =
      passengerForm.title +
      " " +
      passengerForm.firstName +
      " " +
      passengerForm.lastName;
    if (passengerForm.birthDate !== undefined) {
      traveler.birthDate = passengerForm.birthDate;
    }

    if (passengerForm.telephoneNumber !== undefined) {
      traveler.telephoneNumber = passengerForm.telephoneNumber;
    }
    if (passengerForm.email !== undefined) {
      traveler.email = passengerForm.email;
    }
    travelersInfo.push(traveler);
  });

  return travelersInfo;
}

function getTravelersFormArray(reservation) {
  let tpaExtensions = reservation.tpaExtensions;
  let leadTravelerInformationRequired = "";
  let defaultTravelerInformationRequired = "";
  let passengerInfos = reservation.passengerInfos;

  if (tpaExtensions != null && tpaExtensions != undefined) {
    if (
      tpaExtensions.valueMap != null &&
      tpaExtensions.valueMap !== undefined
    ) {
      if (
        tpaExtensions.valueMap.leadTravelerInformationRequired != null &&
        tpaExtensions.valueMap.leadTravelerInformationRequired !== undefined
      ) {
        leadTravelerInformationRequired =
          tpaExtensions.valueMap.leadTravelerInformationRequired;
      }
      if (
        tpaExtensions.valueMap.defaultTravelerInformationRequired != null &&
        tpaExtensions.valueMap.defaultTravelerInformationRequired !== undefined
      ) {
        defaultTravelerInformationRequired =
          tpaExtensions.valueMap.defaultTravelerInformationRequired;
      }
    }
  }

  let containAdult = false;
  passengerInfos.forEach(item => {
    if (item.passengerQualifyingInfo[0].codeContext == "ADULT") {
      containAdult = true;
    }
  });

  let isLeadTravelerSet = false;
  let travelers = [];
  passengerInfos.forEach(item => {
    let traveler = {
      rph: "",
      fromAge: null,
      toAge: null,
      passengerQualifyingInfo: [],
      passengerDetails: [],
      passenger: {}
    };
    let searchAge = item.passengerDetails[0].age;
    traveler.age = searchAge;

    traveler.rph = item.rph;
    traveler.fromAge = item.fromAge;
    traveler.toAge = item.toAge;
    traveler.passengerQualifyingInfo = item.passengerQualifyingInfo;

    traveler.passenger.code = item.passengerQualifyingInfo[0].codeContext;
    if (defaultTravelerInformationRequired.includes("TITLE")) {
      traveler.passenger.title = "";
    }
    if (defaultTravelerInformationRequired.includes("FIRST_NAME")) {
      traveler.passenger.firstName = "";
    }
    if (defaultTravelerInformationRequired.includes("LAST_NAME")) {
      traveler.passenger.lastName = "";
    }
    if (defaultTravelerInformationRequired.includes("DATE_OF_BIRTH")) {
      traveler.passenger.birthDate = dayjs(new Date()).subtract(searchAge, 'year').format("YYYY-MM-DD");
    }
    if (defaultTravelerInformationRequired.includes("COUNTRY_OF_RESIDENCE")) {
      traveler.passenger.addressName = "";
      traveler.passenger.address = "";
    }
    if (defaultTravelerInformationRequired.includes("PASSPORT")) {
      traveler.passenger.docID = "";
      traveler.passenger.expireDate = "";
      traveler.passenger.docHolderNationality = "";
      traveler.passenger.docHolderNationalityName = "";
    }
    if (defaultTravelerInformationRequired.includes("EMAIL")) {
      traveler.passenger.email = "";
    }
    if (defaultTravelerInformationRequired.includes("PHONE_NUMBER")) {
      traveler.passenger.countryCode = "";
      traveler.passenger.telephoneNumber = "";
    }

    if (!isLeadTravelerSet) {
      if (
        item.passengerQualifyingInfo[0].codeContext == "ADULT" ||
        (!containAdult &&
          item.passengerQualifyingInfo[0].codeContext == "SENIOR")
      ) {
        if (leadTravelerInformationRequired.includes("PHONE_NUMBER")) {
          traveler.passenger.countryCode = "";
          traveler.passenger.telephoneNumber = "";
        }
        if (leadTravelerInformationRequired.includes("EMAIL")) {
          traveler.passenger.email = "";
        }
        if (leadTravelerInformationRequired.includes("TITLE")) {
          traveler.passenger.title = "";
        }
        if (leadTravelerInformationRequired.includes("FIRST_NAME")) {
          traveler.passenger.firstName = "";
        }
        if (leadTravelerInformationRequired.includes("LAST_NAME")) {
          traveler.passenger.lastName = "";
        }
        if (leadTravelerInformationRequired.includes("DATE_OF_BIRTH")) {
          traveler.passenger.birthDate = "";
        }
        if (leadTravelerInformationRequired.includes("COUNTRY_OF_RESIDENCE")) {
          traveler.passenger.addressName = "";
          traveler.passenger.address = "";
        }
        if (leadTravelerInformationRequired.includes("PASSPORT")) {
          traveler.passenger.docID = "";
          traveler.passenger.expireDate = "";
          traveler.passenger.docHolderNationality = "";
          traveler.passenger.docHolderNationalityName = "";
        }
        traveler.passenger.leadTraveler = true;
        isLeadTravelerSet = true;
      }
    }

    travelers.push(traveler);
  });

  // 如果没有大人或者老年人，选第一个为leader信息
  if (!isLeadTravelerSet) {
    if (leadTravelerInformationRequired.includes("PHONE_NUMBER")) {
      travelers[0].passenger.countryCode = "";
      travelers[0].passenger.telephoneNumber = "";
    }
    if (leadTravelerInformationRequired.includes("EMAIL")) {
      travelers[0].passenger.email = "";
    }
    if (leadTravelerInformationRequired.includes("TITLE")) {
      travelers[0].passenger.title = "";
    }
    if (leadTravelerInformationRequired.includes("FIRST_NAME")) {
      travelers[0].passenger.firstName = "";
    }
    if (leadTravelerInformationRequired.includes("LAST_NAME")) {
      travelers[0].passenger.lastName = "";
    }
    if (leadTravelerInformationRequired.includes("DATE_OF_BIRTH")) {
      travelers[0].passenger.birthDate = "";
    }
    if (leadTravelerInformationRequired.includes("COUNTRY_OF_RESIDENCE")) {
      travelers[0].passenger.addressName = "";
      travelers[0].passenger.address = "";
    }
    if (leadTravelerInformationRequired.includes("PASSPORT")) {
      travelers[0].passenger.docID = "";
      travelers[0].passenger.expireDate = "";
      travelers[0].passenger.docHolderNationality = "";
      travelers[0].passenger.docHolderNationalityName = "";
    }
    travelers[0].passenger.leadTraveler = true;
  }

  //按照大人、老人、青年人排序
  let orderPassengerType = ["ADULT", "SENIOR", "YOUTH"];
  travelers.sort((a, b) => {
    return (
      orderPassengerType.indexOf(a.passenger.code) -
      orderPassengerType.indexOf(b.passenger.code)
    );
  });

  return travelers;
}

/**
 * 将填写的travelers信息，转化成页面要展示的label文本数据结构
 * @param {*} travelerFormValues
 */
function getTravelersInfo(travelerFormValues) {
  let travelersInfo = [];
  travelerFormValues.forEach(element => {
    let traveler = {};
    traveler.passenger =
      element.lastName + "/" + element.firstName + "  " + element.title;
    traveler.birthDate = element.birthDate;
    if (element.telephoneNumber != undefined) {
      traveler.telephoneNumber = element.telephoneNumber;
    }
    if (element.email != undefined) {
      traveler.email = element.email;
    }
    travelersInfo.push(traveler);
  });

  return travelersInfo;
}

function getPassengerTypeInfo(vendorMessages) {
  if (!vendorMessages || vendorMessages.length == 0) return null;

  let passengerTypeInfo = {
    company: null,
    passengerTypes: []
  };

  vendorMessages.forEach(vendorMessage => {
    if ("passengerType" == vendorMessage.infoType) {
      vendorMessage.subSections.forEach(subSectionT => {
        subSectionT.paragraphs.forEach(paragraph => {
          let passengerType = {};
          paragraph.listItems.forEach(listItem => {
            let textFormat = listItem.textFormat;
            let formats = textFormat.split(":");
            passengerType.companyCode = formats[0];
            passengerType.fromAge = formats[1];
            passengerType.toAge = formats[2];
            listItem.subSections.forEach(subSection => {
              passengerType.type = subSection.paragraphs[0].text;
            });
          });
          passengerTypeInfo.passengerTypes.push(passengerType);
          passengerTypeInfo.company = passengerType.companyCode;
        });
      });
    }
  });

  return passengerTypeInfo;
}

function getPassTravelersFormArray(reservation) {
  let tpaExtensions = reservation.tpaExtensions;
  // let leadTravelerInformationRequired = ""
  let defaultTravelerInformationRequired = "";
  let passengerInfos = reservation.passengerInfos;

  if (tpaExtensions != null && tpaExtensions != undefined) {
    if (
      tpaExtensions.valueMap != null &&
      tpaExtensions.valueMap !== undefined
    ) {
      // if(tpaExtensions.valueMap.leadTravelerInformationRequired != null && tpaExtensions.valueMap.leadTravelerInformationRequired !== undefined){
      //     leadTravelerInformationRequired = tpaExtensions.valueMap.leadTravelerInformationRequired
      // }
      if (
        tpaExtensions.valueMap.defaultTravelerInformationRequired != null &&
        tpaExtensions.valueMap.defaultTravelerInformationRequired !== undefined
      ) {
        defaultTravelerInformationRequired =
          tpaExtensions.valueMap.defaultTravelerInformationRequired;
      }
    }
  }

  // let isLeadTravelerSet = false
  let travelers = [];
  passengerInfos.forEach(item => {
    let traveler = {
      rph: "",
      fromAge: null,
      toAge: null,
      passengerQualifyingInfo: [],
      passengerDetails: [],
      passenger: {}
    };
    let searchAge = item.passengerDetails[0].age;
    traveler.age = searchAge;
    traveler.rph = item.rph;
    traveler.fromAge = item.fromAge;
    traveler.toAge = item.toAge;
    traveler.passengerQualifyingInfo = item.passengerQualifyingInfo;

    traveler.passenger.code = item.passengerQualifyingInfo[0].codeContext;
    if (defaultTravelerInformationRequired.includes("TITLE")) {
      traveler.passenger.title = "";
    }
    if (defaultTravelerInformationRequired.includes("FIRST_NAME")) {
      traveler.passenger.firstName = "";
    }
    if (defaultTravelerInformationRequired.includes("LAST_NAME")) {
      traveler.passenger.lastName = "";
    }
    if (defaultTravelerInformationRequired.includes("DATE_OF_BIRTH")) {
      traveler.passenger.birthDate = "";
    }
    if (defaultTravelerInformationRequired.includes("COUNTRY_OF_RESIDENCE")) {
      traveler.passenger.addressName = "";
      traveler.passenger.address = "";
    }
    if (defaultTravelerInformationRequired.includes("PASSPORT")) {
      traveler.passenger.docID = "";
      traveler.passenger.expireDate = "";
      traveler.passenger.docHolderNationality = "";
      traveler.passenger.docHolderNationalityName = "";
    }
    if (defaultTravelerInformationRequired.includes("EMAIL")) {
      traveler.passenger.email = "";
    }
    if (defaultTravelerInformationRequired.includes("PHONE_NUMBER")) {
      traveler.passenger.countryCode = "";
      traveler.passenger.telephoneNumber = "";
    }

    // if(!isLeadTravelerSet){
    //     if(item.passengerQualifyingInfo[0].codeContext == "ADULT" || item.passengerQualifyingInfo[0].codeContext == "SENIOR"){
    //         if(leadTravelerInformationRequired.includes("PHONE_NUMBER")){
    //             traveler.passenger.countryCode = ""
    //             traveler.passenger.telephoneNumber = ""
    //         }
    //         if(leadTravelerInformationRequired.includes("EMAIL")){
    //             traveler.passenger.email = ""
    //         }
    //         if(leadTravelerInformationRequired.includes("TITLE")){
    //             traveler.passenger.title = ""
    //         }
    //         if(leadTravelerInformationRequired.includes("FIRST_NAME")){
    //             traveler.passenger.firstName = ""
    //         }
    //         if(leadTravelerInformationRequired.includes("LAST_NAME")){
    //             traveler.passenger.lastName = ""
    //         }
    //         if(leadTravelerInformationRequired.includes("DATE_OF_BIRTH")){
    //             traveler.passenger.birthDate = ""
    //         }
    //         if(leadTravelerInformationRequired.includes("COUNTRY_OF_RESIDENCE")){
    //             traveler.passenger.addressName = ""
    //             traveler.passenger.address = ""
    //         }
    //         if(leadTravelerInformationRequired.includes("PASSPORT")){
    //             traveler.passenger.docID = ""
    //             traveler.passenger.expireDate = ""
    //             traveler.passenger.docHolderNationality = ""
    //             traveler.passenger.docHolderNationalityName = ""
    //         }
    //         traveler.passenger.leadTraveler = true
    //         isLeadTravelerSet = true
    //     }
    // }

    travelers.push(traveler);
  });

  //如果没有大人或者老年人，选第一个为leader信息
  // if(!isLeadTravelerSet){
  //     if(leadTravelerInformationRequired.includes("PHONE_NUMBER")){
  //         travelers[0].passenger.countryCode = ""
  //         travelers[0].passenger.telephoneNumber = ""
  //     }
  //     if(leadTravelerInformationRequired.includes("EMAIL")){
  //         travelers[0].passenger.email = ""
  //     }
  //     if(leadTravelerInformationRequired.includes("TITLE")){
  //         travelers[0].passenger.title = ""
  //     }
  //     if(leadTravelerInformationRequired.includes("FIRST_NAME")){
  //         travelers[0].passenger.firstName = ""
  //     }
  //     if(leadTravelerInformationRequired.includes("LAST_NAME")){
  //         travelers[0].passenger.lastName = ""
  //     }
  //     if(leadTravelerInformationRequired.includes("DATE_OF_BIRTH")){
  //         travelers[0].passenger.birthDate = ""
  //     }
  //     if(leadTravelerInformationRequired.includes("COUNTRY_OF_RESIDENCE")){
  //         travelers[0].passenger.addressName = ""
  //         travelers[0].passenger.address = ""
  //     }
  //     if(leadTravelerInformationRequired.includes("PASSPORT")){
  //         travelers[0].passenger.docID = ""
  //         travelers[0].passenger.expireDate = ""
  //         travelers[0].passenger.docHolderNationality = ""
  //         travelers[0].passenger.docHolderNationalityName = ""
  //     }
  //     travelers[0].passenger.leadTraveler = true
  // }

  //按照大人、老人、青年人排序
  let orderPassengerType = ["ADULT", "SENIOR", "YOUTH"];
  travelers.sort((a, b) => {
    return (
      orderPassengerType.indexOf(a.passenger.code) -
      orderPassengerType.indexOf(b.passenger.code)
    );
  });

  return travelers;
}

function getPassTravelersInfo(travelerFormValues) {
  let travelersInfo = [];
  travelerFormValues.forEach(element => {
    let passengerForm = element.passenger;
    let traveler = {};
    traveler.passenger =
      passengerForm.title +
      " " +
      passengerForm.firstName +
      "  " +
      passengerForm.lastName;
    traveler.birthDate = passengerForm.birthDate;
    if (passengerForm.telephoneNumber != undefined) {
      traveler.telephoneNumber = passengerForm.telephoneNumber;
    }
    if (passengerForm.email != undefined) {
      traveler.email = passengerForm.email;
    }
    travelersInfo.push(traveler);
  });

  return travelersInfo;
}

function getRailPassResults(railPassesRS, passCriteria) {
  railPassesRS.fareInfoList.sort((a, b) => {
    let amountA = a.fareOptions[0].fare.basicFare.amount;
    let amountB = b.fareOptions[0].fare.basicFare.amount;
    return amountA - amountB;
  });

  let railPassResults = [];
  railPassesRS.fareInfoList.forEach(element => {
    let index = railPassResults.findIndex(item => {
      return item.label == element.label;
    });
    if (index == -1) {
      railPassResults.push({ label: element.label });
      //原货币单位EUR和selling货币单位HKD
      railPassResults[railPassResults.length - 1].bestPrice =
        element.fareOptions[0].fare.basicFare.amount;
      railPassResults[railPassResults.length - 1].currencyCode =
        element.fareOptions[0].fare.basicFare.currencyCode;
      railPassResults[railPassResults.length - 1].sellingBestPrice =
        element.fareOptions[0].fare.basicFare.sellingAmount;
      railPassResults[railPassResults.length - 1].sellingCurrencyCode =
        element.fareOptions[0].fare.basicFare.sellingCurrencyCode;
      railPassResults[railPassResults.length - 1].earliestDate =
        element.fareOptions[0].earliestDate;
      railPassResults[railPassResults.length - 1].latestDate =
        element.fareOptions[0].latestDate;
      railPassResults[railPassResults.length - 1].passType = !element
        .fareOptions[0].classCode.quantity
        ? "CONSECUTIVE"
        : "FLEXIBLE";

      railPassResults[railPassResults.length - 1].within = "";
      if (railPassResults[railPassResults.length - 1].passType == "FLEXIBLE") {
        railPassResults[railPassResults.length - 1].within = decodePassValidity(
          element.fareOptions[0].classCode.code
        );
      }

      let classCode =
        element.fareOptions[0].accommodation.accommodationClass.extension;

      railPassResults[railPassResults.length - 1].classes = [classCode];
      //按级别将option分组
      railPassResults[railPassResults.length - 1][classCode] = [];

      railPassResults[railPassResults.length - 1].validityOptions = [];
      // railPassResults[railPassResults.length-1].validityOptions = ["3 Days"] //testing data#TODO

      //railPassResults[railPassResults.length-1].options = []

      railPassResults[railPassResults.length - 1].includes = "";
      let option = {};

      option.label = element.label;
      option.class =
        element.fareOptions[0].accommodation.accommodationClass.extension;
      option.passType = !element.fareOptions[0].classCode.quantity
        ? "CONSECUTIVE"
        : "FLEXIBLE";
      if (option.passType == "CONSECUTIVE") {
        option.validity = decodePassValidity(
          element.fareOptions[0].classCode.code
        );
      } else {
        option.validity = element.fareOptions[0].classCode.quantity + " Days";
      }
      railPassResults[railPassResults.length - 1].validityOptions.push(
        option.validity
      );
      //原货币单位EUR和selling货币单位HKD
      option.currencyCode = element.fareOptions[0].fare.basicFare.currencyCode;
      option.amount = element.fareOptions[0].fare.basicFare.amount;
      option.sellingCurrencyCode =
        element.fareOptions[0].fare.basicFare.sellingCurrencyCode;
      option.sellingAmount =
        element.fareOptions[0].fare.basicFare.sellingAmount;
      option.rph = element.fareOptions[0].rph;
      // option.collectionOptions = ["E-Ticket"]

      //计算是否过期

      // option.expireIn = ''
      option.expiredDateTime = "";
      // option.expired = false

      option.conditions = [];
      element.fareOptions[0].fare.termAndConditions.forEach(con => {
        if (con.description.type === "INCLUDES") {
          railPassResults[railPassResults.length - 1].includes =
            con.description.stringValue;
        }
        option.conditions.push({
          code: con.description.type,
          stringValue: con.description.stringValue
        });

        if (con.description.type == "PROVIDER_BOOKING_EXPIRED_DATETIME") {
          option.expiredDateTime = con.description.stringValue;
        }
      });

      //各类乘客数量
      option.passengers = [];
      if (passCriteria.adult > 0) {
        option.passengers.push({ code: "ADULT", quantity: passCriteria.adult });
      }
      if (passCriteria.senior > 0) {
        option.passengers.push({
          code: "SENIOR",
          quantity: passCriteria.senior
        });
      }
      if (passCriteria.youth > 0) {
        option.passengers.push({ code: "YOUTH", quantity: passCriteria.youth });
      }

      railPassResults[railPassResults.length - 1][classCode].push(option);
    } else {
      // railPassResults[index].validityOptions.push(element.validity)
      //railPassResults[index].validityOptions.push("3 Days") //testing data #TODO

      let classCode =
        element.fareOptions[0].accommodation.accommodationClass.extension;

      if (!railPassResults[index].classes.includes(classCode)) {
        railPassResults[index].classes.push(classCode);
      }

      if (!railPassResults[index][classCode]) {
        railPassResults[index][classCode] = [];
      }

      let option = {};
      option.label = element.label;
      option.class =
        element.fareOptions[0].accommodation.accommodationClass.extension;
      option.passType = !element.fareOptions[0].classCode.quantity
        ? "CONSECUTIVE"
        : "FLEXIBLE";
      if (option.passType == "CONSECUTIVE") {
        option.validity = decodePassValidity(
          element.fareOptions[0].classCode.code
        );
      } else {
        option.validity = element.fareOptions[0].classCode.quantity + " Days";
      }
      if (!railPassResults[index].validityOptions.includes(option.validity)) {
        railPassResults[index].validityOptions.push(option.validity);
      }

      //原货币单位EUR和selling货币单位HKD
      option.currencyCode = element.fareOptions[0].fare.basicFare.currencyCode;
      option.amount = element.fareOptions[0].fare.basicFare.amount;
      option.sellingCurrencyCode =
        element.fareOptions[0].fare.basicFare.sellingCurrencyCode;
      option.sellingAmount =
        element.fareOptions[0].fare.basicFare.sellingAmount;
      option.rph = element.fareOptions[0].rph;
      // option.collectionOptions = ["E-Ticket"]

      //计算是否过期

      // option.expireIn = ''
      option.expiredDateTime = "";
      // option.expired = false

      option.conditions = [];
      element.fareOptions[0].fare.termAndConditions.forEach(con => {
        option.conditions.push({
          code: con.description.type,
          stringValue: con.description.stringValue
        });

        if (con.description.type == "PROVIDER_BOOKING_EXPIRED_DATETIME") {
          option.expiredDateTime = con.description.stringValue;
        }

        // if(option.expiredDateTime != ''){
        //     let expiredDateTimeStr = ticket.expiredDateTime.slice(0, 19)
        //     ticket.expiredDateTime = DateUtils.formatDate(expiredDateTimeStr, "YYYY-MM-DD HH:mm:ss")
        //     ticket.expireIn = DateUtils.getExpireInMinute(expiredDateTimeStr)
        // }

        // if(ticket.expireIn <= 0){
        //     ticket.expired = true
        // }
      });

      //各类乘客数量
      option.passengers = [];
      if (passCriteria.adult > 0) {
        option.passengers.push({ code: "ADULT", quantity: passCriteria.adult });
      }
      if (passCriteria.senior > 0) {
        option.passengers.push({
          code: "SENIOR",
          quantity: passCriteria.senior
        });
      }
      if (passCriteria.youth > 0) {
        option.passengers.push({ code: "YOUTH", quantity: passCriteria.youth });
      }

      railPassResults[index][classCode].push(option);
    }
  });

  return railPassResults;
}

function getBookInitiateRQ(payload) {
  let bookInitiateRQ = {
    resStatus: "Initiate",
    railBookInfo: {
      originDestinationList: []
    },
    pos: {
      sourceList: [
        {
          requestorID: {
            id: payload.username
          },
          bookingChannel: {
            companyName: {
              code: "JEBSENTRAVEL"
            },
            type: "6"
          }
        }
      ]
    }
  };

  bookInitiateRQ.railBookInfo.originDestinationList = [];

  payload.rphs.forEach(item => {
    bookInitiateRQ.railBookInfo.originDestinationList.push({ rph: item });
  });

  // if(payload.selectedValidity){
  //     bookInitiateRQ.railBookInfo.originDestinationList.push({rph: payload.selectedValidity.rph})
  // }else if(payload.selectedInboundTicket != null){
  //     bookInitiateRQ.railBookInfo.originDestinationList.push({rph: payload.selectedOutboundTicket.rph})
  //     bookInitiateRQ.railBookInfo.originDestinationList.push({rph: payload.selectedInboundTicket.rph})
  // }else {
  //     bookInitiateRQ.railBookInfo.originDestinationList.push({rph: payload.selectedOutboundTicket.rph})
  // }

  return bookInitiateRQ;
}

function getBookQuoteRQ(payload) {
  let bookQuoteRQ = {
    resStatus: "Quote",
    correlationID: payload.correlationID,
    railBookInfo: {
      passengerInfos: []
    },
    pos: {
      sourceList: [
        {
          requestorID: {
            id: payload.username
          },
          bookingChannel: {
            companyName: {
              code: "JEBSENTRAVEL"
            },
            type: "6"
          }
        }
      ]
    }
  };
  payload.reservations.forEach(revItem => {
    revItem.travelers.forEach(element => {
      let passengerInfo = {};
      passengerInfo.rph = element.rph;
      passengerInfo.passengerQualifyingInfo = element.passengerQualifyingInfo;
      passengerInfo.passengerDetails = [];

      let passengerDetail = { identification: {} };
      passengerDetail.age = element.age;
      if (element.leadTraveler) {
        passengerDetail.leadTraveler = true;
        if (element.leadTraveler.title) {
          passengerDetail.identification.nameTitle = element.leadTraveler.title;
        }
        if (element.leadTraveler.firstName) {
          passengerDetail.identification.givenName =
            element.leadTraveler.firstName;
        }
        if (element.leadTraveler.lastName) {
          passengerDetail.identification.surname =
            element.leadTraveler.lastName;
        }
        if (element.leadTraveler.birthDate) {
          passengerDetail.birthDate = element.leadTraveler.birthDate;
          passengerDetail.age = DateUtils.getDuration(
            "",
            element.leadTraveler.birthDate,
            "year"
          );
        }

        if (element.leadTraveler.docID) {
          passengerDetail.identification.document = {};
          passengerDetail.identification.document.docID =
            element.leadTraveler.docID;
        }
        if (element.leadTraveler.expireDate) {
          passengerDetail.identification.document.expireDate =
            element.leadTraveler.expireDate;
        }
        if (element.leadTraveler.docHolderNationality) {
          passengerDetail.identification.document.docHolderNationality =
            element.leadTraveler.docHolderNationality;
        }

        if (element.leadTraveler.telephoneNumber) {
          passengerDetail.telephones = [
            { phoneNumber: element.leadTraveler.telephoneNumber }
          ];
        }
        if (element.leadTraveler.email) {
          passengerDetail.emails = [
            { stringValue: element.leadTraveler.email }
          ];
        }
        if (element.leadTraveler.address) {
          passengerDetail.addresses = [
            { country: element.leadTraveler.address }
          ];
        }
      }
      // passengerDetail.birthDate = element.passenger.birthDate
      // passengerDetail.age = DateUtils.getDuration("", element.passenger.birthDate, "year")

      // passengerDetail.telephones = [{"phoneNumber": element.passenger.telephoneNumber}]
      // passengerDetail.emails = [{"stringValue": element.passenger.email}]
      // passengerDetail.addresses = [{"country": element.passenger.address}]
      // passengerDetail.identification.givenName = element.passenger.firstName
      // passengerDetail.identification.surname = element.passenger.lastName
      // passengerDetail.identification.nameTitle = element.passenger.title
      if (element.passenger.title) {
        passengerDetail.identification.nameTitle = element.passenger.title;
      }
      if (element.passenger.firstName) {
        passengerDetail.identification.givenName = element.passenger.firstName;
      }
      if (element.passenger.lastName) {
        passengerDetail.identification.surname = element.passenger.lastName;
      }
      if (element.passenger.birthDate) {
        passengerDetail.birthDate = element.passenger.birthDate;
        passengerDetail.age = DateUtils.getDuration(
          "",
          element.passenger.birthDate,
          "year"
        );
      }

      if (element.passenger.docID) {
        passengerDetail.identification.document = {};
        passengerDetail.identification.document.docID = element.passenger.docID;
      }
      if (element.passenger.expireDate) {
        passengerDetail.identification.document.expireDate =
          element.passenger.expireDate;
      }
      if (element.passenger.docHolderNationality) {
        passengerDetail.identification.document.docHolderNationality =
          element.passenger.docHolderNationality;
      }

      if (element.passenger.telephoneNumber) {
        passengerDetail.telephones = [
          { phoneNumber: element.passenger.telephoneNumber }
        ];
      }
      if (element.passenger.email) {
        passengerDetail.emails = [{ stringValue: element.passenger.email }];
      }
      if (element.passenger.address) {
        passengerDetail.addresses = [{ country: element.passenger.address }];
      }

      passengerInfo.passengerDetails.push(passengerDetail);

      bookQuoteRQ.railBookInfo.passengerInfos.push(passengerInfo);
    });
  });
  return bookQuoteRQ;
}

function getBookRQ(payload) {
  let bookQuoteRQ = {
    resStatus: "Quote",
    correlationID: payload.correlationID,
    railBookInfo: {
      passengerInfos: []
    },
    pos: {
      sourceList: [
        {
          requestorID: {
            id: payload.username
          },
          bookingChannel: {
            companyName: {
              code: "JEBSENTRAVEL"
            },
            type: "6"
          }
        }
      ]
    }
  };
  payload.travelerFormValues.forEach(element => {
    let passengerInfo = {};
    passengerInfo.rph = element.rph;
    passengerInfo.passengerQualifyingInfo = element.passengerQualifyingInfo;
    passengerInfo.passengerDetails = [];

    let passengerDetail = { identification: {} };
    passengerDetail.age = element.age;
    if (element.passenger.leadTraveler) {
      passengerDetail.leadTraveler = true;
    }
    // passengerDetail.birthDate = element.passenger.birthDate
    // passengerDetail.age = DateUtils.getDuration("", element.passenger.birthDate, "year")

    // passengerDetail.telephones = [{"phoneNumber": element.passenger.telephoneNumber}]
    // passengerDetail.emails = [{"stringValue": element.passenger.email}]
    // passengerDetail.addresses = [{"country": element.passenger.address}]
    // passengerDetail.identification.givenName = element.passenger.firstName
    // passengerDetail.identification.surname = element.passenger.lastName
    // passengerDetail.identification.nameTitle = element.passenger.title
    if (element.passenger.title) {
      passengerDetail.identification.nameTitle = element.passenger.title;
    }
    if (element.passenger.firstName) {
      passengerDetail.identification.givenName = element.passenger.firstName;
    }
    if (element.passenger.lastName) {
      passengerDetail.identification.surname = element.passenger.lastName;
    }
    if (element.passenger.birthDate) {
      passengerDetail.birthDate = element.passenger.birthDate;
      passengerDetail.age = DateUtils.getDuration(
        "",
        element.passenger.birthDate,
        "year"
      );
    }

    if (element.passenger.docID) {
      passengerDetail.identification.document = {};
      passengerDetail.identification.document.docID = element.passenger.docID;
    }
    if (element.passenger.expireDate) {
      passengerDetail.identification.document.expireDate =
        element.passenger.expireDate;
    }
    if (element.passenger.docHolderNationality) {
      passengerDetail.identification.document.docHolderNationality =
        element.passenger.docHolderNationality;
    }

    if (element.passenger.telephoneNumber) {
      passengerDetail.telephones = [
        { phoneNumber: element.passenger.telephoneNumber }
      ];
    }
    if (element.passenger.email) {
      passengerDetail.emails = [{ stringValue: element.passenger.email }];
    }
    if (element.passenger.address) {
      passengerDetail.addresses = [{ country: element.passenger.address }];
    }

    passengerInfo.passengerDetails.push(passengerDetail);

    bookQuoteRQ.railBookInfo.passengerInfos.push(passengerInfo);
  });
  return bookQuoteRQ;
}

function getConfirmBookingRQ(bookTicketRS) {
  let confirmBookingRQ = {
    uniqueIDs: []
  };
  let railEuropeBookingId = "";
  bookTicketRS.railReservations[0].uniqueID.forEach(idItem => {
    if (idItem.type == "RAILEUROPE_BOOKING_ID") {
      railEuropeBookingId = idItem.id;
    }
  });
  confirmBookingRQ.uniqueIDs.push({ id: railEuropeBookingId });
  return confirmBookingRQ;
}

function getP2pItinerary(reservation) {
  let itineraryInfo = {};
  let trains = [];
  itineraryInfo.isRoundTrip = false;
  itineraryInfo.originLocation = "";
  itineraryInfo.destinationLocation = "";

  let itinerary = reservation.itinerary;
  let originAndDestinations = itinerary.originAndDestinations;

  try {
    if (originAndDestinations.length > 1) {
      itineraryInfo.isRoundTrip = true;
    }

    originAndDestinations.forEach(element => {
      //单程、双程
      let train = {};
      train.originLocation = "";
      train.destinationLocation = "";
      train.originLocation = element.originLocation.locationName;
      train.destinationLocation = element.destinationLocation.locationName;

      if (itineraryInfo.originLocation == "") {
        itineraryInfo.originLocation = element.originLocation.locationName;
      }
      if (itineraryInfo.destinationLocation == "") {
        itineraryInfo.destinationLocation =
          element.destinationLocation.locationName;
      }

      train.trainSegments = [];
      let trainSegments = element.trainSegments;
      trainSegments.forEach(item => {
        let trainSegment = {};

        trainSegment.departureDate = DateUtils.formatDate(
          item.departureDateTime,
          "YYYY-MM-DD"
        );
        trainSegment.arrivalDate = DateUtils.formatDate(
          item.arrivalDateTime,
          "YYYY-MM-DD"
        );
        trainSegment.departureTime = DateUtils.formatDate(
          item.departureDateTime,
          "HH:mm"
        );
        trainSegment.arrivalTime = DateUtils.formatDate(
          item.arrivalDateTime,
          "HH:mm"
        );

        trainSegment.departureStation =
          item.departureStation.details.stationName;
        trainSegment.arrivalStation = item.arrivalStation.details.stationName;

        if (item.trainInfo.train.networkCode.code != null) {
          trainSegment.networkCode = item.trainInfo.train.networkCode.code;
        } else {
          trainSegment.networkCode = "";
        }

        if (item.trainInfo.train.trainNumber != null) {
          trainSegment.trainNumber = item.trainInfo.train.trainNumber;
        } else {
          trainSegment.trainNumber = "";
        }

        trainSegment.duration = DateUtils.getTrainDuration(
          item.arrivalDateTime,
          item.departureDateTime
        );

        train.trainSegments.push(trainSegment);
      });
      trains.push(train);
    });
  } catch {
    console.log("Origin And Destination data errors.");
  }

  itineraryInfo.trains = trains;

  return itineraryInfo;
}

function getPassItinerary(reservation) {
  let itineraryInfo = {};
  let itinerary = reservation.itinerary;
  let vendorMessages = itinerary.vendorMessages;

  itineraryInfo.label = "";
  itineraryInfo.firstTravelDate = "";
  itineraryInfo.passConditions = [];

  try {
    vendorMessages.forEach(vendorMessageItem => {
      vendorMessageItem.subSections.forEach(element => {
        if (element.subCode === "product") {
          element.paragraphs[0].listItems[0].subSections.forEach(item => {
            if (item.subCode === "label") {
              itineraryInfo.label = item.paragraphs[0].text;
            }
            if (item.subCode === "firstTravelDate") {
              itineraryInfo.firstTravelDate = DateUtils.formatDate(
                item.paragraphs[0].text
              );
            }
            if (item.subCode === "travelClass") {
              itineraryInfo.travelClass = decodeCabinClass(
                item.paragraphs[0].text
              );
            }
          });
        }

        if (element.subCode === "conditions") {
          element.paragraphs.forEach(item => {
            let con = {};
            if (item.name == "CARD_INTRODUCTION") {
              con.title = "Card Introduction";
              con.stringValue = item.text;
              itineraryInfo.passConditions.push(con);
            }
            if (item.name == "CARD_SALES") {
              con.title = "Card Sales";
              con.stringValue = item.text;
              itineraryInfo.passConditions.push(con);
            }
            if (item.name == "CARD_LABEL") {
              con.title = "Card Label";
              con.stringValue = item.text;
              itineraryInfo.passConditions.push(con);
            }

            if (item.name == "GLOBAL_INTRODUCTION_DETAILS") {
              con.title = "Global Introduction Details";
              con.stringValue = item.text;
              itineraryInfo.passConditions.push(con);
            }
            if (item.name == "GLOBAL_INTRODUCTION") {
              con.title = "Global Introduction";
              con.stringValue = item.text;
              itineraryInfo.passConditions.push(con);
            }
            if (item.name == "GLOBAL_DESCRIPTION") {
              con.title = "Global Description";
              con.stringValue = item.text;
              itineraryInfo.passConditions.push(con);
            }
            if (item.name == "INCLUDES") {
              con.title = "Includes";
              con.stringValue = item.text;
              itineraryInfo.passConditions.push(con);
            }
            if (item.name == "PASS_RANGE_SALES") {
              con.title = "Pass Range Sales";
              con.stringValue = item.text;
              itineraryInfo.passConditions.push(con);
            }
            if (item.name == "PASS_RANGE_AFTER_SALES") {
              con.title = "Pass After Sales";
              con.stringValue = item.text;
              itineraryInfo.passConditions.push(con);
            }
            if (item.name == "TICKETING_METHOD") {
              con.title = "Ticketing Method";
              con.stringValue = item.text;
              itineraryInfo.passConditions.push(con);
            }
            if (item.name == "PAXTYPE_SALES") {
              con.title = "Paxtype Sales";
              con.stringValue = item.text;
              itineraryInfo.passConditions.push(con);
            }
          });
        }
      });
    });
  } catch {
    console.log("Vendor Messages data errors");
  }

  return itineraryInfo;
}

function getInitiateBookedPrice(reservation) {
  //原货币单位EUR和selling货币单位HKD
  let bookedPrice = {
    currencyCode: "",
    totalAmount: 0,
    sellingCurrencyCode: "",
    sellingTotalAmount: 0,
    taxes: []
  };
  let priceRS = reservation.itinerary.railCharges.total;
  let taxes = priceRS.taxes;
  bookedPrice.currencyCode = priceRS.currencyCode;
  bookedPrice.totalAmount = priceRS.amountAfterTax;
  bookedPrice.sellingCurrencyCode = priceRS.sellingCurrencyCode;
  bookedPrice.sellingTotalAmount = priceRS.sellingAmount;
  if (taxes != null && taxes != undefined) {
    if (
      taxes.taxes != null &&
      taxes.taxes != undefined &&
      taxes.taxes.length > 0
    ) {
      taxes.taxes.forEach(item => {
        if (item.type != "inclusive") {
          bookedPrice.totalAmount = bookedPrice.totalAmount + item.amount;
          bookedPrice.sellingTotalAmount =
            bookedPrice.sellingTotalAmount + item.sellingAmount;
        }
        item.name = item.taxDescriptions[0].name;
        bookedPrice.taxes.push(item);
      });
    }
  }

  return bookedPrice;
}

function getBookedPrice(reservation) {
  let bookedPrice = {
    currencyCode: "",
    totalAmount: 0,
    taxes: []
  };
  let priceRS = reservation.itinerary.railCharges.total;
  let taxes = priceRS.taxes;
  bookedPrice.currencyCode = priceRS.currencyCode;
  bookedPrice.totalAmount = priceRS.amountBeforeTax;
  if (taxes != null && taxes != undefined) {
    if (
      taxes.taxes != null &&
      taxes.taxes != undefined &&
      taxes.taxes.length > 0
    ) {
      taxes.taxes.forEach(item => {
        if (item.type != "inclusive") {
          bookedPrice.totalAmount = bookedPrice.totalAmount + item.amount;
        }
        item.name = item.taxDescriptions[0].name;
        bookedPrice.taxes.push(item);
      });
    }
  }

  return bookedPrice;
}

function decodeFlexibility(code) {
  let codeNames = {
    FULL_FLEX: "Full Flex",
    SEMI_FLEX: "Semi Flex",
    NON_FLEX: "Non Flex"
  };
  return codeNames[code];
}

function decodePassValidity(code) {
  if (code.includes("D")) {
    return code.slice(1, code.indexOf("D")) + " Days";
  }

  if (code.includes("M")) {
    let isPlural = code.slice(1, code.indexOf("M")) > 1 ? " Months" : " Month";
    return code.slice(1, code.indexOf("M")) + isPlural;
  }
}

function compareDuration(a, b) {
  let hourA = a.substr(0, a.indexOf("h"));
  let minuteA = a.substr(a.indexOf("") + 1, a.indexOf("m"));
  let hourB = b.substr(0, b.indexOf("h"));
  let minuteB = b.substr(b.indexOf("") + 1, b.indexOf("m"));
  if (hourA > hourB) {
    return 1;
  } else if (hourA == hourB) {
    if (minuteA > minuteB) {
      return 1;
    } else {
      return -1;
    }
  } else {
    return -1;
  }
}

function hasProvider(selectedProviderArr, ticketProviderArr) {
  let hasIt = false;
  for (let i = 0; i < ticketProviderArr.length; i++) {
    if (selectedProviderArr.includes(ticketProviderArr[i])) {
      hasIt = true;
      break;
    }
  }
  return hasIt;
}

function getPassCountries() {
  let countryArry = [
    { code: "", label: "Country to Visit", disabled: true },
    { code: "EU", label: "Europe" },
    { code: "AT", label: "Austria" },
    { code: "BE", label: "Belgium" },
    { code: "BENELUX", label: "Benelux" },
    { code: "BG", label: "Bulgaria" },
    { code: "CH", label: "Switzerland" },
    { code: "CZ", label: "Czech Republic" },
    { code: "DE", label: "Germany" },
    { code: "DK", label: "Denmark" },
    { code: "ES", label: "Spain" },
    { code: "FI", label: "Finland" },
    { code: "FR", label: "France" },
    { code: "GB", label: "Great Britain" },
    { code: "GR", label: "Greece" },
    { code: "HR", label: "Croatia" },
    { code: "HU", label: "Hungary" },
    { code: "IE", label: "Ireland" },
    { code: "IT", label: "Italy" },
    { code: "LT", label: "Lithuania" },
    { code: "LU", label: "Luxembourg" },
    { code: "MK", label: "North Macedonia" },
    { code: "NL", label: "Netherlands" },
    { code: "NO", label: "Norway" },
    { code: "PL", label: "Poland" },
    { code: "PT", label: "Portugal" },
    { code: "RO", label: "Romania" },
    { code: "RS", label: "Serbia" },
    { code: "SCANDINAVIA", label: "Scandinavia" },
    { code: "SE", label: "Sweden" },
    { code: "SI", label: "Slovenia" },
    { code: "SK", label: "Slovakia" },
    { code: "TR", label: "Turkey" }
  ];

  countryArry.sort((a, b) => {
    return a.label - b.label;
  });

  return countryArry;
}

function getCartStatus(cartItems) {
  cartItems.forEach(item => {
    try {
      item.expired = false;
      item.expiredDateTime = "";
      item.expireIn = 0;

      let expiredDateTimeStr;
      if (item.pass) {
        expiredDateTimeStr = item.pass.expiredDateTime.slice(0, 19);
      }
      if (item.outbound) {
        expiredDateTimeStr = item.outbound.expiredDateTime.slice(0, 19);
      }
      item.expiredDateTime = DateUtils.formatDate(
        expiredDateTimeStr,
        "YYYY-MM-DD HH:mm:ss"
      );
      item.expireIn = DateUtils.getExpireInMinute(expiredDateTimeStr);

      if (item.expireIn <= 0) {
        item.expired = true;
      }
    } catch {
      console.info("Some data errors.");
    }
  });

  return cartItems;
}

function decodeCabinClass(cabinClassCode) {
  let cabinClassText = cabinClassCode;
  if (cabinClassCode == "1") {
    cabinClassText = "1st Class";
  }

  if (cabinClassCode == "2") {
    cabinClassText = "2nd Class";
  }

  return cabinClassText;
}

export default {
  getSearchRQ,
  getPassSearchRQ,
  getTrainTicketResults,
  getRailPassResults,
  getP2POptions,
  getLeadTravelerForm,
  getTicketTravelersFormArray,
  getTicketTravelersInfo,
  getTravelersFormArray,
  getPassengerTypeInfo,
  getTravelersInfo,
  getPassTravelersFormArray,
  getPassTravelersInfo,
  getBookInitiateRQ,
  getBookQuoteRQ,
  getBookRQ,
  getConfirmBookingRQ,
  getP2pItinerary,
  getPassItinerary,
  getInitiateBookedPrice,
  getBookedPrice,
  getPassCountries,
  getFilteredInboundTickets,
  getDepartureStationFilterOptions,
  getArrivalStationFilterOptions,
  getProviderOptions,
  getSortAndFilteredTickets,
  getCartStatus
};
