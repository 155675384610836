import axios from "../utils/axiosUtils"
const hotelUrl = "/api/v1/hotel";
export default {
    getHotelPopularCities(){
        return axios.get(hotelUrl+'/popular-cities');
    },

    searchAvailHotels(hotelAvailRQ) {
        //保证对象是Json字符串
        if (typeof hotelAvailRQ === 'object'){
            hotelAvailRQ = JSON.stringify(hotelAvailRQ);
        }
        return axios.post(hotelUrl+"/avail", hotelAvailRQ,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },

    searchAvailRooms(request){
        //保证对象是Json字符串
        if (typeof request === 'object'){
            request = JSON.stringify(request);
        }
        return axios.post(hotelUrl+"/avail", request,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },

    searchHotelDescription(request){
        //保证对象是JSON字符串
        if(typeof request==='object'){
            request = JSON.stringify(request)
        }
        return axios.post(hotelUrl+"/description",request,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })
    },

    confirmInformation(request){
        //保证对象是JSON字符串
        if(typeof request==='object'){
            request = JSON.stringify(request)
        }
        return axios.post(hotelUrl+"/confirm",request,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })
    },

    preBookHotelRoom(request){
        //保证对象是JSON字符串
        if(typeof request==='object'){
            request = JSON.stringify(request)
        }
        return axios.post(hotelUrl+"/prebook",request,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })
    },

    B2BBook(request){
        if(typeof request==='object'){
            request = JSON.stringify(request)
        }
        return axios.post(hotelUrl+"/b2b-book",request,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        })
    },

    getHotelDestinationAutocomplete(key){
        return axios.get("/api/v1/autocomplete/hotel-destination?language=en&cityOnly=true&keyword="+key);
    }
}