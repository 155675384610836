const state = {
    packageCityRS: {
    },
    dynamicpkgRS:{},
    selectedPkgHotel: {
        ttiCode:'',
        rooms:[],
        room:{
            roomType:"",
            roomTypeCode:"",
            Price:{
                currencyCode:"",
                amountIncludingMarkup:"",
                amountBeforeTax:"",
                amountAfterTax:"",
            },
            maxOccupancy:"",
            minOccupancy:"",
            meals:"",
            freeCancelTime:"",
            referenceId:"",
            hotelCodeContext:"",
            fitWords:"",
            star:0,
            alternate:null,
            quantity:0,
            datePrice:[]
        },
        description: {
            name: "",
            location:{
                cityName: "",
                countryCode: "",
                countryName: "",
                address: "",
                position: {
                    longtitude: "",
                    latitude: ""
                }
            },
            photos: [],
            overView:""
        },
        amenities:[],
        amenity:{
            serviceItem:"",
            code:""
        },
        rating:0,
        roomImgList:[],
        star:0,
        mainImgUrl:'',
        lowestPrice:0
    },
    pkgVerifyRS:{},
    pkgPreBookRS:{}
}

export default state;

