<template>
<Layout>
        <PageHeader :title="title" :items="items" />
    <div class=" container-fluid p-4">

        <!--                建立表单-->
        <b-card bg-variant="light" style="background-color:white!important;">
            <div style="position:relative;bottom: 35px;background-color:transparent;font-weight: bolder;font-size: 20px">{{$t('carRental')}}</div>
            <b-form>
                <b-form-group
                        label-cols-lg="12"
                        label=""
                        label-size="lg"
                        label-class="font-weight-bold pt-0"
                        class="mb-0">
                    <b-row>
                        <b-col cols="4">
                            <ap-autocomplete @selectedItem="selectPickUpLocation" list-name="'pickUpLocation'" url="/vehicle-locations" :subTitle="'address'" :title="'locationName'" :placeholder="$t('pickUpLocation')"></ap-autocomplete>
                        </b-col>
                        <b-col cols="4">
                            <ap-autocomplete @selectedItem="selectDropOffLocation" list-name="'dropOffLocation'" url="/vehicle-locations" :subTitle="'address'" :title="'locationName'" :placeholder="$t('dropOffLocation')"></ap-autocomplete>
                        </b-col>
                        <b-col cols="2">
                            <ap-autocomplete @selectedItem="selectCustomerCountry" list-name="'customerCountry'" url="/country-code" :subTitle="'code'" :title="'name'" :placeholder="$t('customerCountry')"></ap-autocomplete>
                        </b-col>
                        <b-col cols="2">
                            <b-form-select v-model="carRental.carBrand" :options="carBrandOptions" required></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row style="margin-top: 30px">
                        <b-col md="4">
                            <b-form-datepicker v-model="carRental.pickUpDate" :min="pickUpMinDate" class="mb-2" required :placeholder="$t('pickUpDate')"></b-form-datepicker>
                        </b-col>
                        <b-col md="2">
                            <b-form-timepicker dataformatas="hh:mm" :placeholder="$t('pickUpTime')" locale="en" v-model="carRental.pickUpTime"></b-form-timepicker>
                        </b-col>
                        <b-col md="4">
                            <b-form-datepicker v-model="carRental.dropOffDate" :min="new Date(new Date(this.carRental.pickUpDate).setDate(new Date(this.carRental.pickUpDate).getDate()+1))" class="mb-2" required :placeholder="$t('dropOffDate')"></b-form-datepicker>
                        </b-col>
                        <b-col md="2">
                            <b-form-timepicker :placeholder="$t('dropOffTime')" locale="en" v-model="carRental.dropOffTime"></b-form-timepicker>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col offset-md="5">
                            <b-button variant="secondary" offset-md="4" @click="carRentalSearch()">{{$t('searchCar')}}</b-button>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-form>
        </b-card>
    </div>
    <div class="c_wrap container-fluid p-4" style="margin-top: 10%" >
        <div class="c_carousel"></div>
        <carousel-3d
                :autoplay="true"
                :autoplayTimeout="3000"
                :display="5"
                :width="800"
                :height="400"
                :animationSpeed="1000"
        >

            <slide class="carousel_box" v-for="(item, index) in earthData" :key="index" :index="index">
                <div class="carousel_flashlight">
                    <img :src="item.url" alt="" style="min-width: 500px;min-height: 500px">
                </div>
            </slide>
        </carousel-3d>
    </div>
</Layout>
</template>

<script>

    import { Carousel3d, Slide } from 'vue-carousel-3d'
    import {mapState} from "vuex";
    import ApAutocomplete from "../../components/forms-element/autocomplete/ApAutocomplete";

    import Layout from "../layouts/main";
    import PageHeader from "@/components/page-header";

    export default {
        name: "CarRentalHome",
        components:{
            Layout,
            PageHeader,
            Carousel3d,
            Slide,
            ApAutocomplete
        },
        data(){
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            const minDate = new Date(today)
            minDate.setDate(minDate.getDate() - 1);
            const maxDate = new Date(today)
            maxDate.setMonth(maxDate.getMonth() + 2)
            maxDate.setDate(15);
            return{
                title: "Car Rental",
                items: [
                    {
                        // #TODO
                        text: "Jebsen Travel",
                    },
                    {
                        text: "Car Rental",
                        active: true,
                    },
                ],
                carRental:{
                    pickUpLocation:"",
                    dropOffLocation:"",
                    pickUpCode:"",
                    dropOffCode:"",
                    pickUpDate:"",
                    dropOffDate:"",
                    pickUpTime:"",
                    dropOffTime:"",
                    customerCountry:"",
                    carBrand:"ZI"
                },
                check_in_date:'',
                check_out_date:'',
                hotel_destination:'',
                earthData: [
                    {
                        stext: "标题1",
                        sdescription: "1",
                        url:"https://www.avis.com/content/dam/cars/l/2019/ford/2019-ford-fiesta-se-5door-2wd-hatchback-white.png"
                    },
                    {
                        stext: "标题2",
                        sdescription: "2",
                        url:"https://www.avis.com/content/dam/cars/l/2021/kia/2021-kia-soul-s-5door-hatchback-white.png"
                    },
                    {
                        stext: "标题3",
                        sdescription: "3",
                        url:"https://www.avis.com/content/dam/cars/l/2020/toyota/2020-toyota-corolla-le-sedan-white.png"
                    },
                    {
                        stext: "标题4",
                        sdescription: "4",
                        url:"https://www.avis.com/content/dam/cars/l/2020/toyota/2020-toyota-corolla-le-sedan-white.png"
                    },
                    {
                        stext: "标题5",
                        sdescription: "5",
                        url:"https://www.avis.com/content/dam/cars/l/2021/toyota/2021-toyota-camry-se-sedan-white.png"
                    },
                    {
                        stext: "标题1",
                        sdescription: "1",
                        url:"https://www.avis.com/content/dam/cars/l/2020/nissan/2020-nissan-pathfinder-s-4wd-suv-white.png"
                    },
                    {
                        stext: "标题2",
                        sdescription: "2",
                        url:"https://www.avis.com/content/dam/cars/l/2019/chrysler/2019-chrysler-300-limited-sedan-white.png"
                    },
                    {
                        stext: "标题3",
                        sdescription: "3",
                        url:"https://www.avis.com/content/dam/cars/l/2021/mazda/2021-mazda-cx-5-grand-touring-4wd-suv-white.png"
                    },
                    {
                        stext: "标题4",
                        sdescription: "4",
                        url:"https://www.avis.com/content/dam/cars/l/2020/ford/2020-ford-mustang-ecoboost-premium-convertible-white.png"
                    },
                    {
                        stext: "标题5",
                        sdescription: "5",
                        url:"https://www.avis.com/content/dam/cars/l/2020/ford/2020-ford-expedition-xlt-suv-white.png"
                    },
                ],
                carBrandOptions:[
                    {
                        value:"ZI",
                        text:"Avis"
                    },
                    {
                        value:"ZD",
                        text:"Budget"
                    },
                    {
                        value:"ZA",
                        text:"Payless"
                    }
                ],
                pickUpMinDate:minDate,
            }
        },
        methods:{
            carRentalSearch(){
                console.log(this.carRental);
                this.$router.push({
                    path:'/carrental/list',
                    query:{
                        pickUpLocation: this.carRental.pickUpLocation,
                        dropOffLocation: this.carRental.dropOffLocation,
                        pickUpCode: this.carRental.pickUpCode,
                        dropOffCode: this.carRental.dropOffCode,
                        pickUpDate: this.carRental.pickUpDate,
                        pickUpTime: this.carRental.pickUpTime,
                        dropOffDate: this.carRental.dropOffDate,
                        dropOffTime: this.carRental.dropOffTime,
                        customerCountry: this.carRental.customerCountry,
                        carBrand: this.carRental.carBrand
                    }
                });
            },
            selectCustomerCountry(item){
                console.log(item)
                this.carRental.customerCountry = item.code
            },
            selectPickUpLocation(item){
                this.carRental.pickUpLocation = item.locationName
                this.carRental.pickUpCode = item.locationCode
            },
            selectDropOffLocation(item){
                this.carRental.dropOffLocation = item.locationName
                this.carRental.dropOffCode = item.locationCode
            }
        },
        computed:{
            ...mapState(["LayoutType", "Theme", "setHorizontalLayout"]),
        },
        created(){
        },
        mounted() {
        },

    }
</script>

<style scoped>

</style>