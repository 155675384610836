export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login"),
    meta: {
      authRequired: false
    }
  },
  {
    path: "/",
    name: "default",
    component: () => import("../views/account/login"),
    meta: {
      authRequired: false
    }
  },
  {
    path: "/sso",
    name: "sso",
    component: () => import("../views/account/LoginWithSSO")
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/account/register"),
    meta: {
      authRequired: false
    }
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/account/forgot-password"),
    meta: {
      authRequired: false
    }
  },
  {
    path: "/account/accounts",
    name: "accounts",
    component: () => import("../views/account/AccountList"),
    meta: {
      authRequired: false
    }
  },
  {
    path: "/account/accounts",
    name: "accountDetails",
    component: () => import("../views/account/AccountDetails")
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: false
    },
    beforeEnter: (to, from, next) => {
      next({ name: "login" });
    }
  },

  //#TODO
  //
  // start
  {
    path: "/air",
    name: "air",
    component: () => import("../views/air/AirHome")
  },
  {
    path: "/hotel",
    name: "hotel",
    component: () => import("../views/hotel/HotelHome")
  },
  {
    path: "/package",
    name: "package",
    component: () => import("../views/package/PackageHome")
  },
  {
    path: "/cruise",
    name: "cruise",
    component: () => import("../views/cruise/CruiseHome")
  },
  {
    path: "/activity",
    name: "activity",
    component: () => import("../views/activity/ActivityHome")
  },
  {
    path: "/carrental",
    name: "carrental",
    component: () => import("../views/carrental/CarRentalHome")
  },
  {
    path: "/cartransfer",
    name: "cartransfer",
    component: () => import("../views/cartransfer/CartransferHome")
  },
  {
    path: "/rail/ticket",
    name: "ticket",
    component: () => import("../views/rail/TrainTicketDetails"),
    meta: {
      authRequired: false
    }
  },
  {
    path: "/rail/trains",
    name: "trains",
    component: () => import("../views/rail/RailTrains"),
    meta: {
      authRequired: false
    }
  },
  {
    path: "/rail/passes",
    name: "passes",
    component: () => import("../views/rail/RailPasses")
  },
  {
    path: "/rail/passOptions",
    name: "passOptions",
    component: () => import("../views/rail/PassOptions")
  },
  {
    path: "/rail/passDetails",
    name: "passDetails",
    component: () => import("../views/rail/PassDetails")
  },
  {
    path: "/rail/promotion",
    name: "promotion",
    component: () => import("../views/rail/Promotion")
  },
  {
    path: "/rail",
    name: "rail-home",
    component: () => import("../views/rail/RailHome"),
    meta: {
      authRequired: false
    }
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("../views/rail/CartView"),
    meta: {
      authRequired: false
    }
  },
  {
    path: "/cart/book",
    name: "cartBook",
    component: () => import("../views/rail/BookFromCart"),
    meta: {
      authRequired: false
    }
  },
  {
    path: "/insurance",
    name: "insurance",
    component: () => import("../views/insurance/InsuranceHome")
  },
  {
    path: "/visa",
    name: "visa",
    component: () => import("../views/visa/VisaHome")
  },
  {
    path: "/booking/rail",
    name: "bookingRail",
    component: () => import("../views/booking/BookingRailList")
  },
  {
    path: "/booking/rail/view",
    name: "bookingRailDetails",
    component: () => import("../views/booking/BookingRailDetails")
  }
];
