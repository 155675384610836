<script>
import { mapActions } from 'vuex';
import RailAPI from '../../api/RailAPI';
export default {
    data () {
        return {
            connectionList: []
        }
    },
    created(){
        this.loadConnectionList()
    },
    methods: {
        ...mapActions('rail', []),
        loadConnectionList(){
            RailAPI.getConnectionList().then(res => {
                let resList = res.data
                this.connectionList = resList
				let orderByStatus = ["UP", "DOWN", "UNKNOWN"]
				this.connectionList.sort((a, b) => {
					return orderByStatus.indexOf(a.status) - orderByStatus.indexOf(b.status)
				})
				
            }).catch(error => {
                console.log(error)
            })
        },
        toDetails(id) {
            this.$router.push({
                name: 'promotion',
                query: {
                    id: id
                }
            })
            // let route = this.$router.resolve({
            //     name: 'promotion',
            //     query: {
            //         id: id
            //     }
            // })
            // window.open(route.href)
        },
    } 
}

</script>

<template>
    <b-card>
        <b-card-title>Connection Status</b-card-title>
        <b-list-group>
            <b-list-group-item v-for="con in connectionList" :key="con.id">
				<i class="ri-train-line text-success" v-if="con.status == 'UP'"></i>
				<i class="ri-train-line" v-else-if="con.status == 'DOWN'"></i>
				<i class="ri-train-line text-warning" v-else></i>
				<span class="ml-1">{{ con.id }}</span>
				<b-badge class="ml-4" pill variant="success" v-if="con.status == 'UP'">{{ con.status }}</b-badge>
				<b-badge class="ml-4" pill variant="secondary" v-else-if="con.status == 'DOWN'">{{ con.status }}</b-badge>
				<b-badge class="ml-4" pill variant="warning" v-else>{{ con.status }}</b-badge>
			</b-list-group-item>
        </b-list-group>
    </b-card>
</template>



<style scoped>
</style>