<script>
import RailPriceBreakdownVue from "./PriceBreakdown.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "",
  components: {
    RailPriceBreakdownVue
  },
  props: {
    trainTicket: {},
    keyPrefix: {},
    isOutbound: {}
  },
  data() {
    return {
      collapseIdPrefix: "collapse-",
      journeyDetails: {
        departureAndArrivalTime: "10:20 to 12:30",
        trainProvider: "Eurostar",
        trainNumber: "33345",
        departureStation: "Firenze S.M. Novella",
        arrivalStation: "Roma Termini",
        journeyDuration: "2h 2m",
        isDirect: "yes"
      },
      priceDetails: {
        adult1: 300,
        adult2: 300,
        child1: 100,
        total: 700
      },
      viewConditionsOfTicket: {},
      isModalVisible: false,
      isAddMoreItemsInquiryModalVisible: false,
      cartBuffer: {}
    };
  },
  computed: {
    ...mapGetters("rail", ["isRoundTrip", "selectedOutboundTicket"]),
    tickets: function() {
      return this.trainTicket.tickets;
    }
    // cart: function(){
    //     let cartFromSession = JSON.parse(sessionStorage.getItem('cart'))
    //     if(cartFromSession == null || cartFromSession == undefined){
    //         cartFromSession = []
    //     }
    //     return cartFromSession
    // }
  },
  created() {},

  methods: {
    ...mapActions("rail", [
      "selectTicket"
      // 'updateCartNumber',
    ]),
    ...mapActions("booking", ["addGoodsToCart"]),
    nextStep: function(selectedTicket) {
      if (!this.isRoundTrip) {
        //如果是单程，一步（选一次票）
        this.selectTicket({
          selectedOutboundTicket: selectedTicket
        });
        this.cartBuffer.outbound = selectedTicket;
        this.isAddMoreItemsInquiryModalVisible = true;

        // this.$router.push({
        //     name: 'ticket'
        // })
      } else {
        //如果是双程，两步（选两次票）
        if (this.isOutbound) {
          this.selectTicket({
            selectedOutboundTicket: selectedTicket
          });
          // this.cartBuffer.outbound = selectedTicket
          this.$emit("toInboundOptions", selectedTicket);
        } else {
          this.selectTicket({
            selectedInboundTicket: selectedTicket
          });
          this.cartBuffer.outbound = this.selectedOutboundTicket;
          this.cartBuffer.inbound = selectedTicket;
          this.isAddMoreItemsInquiryModalVisible = true;
          // this.$router.push({
          //     name: 'ticket'
          // });
        }
      }
    },
    putIntoCart() {
      this.isAddMoreItemsInquiryModalVisible = false;
      this.addGoodsToCart({ goods: this.cartBuffer });
      this.$router.push({
        name: "rail-home"
      });
    },
    bookTicketNow() {
      this.isAddMoreItemsInquiryModalVisible = false;
      this.$router.push({
        name: "ticket"
      });
    },
    showConditionModal(viewTicket) {
      this.viewConditionsOfTicket = viewTicket;
      this.isModalVisible = true;
    },
    onModalHidden() {
      this.viewConditionsOfTicket = {};
      this.isModalVisible = false;
    }
  }
};
</script>

<template>
  <div>
    <div>
      <b-card no-body class="mb-2">
        <b-card-header class="font-size-17 font-weight-bold bg-soft-secondary">
          <span class="text-primary"
            >{{ trainTicket.originStation }}
            &nbsp; to &nbsp;
          </span>

          <span class="text-primary">{{ trainTicket.destinationStation }}</span>
          <br />
          <span class="text-primary"
            >{{ trainTicket.departureTime }}
            &nbsp; to &nbsp;
          </span>

          <span class="text-primary">{{ trainTicket.arrivalTime }}&nbsp;</span>
          <span class="text-danger">{{ trainTicket.arriveNextDate }}</span>
        </b-card-header>
        <!-- Journey details -->
        <div class="mb-3 p-3">
          <b-row>
            <b-col cols="9">
              <ul class="list-unstyled font-size-14">
                <li class="my-2">
                  <i class="mdi mdi-google-maps text-secondary"></i>&nbsp;
                  <template v-for="(item, index) in trainTicket.routings">
                    <span v-if="index === 0" class="text-secondary" :key="index"
                      ><b>{{ item }}</b></span
                    >
                    <span v-else class="text-secondary" :key="index + '_1'">
                      &nbsp;<i
                        class="mdi mdi-arrow-expand-right text-success"
                      ></i
                      >&nbsp;
                      <b>{{ item }}</b>
                    </span>
                  </template>
                </li>
                <li class="my-2">
                  <i class="mdi mdi-train text-secondary"></i>&nbsp;
                  <template v-for="(item, index) in trainTicket.trains">
                    <span
                      v-if="index === 0"
                      class="text-secondary"
                      :key="index"
                      >{{ item }}</span
                    >
                    <span v-else class="text-secondary" :key="index + '_2'">
                      &nbsp;<i
                        class="mdi mdi-transit-connection-variant text-success"
                      ></i
                      >&nbsp; {{ item }}</span
                    >
                  </template>
                </li>
                <!-- <li>
                            <span class="text-primary"><b>{{trainTicket.depAndArriTimeStr}}</b></span>
                        </li> -->
                <!-- <li>
                            Train:
                            <span class="text-primary"><b>{{trainTicket.train}}</b></span>
                        </li> -->

                <li class="my-2 text-secondary">
                  <i class="mdi mdi-map-clock text-secondary"></i>&nbsp;
                  Duration
                  <span class="text-success">{{ trainTicket.duration }}</span>
                </li>
                <li class="my-2 text-secondary">
                  <i class="mdi mdi-directions text-secondary"></i>&nbsp; Direct
                  <span class="text-success">{{ trainTicket.direct }}</span>
                </li>
              </ul>
            </b-col>
            <b-col cols="2">
              <div
                class="text-center mx-auto border rounded-pill bg-soft-secondary"
              >
                &nbsp;&nbsp;
                <span class="text-danger"
                  ><b
                    >{{ trainTicket.sellingCurrencyCode }}&nbsp;
                    {{ trainTicket.sellingBestPrice }}&nbsp;&nbsp;</b
                  ></span
                >
                <i class="mdi mdi-arrow-up text-secondary"></i>
              </div>
            </b-col>
          </b-row>
          <b-tabs
            card
            active-nav-item-class="font-weight-bold text-uppercase text-success"
            active-tab-class="font-weight-bold text-secondary"
            content-class="mt-0"
          >
            <b-tab
              v-for="(item, index) in trainTicket.comfortTypeTabs"
              :title="item"
              :key="keyPrefix + index"
            >
              <template v-for="(ticket, indx) in trainTicket.tickets">
                <b-row
                  role="tab"
                  class="py-2"
                  v-if="ticket.comfortType === item"
                  :key="'row-' + keyPrefix + index + indx"
                >
                  <b-col cols="5"
                    >{{
                      ticket.seatClass +
                        " ( " +
                        ticket.seatClassDescription +
                        " ) " +
                        ","
                    }}
                    {{ ticket.flexibilityType }}</b-col
                  >
                  <b-col cols="2" class="text-right text-danger">
                    {{ ticket.sellingCurrencyCode }}
                    {{ ticket.sellingTotalAmount }}
                  </b-col>
                  <b-col cols="2" class="">
                    <a
                      v-b-toggle
                      :href="'#collapse-' + keyPrefix + index + indx"
                      title="Price details"
                      @click.prevent
                    >
                      <i class="mdi mdi-chevron-down-box text-info"></i>
                      <small>Detail</small>
                    </a>
                  </b-col>
                  <b-col cols="2">
                    <b-badge
                      variant="success"
                      class="cursor-pointer font-size-16"
                      to="#"
                      @click.prevent="nextStep(ticket)"
                      >Select</b-badge
                    >
                  </b-col>
                </b-row>
                <b-collapse
                  :id="'collapse-' + keyPrefix + index + indx"
                  :accordion="'priceBreakdown-accordion'"
                  role="tabpanel"
                  v-if="ticket.comfortType === item"
                  :key="'collapse-' + keyPrefix + index + indx"
                  class="pl-3"
                >
                  <b-row class="border rounded flex-column w-75  bg-light">
                    <div class="p-2 w-50">
                      <RailPriceBreakdownVue
                        :price-details="ticket.priceBreakdown"
                        :conditions="ticket.conditions"
                        :currency-code="ticket.currencyCode"
                        :total-amount="ticket.totalAmount"
                        :selling-currency-code="ticket.sellingCurrencyCode"
                        :selling-total-amount="ticket.sellingTotalAmount"
                      ></RailPriceBreakdownVue>
                    </div>
                    <div class="px-2">
                      <a
                        type="link"
                        @click="showConditionModal(ticket)"
                        class="text-info view-con-link"
                        >Terms and Conditions</a
                      >
                    </div>
                  </b-row>
                </b-collapse>
              </template>
            </b-tab>
          </b-tabs>
        </div>
      </b-card>
    </div>
    <div>
      <b-modal
        id="ticket-conditions-modal"
        centered
        scrollable
        :visible="isModalVisible"
        @hidden="onModalHidden"
        hide-footer
        title="Ticket Conditions"
      >
        <div
          class="my-2"
          v-for="(pbdItem, pbdindx) in viewConditionsOfTicket.priceBreakdown"
          :key="`ticket-con-content-${pbdindx}`"
        >
          <p class="text-success">
            {{ pbdItem.quantity + " x " }}{{ pbdItem.code }}
          </p>
          <div
            v-for="(conItem, iiddxx) in pbdItem.conditions"
            :key="`ticket-con-p-${iiddxx}`"
          >
            <p class="text-success">
              <span class="mr-1">{{
                viewConditionsOfTicket.trainSegments[iiddxx].departureStation
              }}</span>
              to
              <span class="ml-1">{{
                viewConditionsOfTicket.trainSegments[iiddxx].arrivalStation
              }}</span>
            </p>
            <span class="text-success">{{
              viewConditionsOfTicket.trains[iiddxx]
            }}</span>
            <p v-html="conItem"></p>
          </div>
        </div>
      </b-modal>
    </div>

    <div>
      <b-modal
        v-model="isAddMoreItemsInquiryModalVisible"
        id="add-more-items-inquiry-modal"
        title="Inquiry"
        centered
        size="lg"
        no-close-on-backdrop
        hide-footer
        ok-title="Save"
        ok-variant="success"
      >
        <div class="text-center">
          <b-row>
            <b-col>
              <p class="font-size-16">
                You could book the ticket now.
                <br />You could also put it into cart, add more tickets, and
                then book them at once.
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button variant="info" @click="putIntoCart"
                >Put Into Cart</b-button
              >
            </b-col>
            <b-col>
              <b-button variant="success" @click="bookTicketNow"
                >Book Ticket Now</b-button
              >
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
.view-con-link {
  cursor: pointer;
}
</style>
