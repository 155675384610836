import axios from "axios";
import router from "@/router";

axios.defaults.timeout = 180000;
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    try {
      switch (error.response.status) {
        case 400:
          error.response.data = { errors: {} };
          error.response.data.errors.errors = [
            { stringValue: "Some request errors." }
          ];
          break;
        case 401:
          error.response.data = { errors: {} };
          error.response.data.errors.errors = [
            { stringValue: "Please log in." }
          ];
          router.push({ name: "login" });
          break;
        case 500:
          error.response.data = { errors: {} };
          error.response.data.errors.errors = [
            { stringValue: "Some server errors." }
          ];
          break;
        case 504:
          error.response.data = { errors: {} };
          error.response.data.errors.errors = [
            { stringValue: "Request timeout, please try again." }
          ];
          break;
      }
    } catch {
      console.info(error);
    }
    return Promise.reject(error);
  }
);

export default axios;
