import axios from "../utils/axiosUtils"

export default {
    getPromotionList(){
        let url = '/api/v1/cms/promotions'

        return axios.get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
    },

    getPromotion(id){
        let url = '/api/v1/cms/promotions/'+id

        return axios.get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
    },
	
	getBannerList(){
		let url = '/api/v1/cms/rail-home-banners'
	
		return axios.get(url, {
			headers: {
				"Content-Type": "application/json"
			}
		})
	},
	
	getBanner(id){
		let url = '/api/v1/cms/rail-home-banners/'+id
	
		return axios.get(url, {
			headers: {
				"Content-Type": "application/json"
			}
		})
	},
	
	getConnectionList(){
		let url = '/api/v1/rail/suppliers-health-status'
	
		return axios.get(url, {
			headers: {
				"Content-Type": "application/json"
			}
		})
	},

    searchTrainTickets (searchRQ) {
        let url = '/api/v1/rail/shop'
        
        return axios.post(url, searchRQ, {
            headers: {
                "Content-Type": "application/json"
            }
        })
    },

    bookTicket(bookRQ) {
        let url = '/api/v1/rail/book'
        return axios.post(url, bookRQ, {
            headers: {
                "Content-Type": "application/json"
            }
        })
    },

    confirmBooking(confirmBookingRQ) {
        let url = '/api/v1/rail/confirm-booking'
        return axios.post(url, confirmBookingRQ, {
            headers: {
                "Content-Type": "application/json"
            }
        })
    },

    searchRailPasses(searchRQ) {
        let url = '/api/v1/rail/fare-quote'
        
        return axios.post(url, searchRQ, {
            headers: {
                "Content-Type": "application/json"
            }
        })
    }
}
