/**
*  获取state中各类信息，包括原始信息与重构后的信息
* */

const getters = {
    activitySearchRS(state){
        if (state.activitySearchRS == undefined){
            return {}
        }
        return state.activitySearchRS;
    },
    activityBasicDescription: function(state){
        return function( tourActivityID){
        if (state.activitySearchRS == undefined){
            return {}
        }
        let specificTourActivity = state.activitySearchRS.tourActivityInfos.filter(function filter(activityInfo){
            if(activityInfo.basicInfo.tourActivityID == tourActivityID){
                return true;
            }
            return false;
        })[0];
        let basicDescription = "";
        if (specificTourActivity!= null){
            // console.info(specificTourActivity);
            basicDescription = specificTourActivity.description.multimedia.multimediaDescriptionLists[0].textItems.filter(function filter(textItem){
                if (textItem.category == "basic"){
                    return true;
                }
                return false;
            })[0];
        }
        return basicDescription;
    }
    },

    necessaryInfoDetailList(state){
        // console.log(state.necessaryInfoDetailList)
        return state.necessaryInfoDetailList
    },
    activityDetail(state){
        return state.activityDetail
    }
}

export default getters;