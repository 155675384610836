<script>

export default {
    name: 'TravelerInfoVue',
    props: {
        travelers: {},
    },
    data () {
        return {
        }
    },
    computed: {

    },
    methods: {
    }
}

</script>

<template>
<div class="d-flex">
    <div
    v-for="(item, index) in travelers"
    :key="'travelerInfo-'+index"
    class="mr-4 border rounded"
    >
        <div class="p-2 border-bottom">Traveler {{parseInt(index)+1}} ({{item.code}})</div>
        <div class="p-3">
            <p class="text-primary">{{item.passenger}}</p>
            <div class="mt-3">
                <p v-if="item.birthDate"><i class="mdi mdi-cake text-primary mr-2"></i>{{item.birthDate}}</p>
                <p v-if="item.phoneNumber"><i class="mdi mdi-phone text-primary mr-2"></i>{{item.phoneNumber}}</p>
                <p v-if="item.email"><i class="mdi mdi-mail text-primary mr-2"></i>{{item.email}}</p>
            </div>
        </div>
    </div>
    <!-- <div
    v-for="(item, index) in travelers"
    :key="'travelerInfo-'+index"
    class="p-3 border"
    >
        <h5 class="font-size-14">Traveler {{index+1}} ({{item.code}})</h5>
        <b-row>
            <b-col cols="4">
            <label>Passenger</label>
            </b-col>
            <b-col>
                {{item.passenger}}
            </b-col>
        </b-row>
        
        <b-row>
            <b-col cols="4"><label>Date of Birth</label></b-col>
            <b-col>{{item.birthDate}}</b-col>
        </b-row>

        <b-row v-if="item.telephoneNumber">
            <b-col cols="4"><label>Telephone Number</label></b-col>
            <b-col>{{item.telephoneNumber}}</b-col>
        </b-row>

        <b-row v-if="item.email">
            <b-col cols="4"><label>Email</label></b-col>
            <b-col>{{item.email}}</b-col>
        </b-row>
    </div> -->

</div>
</template>

<style scoped>

</style>