<script>
// import RailUtils from '../../utils/RailUtils'
import DateUtils from "../../utils/DateUtils";
import autocompleteApi from "../../api/autocompleteApi";
import railPlacesUtils from "../../utils/autocomplete/railPlacesUtils";
import AgencyPortalAutocompleteVue from "../forms-element/autocomplete/AgencyPortalAutocomplete.vue";
import { validationMixin } from "vuelidate";
import {
  maxLength,
  minLength,
  required,
  email
} from "vuelidate/lib/validators";
import dayjs from "dayjs";

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "TravelerFormVueForCart",
  components: {
    AgencyPortalAutocompleteVue
  },
  mixins: [validationMixin],
  props: {
    travelers: {
      defaults: [],
      required: true
    },
    leadTravelerForm: {},
    committed: {},
    idPrefix: {},
    isQuoteStep: {},
      travelDateTime: {},
  },
  data() {
    return {
      isAllFilled: true,
      isAgeValid: true,
      errorMsg: {
        notAllFilled: {
          text: "Please fill out all traveler forms."
        },
        isAgeInvalid: {
          text:
            "The passenger's date of birth does not appear valid for the senior / youth or adult category and Age range: "
        }
      },
      titleOptions: [
        { text: "Title", value: "", disabled: true },
        { text: "Mr", value: "MR" },
        { text: "Ms", value: "MS" }
      ],
      isTravelersConfirmed: false,
      suggestions: [],
      leadTraveler: {},
        pickerOptions: {
            disabledDate(time) {
                return time.getTime() > Date.now();
            }
        }
    };
  },
  validations() {
    let passenger = {};
    let leadTraveler = {};
    for (let key in this.travelers[0].passenger) {
      if (key == "email") {
        passenger[key] = { required, email };
      } else if (key == "firstName") {
        passenger[key] = {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50)
        };
      } else if (key == "birthDate") {
        passenger[key] = { required };
      } else {
        passenger[key] = { required };
      }
    }
    for (let key in this.leadTraveler) {
      if (key == "email") {
        leadTraveler[key] = { required, email };
      } else {
        leadTraveler[key] = { required };
      }
    }

    return {
      leadTraveler: leadTraveler,
      travelers: {
        $each: {
          passenger: passenger
        }
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    this.leadTraveler = this.leadTravelerForm;
  },
  methods: {
    phoneUpdate(allValue, index) {
      if (
        allValue.formattedNumber != null &&
        allValue.formattedNumber != undefined
      ) {
        this.travelers[index].passenger.telephoneNumber =
          allValue.formattedNumber;
      }
    },
    leadTravelerPhoneUpdate(allValue) {
      console.log(allValue);
      if (
        allValue.formattedNumber != null &&
        allValue.formattedNumber != undefined
      ) {
        this.leadTraveler.telephoneNumber = allValue.formattedNumber;
      }
    },
    validateFieldState(index, field) {
      const { $dirty, $error } = this.$v.travelers.$each[index].passenger[
        field
      ];
      return $dirty ? !$error : null;
    },
    validateState(field) {
      const { $dirty, $error } = this.$v.leadTraveler[field];
      return $dirty ? !$error : null;
    },
    validateAge(index) {
      let fromAge = this.travelers[index].fromAge;
      let toAge = this.travelers[index].toAge;
      let birthDate = this.travelers[index].passenger.birthDate;
      if (
        fromAge != null &&
        fromAge != undefined &&
        toAge != null &&
        toAge != undefined
      ) {
        if (birthDate != "") {
          let age = DateUtils.getDuration("", birthDate, "year");
          if (age < fromAge || age > toAge) {
            this.isAgeValid = false;
            return false;
          } else {
            this.isAgeValid = true;
          }
        }
      }
    },
    validateBirth(index, birth, fromAge, toAge) {
      let isValid = dayjs(birth, "YYYY-MM-DD", true).isValid();
      let age = DateUtils.getDuration("", birth, "year");
      console.info(age);
      if (!isValid || age < fromAge || age > toAge) {
        this.$v.travelers.$each[index].$model.passenger.birthDate = "";
        return false;
      } else {
        let today = new Date(DateUtils.format(new Date()));
        let date = new Date(birth);
        if (date > today) {
          this.$v.travelers.$each[index].$model.passenger.birthDate = "";
          return false;
        } else {
          this.$v.travelers.$each[index].$model.passenger.birthDate = birth;
          return true;
        }
      }
    },
    dateDisabled(ymd, date) {
      let number = Date.now();
      return date > number;
    },
    getSuggestedCountries(str) {
      autocompleteApi
        .getCountries(str)
        .then(res => {
          let items = res.data;
          let destinations = railPlacesUtils.countrySuggestions(items);
          this.suggestions = destinations;
        })
        .catch(() => {
          this.suggestions = [{ value: "", text: "empty suggestion" }];
        });
    },
    selectCountry(countryCode, index) {
      this.travelers[index].passenger.docHolderNationality = countryCode;
    },
    selectAddressCountry(countryCode, index) {
      this.travelers[index].passenger.address = countryCode;
    },
    inputAddressCountry(index) {
      console.info("input");
      this.travelers[index].passenger.address = "";
    },
    blurAddressCountry(index) {
      this.$v.travelers.$each[index].passenger.address.$touch();
    },
    inputPassportCountry(index) {
      this.travelers[index].passenger.docHolderNationality = "";
    },
    blurPassportCountry(index) {
      this.$v.travelers.$each[
        index
      ].$model.passenger.docHolderNationality.$touch();
    },
    toEditPassengers() {
      this.isTravelersConfirmed = false;
      this.committed.committed = false;
    },
    toConfirmPassengers: function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("invalid");
      } else {
        this.isTravelersConfirmed = true;
        this.toProcessData();
        this.toCommitToParentComponent();
      }
    },
    toProcessData() {
      //将leadTraveler数据合并到第一个乘客的travelers对象里
      this.travelers[0].leadTraveler = this.leadTraveler;
    },
    toCommitToParentComponent() {
      this.committed.committed = true;
    },
      disableDates(time, passenger,travelDateTime){
        //
          let today = DateUtils.format(new Date(travelDateTime))
          let maxDate = dayjs(today).subtract(passenger.age, "year");
          let minDate = dayjs(today).subtract(passenger.age+1, "year").add(1, "day");
          let compareDate = dayjs(new Date(time.getTime()));
          let isBefore = compareDate.isBefore(minDate);
          let isAfter = compareDate.isAfter(maxDate);
          let b = isBefore || isAfter;
          return b;
      }
  }
};
</script>

<template>
  <div>
    <b-card no-body>
      <div v-if="isTravelersConfirmed" class="d-flex">
        <div
          v-for="(item, index) in $v.travelers.$each.$iter"
          :key="'ticket-traveler-info-' + index"
          class="m-3 px-3 pt-3 border border-light"
        >
          <div class="p-2 text-secondary border-bottom">
            Passenger {{ index + 1 }} ({{ travelers[index].passenger.code }})
          </div>
          <div class="p-3">
            <p class="text-primary">
              {{ travelers[index].passenger.title }}
              {{ travelers[index].passenger.firstName }}
              {{ travelers[index].passenger.lastName }}
            </p>
            <div class="mt-3">
              <p v-if="travelers[index].passenger.birthDate">
                <i class="mdi mdi-cake text-primary mr-2"></i
                >{{ travelers[index].passenger.birthDate }}
              </p>
              <p v-if="travelers[index].passenger.telephoneNumber">
                <i class="mdi mdi-phone text-primary mr-2"></i
                >{{ travelers[index].passenger.telephoneNumber }}
              </p>
              <p v-if="travelers[index].passenger.email">
                <i class="mdi mdi-mail text-primary mr-2"></i
                >{{ travelers[index].passenger.email }}
              </p>
              <div v-if="index == 0">
                <p v-if="leadTraveler.telephoneNumber">
                  <i class="mdi mdi-phone text-primary mr-2"></i
                  >{{ leadTraveler.telephoneNumber }}
                </p>
                <p v-if="leadTraveler.email">
                  <i class="mdi mdi-mail text-primary mr-2"></i
                  >{{ leadTraveler.email }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isTravelersConfirmed">
        <div
          v-for="(item, index) in $v.travelers.$each.$iter"
          :key="'ticket-traveler-' + idPrefix + '-' + index"
          class="px-3 pt-3 border-info border-top"
        >
          <h5 class="font-size-14">
            Traveler {{ Number(index) + Number(1) }} ({{
              travelers[index].passenger.code
            }})
              <span
                      class="text-danger"
                      v-if="
              travelers[index].passenger.code == 'INFANT' ||
              travelers[index].passenger.code == 'CHILD' ||
              travelers[index].passenger.code == 'YOUTH'
            "
              >
            (Age:
            {{ travelers[index].age }})
          </span>
              <span
                      class="text-danger"
                      v-else-if="
              travelers[index].fromAge &&
                travelers[index].toAge &&
                travelers[index].toAge !== 999
            "
              >
             (Age range:
              {{ travelers[index].fromAge + " to " + travelers[index].toAge }})
             </span>
              <span
                      class="text-danger"
                      v-else-if="
              travelers[index].fromAge && travelers[index].toAge === 999
            "
              >
             (Age range: {{ travelers[index].fromAge + " + " }})
          </span>
              <span class="text-danger" v-else-if="travelers[index].toAge">
           (Age range: {{ "0 to " + travelers[index].toAge }})

          </span>
          </h5>
          <span>Your name must appear as on your passport</span>
          <b-row>
            <b-col
              cols="2"
              v-if="travelers[index].passenger.title !== undefined"
            >
              <b-form-group>
                <b-form-select
                  v-model="item.passenger.title.$model"
                  :options="titleOptions"
                  :class="{ 'form-group--error': item.passenger.title.$error }"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
              v-if="travelers[index].passenger.firstName !== undefined"
            >
              <b-form-group>
                <b-form-input
                  v-model="item.passenger.firstName.$model"
                  placeholder="First Name"
                  :class="{
                    'form-group--error': item.passenger.firstName.$error
                  }"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
              v-if="travelers[index].passenger.lastName !== undefined"
            >
              <b-form-group>
                <b-form-input
                  v-model="item.passenger.lastName.$model"
                  placeholder="Last Name"
                  :class="{
                    'form-group--error': item.passenger.lastName.$error
                  }"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              v-if="travelers[index].passenger.birthDate !== undefined"
            >
              <b-input-group
                class="mb-3"
                :class="{
                  'form-group--error': item.passenger.birthDate.$error
                }"
              >
<!--                <el-date-picker-->
<!--                  v-model="item.passenger.birthDate.$model"-->
<!--                  type="date"-->
<!--                  class="full-width"-->
<!--                  placeholder="Birth Date - YYYY-MM-DD"-->
<!--                  :picker-options="pickerOptions"-->
<!--                  format="yyyy-MM-dd"-->
<!--                  value-format="yyyy-MM-dd"-->
<!--                  @blur="-->
<!--                    validateBirth(-->
<!--                      index,-->
<!--                      item.passenger.birthDate.$model,-->
<!--                      travelers[index].fromAge,-->
<!--                      travelers[index].toAge-->
<!--                    )-->
<!--                  "-->
<!--                >-->
<!--                </el-date-picker>-->
                  <el-date-picker
                          v-if="travelers[index].passenger.code !== 'ADULT' && travelers[index].passenger.code !== 'SENIOR'"
                          v-model="item.passenger.birthDate.$model"
                          type="date"
                          class="full-width"
                          placeholder="Birth Date - YYYY-MM-DD"
                          :picker-options="{
                                  disabledDate(time) {
                                    return disableDates(time, travelers[index],travelDateTime);
                                  }
                              }"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                  />
                  <el-date-picker
                          v-else
                          v-model="item.passenger.birthDate.$model"
                          type="date"
                          class="full-width"
                          placeholder="Birth Date - YYYY-MM-DD"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          :picker-options="{
                                  disabledDate(time) {
                                    return time.getTime() > new Date().getTime();
                                  }
                              }"
                  />
              </b-input-group>
              <span v-if="item.passenger.birthDate.$error"
                >{{ errorMsg.isAgeInvalid.text
                }}<span v-if="travelers[index].toAge !== 999"> From </span>
                {{ travelers[index].fromAge }}
                <span v-if="travelers[index].toAge === 999">+ </span
                ><span v-else>to {{ travelers[index].toAge }}</span>
              </span>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="6"
              v-if="travelers[index].passenger.countryCode !== undefined"
            >
              <b-form-group>
                <VuePhoneNumberInput
                  v-model="item.passenger.countryCode.$model"
                  @update="
                    allValue => {
                      phoneUpdate(allValue, index);
                    }
                  "
                  :class="{
                    'form-group--error': item.passenger.countryCode.$error
                  }"
                  :preferred-countries="['HK', 'CN']"
                  default-country-code="HK"
                  valid-color="#ced4da"
                  placeholder="Telephone Number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              v-if="travelers[index].passenger.email !== undefined"
            >
              <b-form-group>
                <b-form-input
                  v-model="item.passenger.email.$model"
                  placeholder="Email Address"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col v-if="travelers[index].passenger.addressName !== undefined">
              <b-form-group>
                <AgencyPortalAutocompleteVue
                  :id="`address-country-${idPrefix}-${index}`"
                  v-model="item.passenger.addressName.$model"
                  :class="{
                    'form-group--error': item.passenger.address.$error
                  }"
                  @select-value="
                    selectedValue => {
                      selectAddressCountry(selectedValue, index);
                    }
                  "
                  @fetch="getSuggestedCountries"
                  @input="() => inputAddressCountry(index)"
                  @blur="() => blurAddressCountry(index)"
                  :suggestions="suggestions"
                  placeholder="Country of Residence"
                  :datalistId="`address-country-datalist-${idPrefix}-${index}`"
                ></AgencyPortalAutocompleteVue>
                <span
                  class="error-message"
                  v-show="item.passenger.address.$error"
                >
                  You should choose the country from the dropdown
                </span>
              </b-form-group>
            </b-col>
            <b-col v-if="travelers[index].passenger.docID !== undefined">
              <b-form-group>
                <b-form-input
                  v-model="item.passenger.docID.$model"
                  placeholder="Passport Number"
                  :state="validateFieldState(index, 'docID')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col v-if="travelers[index].passenger.expireDate !== undefined">
              <b-form-group>
                <b-form-datepicker
                  placeholder="Expire Date"
                  show-decade-nav
                  v-model="item.passenger.expireDate.$model"
                  :state="validateFieldState(index, 'expireDate')"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col
              v-if="
                travelers[index].passenger.docHolderNationalityName !==
                  undefined
              "
            >
              <b-form-group>
                <AgencyPortalAutocompleteVue
                  :id="`passport-country-${idPrefix}-${index}`"
                  v-model="item.passenger.docHolderNationalityName.$model"
                  :state="validateFieldState(index, 'docHolderNationalityName')"
                  @select-value="
                    selectedValue => {
                      selectCountry(selectedValue, index);
                    }
                  "
                  @fetch="getSuggestedCountries"
                  :suggestions="suggestions"
                  placeholder="Passport Country"
                  :datalistId="`passport-country-datalist-${idPrefix}-${index}`"
                ></AgencyPortalAutocompleteVue>
              </b-form-group>
            </b-col>
          </b-row>

          <template v-if="index == 0">
            <b-row>
              <b-col cols="2" v-if="leadTraveler.title !== undefined">
                <b-form-group>
                  <b-form-select
                    v-model="$v.leadTraveler.title.$model"
                    :options="titleOptions"
                    :class="{
                      'form-group--error': $v.leadTraveler.title.$error
                    }"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="3" v-if="leadTraveler.firstName !== undefined">
                <b-form-group>
                  <b-form-input
                    v-model="$v.leadTraveler.firstName.$model"
                    placeholder="First Name"
                    :class="{
                      'form-group--error': $v.leadTraveler.firstName.$error
                    }"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="3" v-if="leadTraveler.lastName !== undefined">
                <b-form-group>
                  <b-form-input
                    v-model="$v.leadTraveler.lastName.$model"
                    placeholder="Last Name"
                    :class="{
                      'form-group--error': $v.leadTraveler.lastName.$error
                    }"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4" v-if="leadTraveler.birthDate !== undefined">
                <b-form-group>
                  <b-form-datepicker
                    placeholder="Date of Birth"
                    :id="`lead-birth-date-${idPrefix}-${index}`"
                    :class="{
                      'form-group--error': $v.leadTraveler.birthDate.$error
                    }"
                    show-decade-nav
                    v-model="$v.leadTraveler.birthDate.$model"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback
                    :id="`lead-birth-date-feedback-${idPrefix}-${index}`"
                    >{{ errorMsg.isAgeInvalid.text
                    }}<span v-if="travelers[index].toAge !== 999">From </span>
                    {{ travelers[index].fromAge }}
                    <span v-if="travelers[index].toAge === 999">+ </span
                    ><span v-else>to {{ travelers[index].toAge }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-input-group
                  class="mb-3"
                  :class="{
                    'form-group--error': $v.leadTraveler.birthDate.$error
                  }"
                >
                  <b-input-group-prepend>
                    <b-form-datepicker
                      v-model="$v.leadTraveler.birthDate.$model"
                      show-decade-nav
                      button-only
                      :date-disabled-fn="dateDisabled"
                      right
                      locale="en-US"
                      aria-controls="example-input"
                      @input="
                        validateBirth(
                          index,
                          $v.leadTraveler.birthDate.$model,
                          travelers[index].fromAge,
                          travelers[index].toAge
                        )
                      "
                    ></b-form-datepicker>
                  </b-input-group-prepend>
                  <b-form-input
                    id="example-input"
                    v-model="$v.leadTraveler.birthDate.$model"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    @blur="
                      validateBirth(
                        index,
                        $v.leadTraveler.birthDate.$model,
                        travelers[index].fromAge,
                        travelers[index].toAge
                      )
                    "
                  ></b-form-input>
                </b-input-group>
                <span v-if="$v.leadTraveler.birthDate.$error"
                  >{{ errorMsg.isAgeInvalid.text
                  }}<span v-if="travelers[index].toAge !== 999">From </span>
                  {{ travelers[index].fromAge }}
                  <span v-if="travelers[index].toAge === 999">+ </span
                  ><span v-else>to {{ travelers[index].toAge }}</span>
                </span>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6" v-if="leadTraveler.countryCode !== undefined">
                <b-form-group>
                  <VuePhoneNumberInput
                    v-model="$v.leadTraveler.countryCode.$model"
                    @update="
                      allValue => {
                        leadTravelerPhoneUpdate(allValue);
                      }
                    "
                    :class="{
                      'form-group--error': $v.leadTraveler.countryCode.$error
                    }"
                    :preferred-countries="['HK', 'CN']"
                    default-country-code="HK"
                    valid-color="#ced4da"
                    placeholder="Telephone Number"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6" v-if="leadTraveler.email !== undefined">
                <b-form-group>
                  <b-form-input
                    v-model="$v.leadTraveler.email.$model"
                    placeholder="Email Address"
                    :class="{
                      'form-group--error': $v.leadTraveler.email.$error
                    }"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col v-if="leadTraveler.addressName !== undefined">
                <b-form-group>
                  <!--                            <AgencyPortalAutocompleteVue-->
                  <!--                            :id="`lead-address-country-${idPrefix}-${index}`"-->
                  <!--                            v-model="$v.leadTraveler.addressName.$model"-->
                  <!--                            :class="{ 'form-group&#45;&#45;error': $v.leadTraveler.address.$error }"-->
                  <!--                            @select-value="(selectedValue) => {selectAddressCountry(selectedValue, index)}"-->
                  <!--                            @fetch="getSuggestedCountries"-->
                  <!--                            :suggestions="suggestions"-->
                  <!--                            placeholder="Country of Residence"-->
                  <!--                            :datalistId="`lead-address-country-datalist-${idPrefix}-${index}`"-->
                  <!--                            ></AgencyPortalAutocompleteVue>-->

                  <AgencyPortalAutocompleteVue
                    :id="`lead-address-country-${index}`"
                    v-model="$v.leadTraveler.addressName.$model"
                    :class="{
                      'form-group--error': item.passenger.address.$error
                    }"
                    @select-value="
                      selectedValue => {
                        selectAddressCountry(selectedValue, index);
                      }
                    "
                    @fetch="getSuggestedCountries"
                    @input="() => inputAddressCountry(index)"
                    @blur="() => blurAddressCountry(index)"
                    :suggestions="suggestions"
                    placeholder="Country of Residence"
                    :datalistId="
                      `lead-address-country-datalist-${idPrefix}-${index}`
                    "
                  ></AgencyPortalAutocompleteVue>
                </b-form-group>
              </b-col>
              <b-col v-if="leadTraveler.docID !== undefined">
                <b-form-group>
                  <b-form-input
                    v-model="$v.leadTraveler.docID.$model"
                    placeholder="Passport Number"
                    :class="{
                      'form-group--error': $v.leadTraveler.docID.$error
                    }"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col v-if="leadTraveler.expireDate !== undefined">
                <b-form-group>
                  <b-form-datepicker
                    placeholder="Expire Date"
                    show-decade-nav
                    v-model="$v.leadTraveler.expireDate.$model"
                    :class="{
                      'form-group--error': $v.leadTraveler.expireDate.$error
                    }"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric'
                    }"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col v-if="leadTraveler.docHolderNationalityName !== undefined">
                <b-form-group>
                  <AgencyPortalAutocompleteVue
                    :id="`lead-passport-country-${idPrefix}-${index}`"
                    v-model="$v.leadTraveler.docHolderNationalityName.$model"
                    :class="{
                      'form-group--error':
                        $v.leadTraveler.docHolderNationality.$error
                    }"
                    @select-value="
                      selectedValue => {
                        selectCountry(selectedValue, index);
                      }
                    "
                    @fetch="getSuggestedCountries"
                    :suggestions="suggestions"
                    placeholder="Passport Country"
                    :datalistId="
                      `lead-passport-country-datalist-${idPrefix}-${index}`
                    "
                  ></AgencyPortalAutocompleteVue>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
        </div>
      </div>

      <div v-if="isQuoteStep">
        <div
          class="px-3 pb-3 d-flex justify-content-end"
          v-if="!isTravelersConfirmed"
        >
          <b-button size="sm" variant="info" @click="toConfirmPassengers"
            >Save info
          </b-button>
        </div>
        <div
          class="px-3 pb-3 d-flex justify-content-end"
          v-if="isTravelersConfirmed"
        >
          <b-button size="sm" variant="secondary" @click="toEditPassengers"
            >Edit passengers
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<style scoped></style>
