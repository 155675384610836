import axios from "../utils/axiosUtils"
const carUrl = "/api/v1/car";
export default {

    searchVehicles(request){
        //保证对象是Json字符串
        if (typeof request === 'object'){
            request = JSON.stringify(request);
        }
        console.log(carUrl+"/vehicles")
        return axios.post(carUrl+"/vehicles", request,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    },

    searchVehicleRate(request){
        //保证对象是Json字符串
        if (typeof request === 'object'){
            request = JSON.stringify(request);
        }
        return axios.post(carUrl+"/rates", request,{
            headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
            }
        });
    }
}